import { Component, Input } from '@angular/core';
import {
    BankTransactionEntity,
    ChargeCategoryInfo,
    ChargeEntity,
    ChargeHistory,
    ChargePeriodicityInfo,
    IncomeCategoryInfo,
    IncomeEntity,
    IncomeHistory,
    IncomePeriodicityInfo,
} from '@omedom/data';

/**
 * @description Component to display a treasury card (charge or income) in the application
 * @author Jérémie Lopez <jeremie.lopez@omedom.com>
 * @date 27/05/2024
 * @export
 * @class TreasuryCardComponent
 */
@Component({
    selector: 'omedom-treasury-card2',
    templateUrl: './treasury-card.component.html',
    styleUrls: ['./treasury-card.component.scss'],
})
export class TreasuryCardComponent2 {
    /**
     * @description Treasury entity to display in the card
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 27/05/2024
     * @type {(ChargeEntity | IncomeEntity)}
     * @memberof TreasuryCardComponent2
     */
    @Input({ required: true })
    public treasury!: ChargeEntity | IncomeEntity;

    /**
     * @description Transaction associated to the treasury
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 27/05/2024
     * @type {BankTransactionEntity}
     * @memberof TreasuryCardComponent2
     */
    @Input()
    public transaction?: BankTransactionEntity;

    /**
     * @description Date to check if history is paied
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 27/05/2024
     * @type {Date}
     * @memberof TreasuryCardComponent2
     */
    @Input()
    public date?: Date;

    constructor() { }

    /**
     * @description Category of the treasury (charge or income) to display in the card
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 27/05/2024
     * @readonly
     * @type {(ChargeCategoryInfo | IncomeCategoryInfo)}
     * @memberof TreasuryCardComponent2
     */
    public get category(): ChargeCategoryInfo | IncomeCategoryInfo {
        const isCharge = this.treasury instanceof ChargeEntity;

        return isCharge
            ? new ChargeCategoryInfo((this.treasury as ChargeEntity).category)
            : new IncomeCategoryInfo((this.treasury as IncomeEntity).category);
    }

    /**
     * @description History of the treasury (charge or income) at the date selected
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 27/05/2024
     * @readonly
     * @type {(ChargeHistory | IncomeHistory | undefined)}
     * @memberof TreasuryCardComponent2
     */
    public get history(): ChargeHistory | IncomeHistory | undefined {
        if (!this.treasury.history) {
            return;
        }

        return this.treasury.history.find((history) => {
            const historyDate = new Date(history.date.seconds * 1000);

            // Check if date is defined
            if (!this.date) {
                return undefined;
            }

            return (
                historyDate.getFullYear() === this.date.getFullYear() &&
                historyDate.getMonth() === this.date.getMonth() &&
                historyDate.getDate() === this.date.getDate()
            );
        });
    }

    /**
     * @description Status of the history selected
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 27/05/2024
     * @readonly
     * @type {('paid' | 'unpaid' | 'pending' | undefined)}
     * @memberof TreasuryCardComponent2
     */
    public get status(): 'paid' | 'unpaid' | 'pending' | undefined {
        if (!this.history) {
            return;
        }

        if (this.history.isPayed) {
            return 'paid';
        }

        if (!this.history.isReaded) {
            return 'pending';
        }

        return 'unpaid';
    }

    /**
     * @description Periodicity of the treasury
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 27/05/2024
     * @readonly
     * @type {(ChargePeriodicityInfo | IncomePeriodicityInfo)}
     * @memberof TreasuryCardComponent2
     */
    public get periodicity(): ChargePeriodicityInfo | IncomePeriodicityInfo {
        const isCharge = this.treasury instanceof ChargeEntity;

        return isCharge
            ? new ChargePeriodicityInfo((this.treasury as ChargeEntity).periodicity)
            : new IncomePeriodicityInfo((this.treasury as IncomeEntity).periodicity);
    }

}
