import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { PropertyContestEntity } from '@omedom/data';
import { map, Observable } from 'rxjs';

import { RestService } from './rest.service';

@Injectable({
    providedIn: 'root',
})
export class PropertyContestService extends RestService<PropertyContestEntity> {
    constructor(protected override firestore: AngularFirestore) {
        super(firestore, 'propertyContest');
    }

    /**
     * @description Get the property contest of a user by user UID
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 10/12/2024
     * @param {string} userUID
     * @returns {*}  {Promise<PropertyContestEntity>}
     * @memberof PropertyContestService
     */
    public async getUserPropertyContest(userUID: string): Promise<PropertyContestEntity> {
        const propertyContests = await this.search([
            { where: 'userUID', operator: '==', value: userUID },
            { sortBy: 'created', direction: 'desc' },
            { limit: 1, limitToLast: false },
        ]);

        return propertyContests[0];
    }

    /**
     * @description Get the property contest of a user by user UID (Observable version)
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 10/12/2024
     * @param {string} userUID
     * @returns {*}  {Observable<PropertyContestEntity>}
     * @memberof PropertyContestService
     */
    public _getUserPropertyContest(userUID: string): Observable<PropertyContestEntity | null> {
        return this._search([
            { where: 'userUID', operator: '==', value: userUID },
            { sortBy: 'created', direction: 'desc' },
            { limit: 1, limitToLast: false },
        ]).pipe(map((propertyContests) => propertyContests[0] ?? null));
    }
}
