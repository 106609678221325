import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ChartDoughnutData, LoanEntity } from '@omedom/data';
import { OmedomChart } from '@omedom/utils';

import { elementAnimation } from '../../../animations/element.animation';

/* eslint-disable no-unused-vars */
/* eslint-disable @angular-eslint/component-class-suffix */

/**
 * @description Layout of the loan details container (default or mobile) to adapt the template to the screen size and orientation
 * @author Jérémie Lopez <jeremie.lopez@omedom.com>
 * @date 04/07/2024
 * @export
 * @enum {number}
 */
export enum LoanDetailsLayout {
    DEFAULT = 'default',
    MOBILE = 'mobile',
    DESKTOP = 'desktop',
}

@Component({
    selector: 'omedom-loan-details',
    templateUrl: './loan-details.container.html',
    styleUrls: ['./loan-details.container.scss'],
    animations: [elementAnimation],
})
export class LoanDetailsContainer implements OnChanges {
    /**
     * @description Loan data to display in the template
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 03/07/2024
     * @type {LoanEntity}
     * @memberof LoanDetailsContainer
     */
    @Input({ required: true })
    public loan!: LoanEntity;

    /**
     * @description Layout of the loan details container (default or mobile) to adapt the template to the screen size and orientation
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 04/07/2024
     * @type {LoanDetailsLayout}
     * @memberof LoanDetailsContainer
     */
    @Input()
    public layout: LoanDetailsLayout = LoanDetailsLayout.DEFAULT;

    /**
     * @description Data for the doughnut chart in the template
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 04/07/2024
     * @type {ChartDoughnutData[]}
     * @memberof LoanDetailsContainer
     */
    protected data: ChartDoughnutData[] = [];

    /**
     * @description Layout of the loan details container (default or mobile) to adapt the template to the screen size and orientation
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 12/07/2024
     * @protected
     * @memberof LoanDetailsContainer
     */
    protected LoanDetailsLayout = LoanDetailsLayout;

    constructor() {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['loan']) {
            // Check if the loan data has changed
            const loan = changes['loan'].currentValue as LoanEntity;
            const previousLoan = changes['loan'].previousValue as LoanEntity;

            if (!previousLoan) {
                this.updateData();
                return;
            }

            const keys = Object.keys(loan) as (keyof LoanEntity)[];
            const hasChanged = keys.some((key) => loan[key] !== previousLoan[key]);

            if (hasChanged) {
                this.updateData();
                return;
            }
        }
    }

    /**
     * @description Update the data for the doughnut chart in the template (capital restant dû and montant remboursé) with the loan data from the input property
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 04/07/2024
     * @private
     * @memberof LoanDetailsContainer
     */
    private updateData(): void {
        this.data = [
            {
                label: 'Capital restant dû',
                value: this.loan.remainingCapital || 0,
                color: OmedomChart.red,
            },
            {
                label: 'Montant remboursé',
                value: this.loan.repaidCapital || 0,
                color: OmedomChart.green,
            },
        ];
    }
}
