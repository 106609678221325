<div class="icon" (mouseleave)="popover?.dismiss()">
    @if (asset.image) {
        <div
            #popoverTrigger
            class="header-avatar margin-right-1"
            [id]="asset.id"
        >
            <img [src]="asset.image" alt="Avatar du bien" />
        </div>
    } @else {
        <div class="header-icon margin-right-1" [id]="asset.id">
            <!-- (mouseover)="presentPopover($event,asset)"
      (mouseleave)="dismissPopover()" -->
            <i class="uil" [class]="getDefaultIcon()"></i>
        </div>
    }

    @if (!showLabel) {
        <ion-popover
            size="cover"
            #popover
            [trigger]="asset.id"
            triggerAction="hover"
        >
            <ng-template>
                <ion-content class="ion-padding">{{ asset.label }}</ion-content>
            </ng-template>
        </ion-popover>
    }
</div>
@if (showLabel) {
    <div class="header-title">
        {{ asset.label }}
    </div>
}
