import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ExpertiseFieldOptions, optGroupOptions, proPositionOptions } from '@omedom/data';

@Component({
    selector: 'omedom-directory-search',
    templateUrl: './directory-search.component.html',
    styleUrls: ['./directory-search.component.scss'],
})
export class DirectorySearchComponent implements OnInit {
    @Output() searchChanged = new EventEmitter();

    /**
     * @description data search by user
     * @author ANDRE Felix
     * @type {string}
     * @memberof DirectorySearchComponent
     */
    public searchData?: string;

    public proOptions = [...ExpertiseFieldOptions, ...proPositionOptions];

    public proOptGroupOptions: optGroupOptions[] = [
        // {
        //     label: "Domaine d'expertise",
        //     options: ExpertiseFieldOptions,
        // },
        {
            label: 'Profession',
            options: proPositionOptions,
        },
    ];

    constructor() {}

    ngOnInit() {}

    sendSearch() {
        this.searchChanged.emit(this.searchData?.toLocaleLowerCase());
    }
}
