<div class="container">
    <div
        class="graph-header background-color-very-light-grey padding-1 omedom-border-radius"
    >
        <div
            class="omedom-card flex-1"
            [style.min-width]="
                'min(calc(370px - 190* max(min(100% - 560px + 1px, 1px), 0px)), 100%)'
            "
        >
            <div class="margin-bottom-1">Solde indicatif à date</div>
            <strong
                [class]="
                    totalIncome + totalCharge >= 0 ? 'color-green' : 'color-red'
                "
                >{{
                    totalIncome + totalCharge | number: '0.2-2'
                }}&nbsp;€</strong
            >
        </div>
        <div
            class="omedom-card flex-1"
            [style.min-width]="'min(calc(50% - 10px), 180px)'"
        >
            <div class="margin-bottom-1">
                <i class="uil uil-chart-pie-alt"></i>
                Total charges à date
            </div>
            <strong class="color-red"
                >{{ totalCharge | number: '0.2-2' }}&nbsp;€</strong
            >
        </div>
        <div
            class="omedom-card flex-1"
            [style.min-width]="'min(calc(50% - 10px), 180px)'"
        >
            <div class="margin-bottom-1">
                <i class="uil uil-euro-circle"></i>
                Total revenus à date
            </div>
            <strong class="color-green"
                >{{ totalIncome | number: '0.2-2' }}&nbsp;€</strong
            >
        </div>
    </div>

    <div class="graph-container w-100">
        <div
            class="graph w-100 background-color-very-light-grey padding-2 margin-y-2 omedom-border-radius"
        >
            <canvas
                baseChart
                [data]="chartData"
                [options]="chartOptions"
                type="bar"
            >
            </canvas>
        </div>
        <omedom-chart-legend
            [legends]="chartLegends"
            [forecastAuthorised]="forecastAuthorised"
            (forecastClicked)="forecastClicked.emit()"
        ></omedom-chart-legend>
    </div>

    <div class="graph-header margin-top-1">
        <div class="omedom-card flex-1">
            <div class="card-title margin-bottom-1">
                Cumul années précédentes
            </div>
            @if (
                cumulatedPreviousYearTreasury ||
                cumulatedPreviousYearTreasury === 0
            ) {
                <strong
                    [class]="
                        cumulatedPreviousYearTreasury >= 0
                            ? 'color-green'
                            : 'color-red'
                    "
                >
                    {{
                        cumulatedPreviousYearTreasury
                            | currency: 'EUR' : 'symbol' : '1.2-2'
                    }}
                </strong>
            } @else {
                <strong class="color-grey">--,-- €</strong>
            }
        </div>
        <div class="omedom-card flex-1">
            <div class="card-title margin-bottom-1">
                Solde prévisionnel année en-cours
            </div>
            @if (
                cumulatedYearTreasuryProjection ||
                cumulatedYearTreasuryProjection === 0
            ) {
                <strong
                    [class]="
                        cumulatedYearTreasuryProjection >= 0
                            ? 'color-green'
                            : 'color-red'
                    "
                >
                    {{
                        cumulatedYearTreasuryProjection
                            | currency: 'EUR' : 'symbol' : '1.2-2'
                    }}
                </strong>
            } @else {
                <strong class="color-grey">--,-- €</strong>
            }
        </div>
        <div class="omedom-card flex-1">
            <div class="card-title margin-bottom-1">Cumul prévisionnel</div>
            @if (
                cumulatedPreviousYearTreasury +
                    cumulatedYearTreasuryProjection ||
                cumulatedPreviousYearTreasury +
                    cumulatedYearTreasuryProjection ===
                    0
            ) {
                <strong
                    [class]="
                        cumulatedPreviousYearTreasury +
                            cumulatedYearTreasuryProjection >=
                        0
                            ? 'color-green'
                            : 'color-red'
                    "
                >
                    {{
                        cumulatedPreviousYearTreasury +
                            cumulatedYearTreasuryProjection
                            | currency: 'EUR' : 'symbol' : '1.2-2'
                    }}
                </strong>
            } @else {
                <strong class="color-grey">--,-- €</strong>
            }
        </div>
    </div>
</div>

@if (showDate) {
    <div class="omedom-sticky-button-container w-100">
        <omedom-date
            [type]="omedomDateType.year"
            (dateChange)="dateChange($event)"
        ></omedom-date>
    </div>
}
