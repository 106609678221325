<!-- Card -->
@if (society) {
    <div
        class="card card-{{ mode }} layout-{{ layout }} "
        [ngClass]="{ 'list-view': listView, 'card-view': !listView }"
        (click)="onSocietyClick(); $event.stopPropagation()"
        [style]="gridTemplateColumns"
    >
        <!-- Header -->
        <div
            class="header"
            [class.flex-1]="mode !== allMode.desktop"
            [ngClass]="{ 'list-view': listView, 'card-view': !listView }"
        >
            @if (society.photo) {
                <img
                    class="header-avatar"
                    [ngClass]="{
                        'list-view': listView,
                        'card-view': !listView
                    }"
                    [src]="society.photo"
                    alt="Photo de la société"
                />
            } @else {
                <div
                    class="header-icon"
                    [ngClass]="{
                        'list-view': listView,
                        'card-view': !listView
                    }"
                >
                    <i class="uil uil-suitcase"></i>
                </div>
            }
            <!-- Title in List view-->
            @if (listView) {
                <div
                    class="header-title-container list-view"
                    [class.flex-1]="mode !== allMode.desktop"
                >
                    <h3 class="header-title">
                        {{ society.name }}
                    </h3>
                    @if (!listView && mode === allMode.desktop) {
                        <span class="header-subtitle-address">
                            {{ getStringAddress(society) }}
                        </span>
                    }
                </div>
            } @else {
                <!-- Title in Card view-->
                <div class="header-title-container card-view">
                    <h4 class="header-title">
                        {{ society.name }}
                    </h4>
                </div>
            }
            <!-- Icons-->
            <ng-container
                *ngTemplateOutlet="
                    mode !== allMode.desktop ? iconContainer : null
                "
            ></ng-container>
        </div>
        <!-- Last and next movments in List view only-->
        @if (listView && showPastAndFutureMovements) {
            <div class="widgets">
                <div class="next-move">
                    @if (mode !== allMode.desktop) {
                        <div class="next-move-header">
                            <span class="next-move-title"
                                >Dernier mouvement</span
                            >
                        </div>
                    }
                    <omedom-movement-card
                        [story]="lastMouvement"
                        [isPropertyCard]="true"
                        [isSocietyStory]="true"
                        [mode]="mode"
                    ></omedom-movement-card>
                </div>
                <div class="next-move">
                    @if (mode !== allMode.desktop) {
                        <div class="next-move-header">
                            <span class="next-move-title"
                                >Prochain mouvement</span
                            >
                        </div>
                    }
                    <omedom-movement-card
                        [story]="nextMouvement"
                        [isPropertyCard]="true"
                        [isSocietyStory]="true"
                        [futureCard]="true"
                        [mode]="mode"
                    ></omedom-movement-card>
                </div>
            </div>
        }
    </div>
}

<ng-template #iconContainer>
    @if (listView) {
        <i class="uil uil-angle-right-b header-next"></i>
    }
</ng-template>
