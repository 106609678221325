import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { ChargeAssociateTo } from '@omedom/data';

@Component({
    selector: 'omedom-form-associate-to',
    templateUrl: './form-associate-to.component.html',
    styleUrls: ['./form-associate-to.component.scss'],
})
export class FormAssociateToComponent implements OnInit, OnChanges {
    @Input() propertyInfo?: ChargeAssociateTo;

    @Input() totalAmount: number = 0;

    @Input() disabled: boolean = true;

    @Output() percentageHasChanged = new EventEmitter();

    percentage: number = 0;
    amount: number = 0;
    constructor() {}

    ngOnInit() {
        if (this.propertyInfo) {
            this.percentage = this.propertyInfo.percentage;
            this.amount = this.propertyInfo.amount;
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['totalAmount']) {
            this.totalAmount = changes['totalAmount'].currentValue;
            this.amount = (this.totalAmount * this.percentage) / 100;
        }
    }

    percentageChange(event: number) {
        this.percentage = event;
        this.checkPercentageLimit();
        this.amount = (this.totalAmount * this.percentage) / 100;

        const value: Partial<ChargeAssociateTo> = {
            percentage: this.percentage,
            propertyUid: this.propertyInfo?.propertyUid,
        };
        this.percentageHasChanged.emit(value);
    }

    checkPercentageLimit() {
        if (this.percentage > 100) {
            this.percentage = 100;
        }
        if (this.percentage < 0) {
            this.percentage = 0;
        }
    }
    /**
     * @description return data from inputs in format
     * @author ANDRE Felix
     * @returns {*}  {*}
     * @memberof FormAssociateToComponent
     */
    getInputData(): any {
        if (!this.propertyInfo) {
            return {};
        }
        const associateTo: ChargeAssociateTo = {
            propertyUid: this.propertyInfo.propertyUid,
            propertyLabel: this.propertyInfo.propertyLabel,
            percentage: this.percentage,
            amount: this.amount,
        };
        return associateTo;
    }

    updatePercentage(percentage: number) {
        this.percentage = percentage - 1;
        this.percentage = percentage;
        this.amount = (this.totalAmount * this.percentage) / 100;
    }
}
