import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { ModalController, NavController, Platform, ToastController } from '@ionic/angular';
import {
    AudioBlobType,
    Category,
    Color,
    DocBlobType,
    DocumentEntity,
    DocumentTypeClient,
    EntityTypes,
    ImageBlobType,
    LoanEntity,
    PropertyEntity,
    RoleState,
    SavingEntity,
    SocietyEntity,
    UserEntity,
    VideoBlobType,
} from '@omedom/data';
import {
    DocumentService,
    FileService,
    LoanService,
    PropertyService,
    RoleService,
    SanitizerService,
    SavingService,
    SocietyService,
} from '@omedom/services';
import { BehaviorSubject, Subscription } from 'rxjs';

import { DocumentDeleteComponent } from '../document-delete/document-delete.component';

@Component({
    selector: 'omedom-document-viewer',
    templateUrl: './document-viewer.component.html',
    styleUrls: ['./document-viewer.component.scss'],
    providers: [FileService],
})
export class DocumentViewerComponent implements OnInit, OnDestroy {
    @Input()
    public document?: DocumentEntity;

    @Input()
    public documentUid?: string;

    @Input() navigationAfterSubmit = true;

    @Output()
    public editDocument: EventEmitter<DocumentEntity> = new EventEmitter<DocumentEntity>();

    /**
     * @description Role right of the user on the story
     * @author Didier Pascarel
     * @type {RoleState}
     * @memberof  DocumentCardComponent
     */
    roleRight?: RoleState;

    public property?: PropertyEntity;

    public society?: SocietyEntity;

    public loan?: LoanEntity;

    public saving?: SavingEntity;

    private imageBlobTypes = ImageBlobType;
    private audioBlobTypes = AudioBlobType;
    private docBlobTypes = DocBlobType;
    private videoBlobTypes = VideoBlobType;

    public sanitized$ = new BehaviorSubject<boolean>(false);

    public documentURL: SafeResourceUrl | null = null;

    public user?: UserEntity;

    public isAndroid = this.platform.is('android') || false;

    public pendingDocumentAction$ = new BehaviorSubject<boolean>(false);
    public pendingDocumentDeletion$ = new BehaviorSubject<boolean>(false);

    private subscriptions: Subscription[] = [];

    constructor(
        private documentService: DocumentService,
        private propertyService: PropertyService,
        private societyService: SocietyService,
        private loanService: LoanService,
        private savingService: SavingService,
        private route: ActivatedRoute,
        private sanitizer: DomSanitizer,
        private modalController: ModalController,
        private toastController: ToastController,
        private navController: NavController,
        private storage: AngularFireStorage,
        private platform: Platform,
        private roleService: RoleService,
        private fileService: FileService,
        private sanitizeService: SanitizerService
    ) {}

    public get icon(): string {
        return DocumentEntity.getIcon(this.document?.type ?? DocumentTypeClient.other);
    }

    public get backgroundIcon(): string {
        return DocumentEntity.getColor(this.document?.type ?? DocumentTypeClient.other);
    }

    public get fileName(): string {
        return `${this.document?.name.toLowerCase().replace(/ /g, '_')}.${
            this.document?.extensionName
        }`;
    }

    async ngOnInit(): Promise<void> {
        if (!this.document) {
            const documentUid = this.documentUid ?? this.route.snapshot.params['documentUid'];
            this.documentUid = documentUid;
            this.document = await this.documentService.get(documentUid);
        }

        if (this.document) {
            this.updateRoleRight();

            await this.getEntity();

            this.sanitizeURL(this.document.fileURL);

            this.sanitized$.next(true);
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((sub) => sub.unsubscribe());
    }

    async getEntity() {
        if (!this.document) {
            return;
        }
        if (this.document.propertyUID) {
            this.property = await this.propertyService.get(this.document.propertyUID);
        }

        if (this.document.societyUID) {
            this.society = await this.societyService.get(this.document.societyUID);
        }

        if (this.document.loanUID) {
            this.loan = await this.loanService.get(this.document.loanUID);
        }

        if (this.document.savingUID) {
            this.saving = await this.savingService.get(this.document.savingUID);
        }
    }

    sanitizeURL(url: string) {
        this.documentURL = this.sanitizeService.sanitizeFireStoreURL(url);
    }

    public async displayPDF(url?: string): Promise<void> {
        if (!url) {
            return;
        }
        await this.fileService.displayPDF(url);
    }

    public isDocumentColor(color: Color): boolean {
        return DocumentEntity.isColor(color, this.document?.type ?? DocumentTypeClient.other);
    }

    public isDocumentCategory(category: Category): boolean {
        return DocumentEntity.isCategory(category, this.document?.type ?? DocumentTypeClient.other);
    }

    public isBlobType(type: 'audio' | 'video' | 'image' | 'doc'): boolean {
        return Object.values(this[`${type}BlobTypes`]).includes(this.document?.extension as any);
    }

    public isBlobTypeOther(): boolean {
        return (
            !this.isBlobType('audio') &&
            !this.isBlobType('video') &&
            !this.isBlobType('image') &&
            !this.isBlobType('doc')
        );
    }

    public async delete(): Promise<void> {
        if (!this.roleRight || !this.roleRight.delete) {
            return;
        }
        // Create modal delete document
        const modal = await this.modalController.create({
            component: DocumentDeleteComponent,
            initialBreakpoint: 1,
            breakpoints: [0, 1],
            canDismiss: true,
        });

        await modal.present();

        modal.onDidDismiss().then(async (callback) => {
            if (callback?.data) {
                let toast: HTMLIonToastElement;

                try {
                    if (!this.document) {
                        throw new Error('Document not found');
                    }
                    const cleanedURL = this.document.fileURL
                        .toString()
                        // .split(' ')[4] // fileURL not include "file SafeValue must use [property]=binding" before the URL
                        .split('?')[0]
                        .replace(/%2F/g, '/')
                        .split('/')
                        .splice(7)
                        .join('/');

                    const decodeURL = decodeURI(cleanedURL);
                    const data = {
                        path: decodeURL,
                    };
                    this.pendingDocumentAction$.next(true);
                    this.pendingDocumentDeletion$.next(true);
                    await this.documentService.delete(this.document.uid);
                    // Create toast to display info
                    toast = await this.toastController.create({
                        position: 'top',
                        message: 'Le document a bien été supprimé',
                        duration: 2000,
                        color: 'warning',
                    });
                } catch (error) {
                    // Create toast to display info
                    toast = await this.toastController.create({
                        position: 'top',
                        message: "Un erreur s'est produite",
                        duration: 4000,
                        color: 'danger',
                    });
                }
                await toast.present();
                this.pendingDocumentAction$.next(false);
                this.pendingDocumentDeletion$.next(false);
                if (this.navigationAfterSubmit) {
                    this.navController.back();
                }

                if (await this.modalController.getTop()) {
                    await this.modalController.dismiss();
                    return;
                }
            }
        });
    }

    public async edit(): Promise<void> {
        if (!this.roleRight || !this.roleRight.update) {
            return;
        }
        let options: any;
        if (this.document?.propertyUID) {
            options = { queryParams: { propertyUid: this.document.propertyUID } };
        } else if (this.document?.societyUID) {
            options = { queryParams: { societyUid: this.document.societyUID } };
        }

        if (await this.modalController.getTop()) {
            await this.modalController.dismiss({ document: this.document });
            return;
        }

        this.editDocument.emit(this.document);

        if (this.navigationAfterSubmit) {
            this.navController.navigateForward(
                `/tabs/document/form/${this.document?.uid}`,
                options
            );
        }
    }

    async updateRoleRight() {
        if (!this.document) {
            return;
        }
        const role$ = this.roleService
            ._getRoleState(this.document, EntityTypes.document)
            .subscribe((role) => {
                this.roleRight = role;
            });
        this.subscriptions.push(role$);
    }
}
