import { Injectable, signal } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import { SubscriptionDto, SubscriptionEntity } from '@omedom/data';
import { lastValueFrom, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { RestService } from './rest.service';

@Injectable({
    providedIn: 'root',
})
export class SubscriptionService extends RestService<SubscriptionEntity> {
    subscription = signal<SubscriptionDto | undefined>(undefined);

    constructor(
        protected override firestore: AngularFirestore,
        private functions: AngularFireFunctions,
        protected auth: AngularFireAuth,
    ) {
        super(firestore, 'subscriptions');
    }

    /**
     * @description Get the subscription of a user in real time
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 10/08/2023
     * @param {string} userUID The user UID
     * @returns {Observable<SubscriptionEntity>} The subscription of the user
     * @memberof SubscriptionService
     * @deprecated
     * @example
     * this.subscriptionService.getSubscriptionByUser('userUID').subscribe((subscription) => {
     *    // Do something with the subscription
     * });
     */
    public getSubscriptionByUser(userUID: string): Observable<SubscriptionEntity> {
        return this._search([
            { where: 'userUID', operator: '==', value: userUID },
            { sortBy: 'created', direction: 'desc' },
        ]).pipe(map((subscriptions) => subscriptions[0]));
    }

    /**
     * @description Get the subscription of a user
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 10/08/2023
     * @param {string} userUID The user UID
     * @returns {Promise<SubscriptionEntity>} The subscription of the user
     * @memberof SubscriptionService
     * @deprecated
     * @example
     * const subscription = await this.subscriptionService.getSubscriptionByUserPromise('userUID');
     */
    public async getSubscriptionByUserPromise(userUID: string): Promise<SubscriptionEntity> {
        return (await this.search([{ where: 'userUID', operator: '==', value: userUID }]))[0];
    }

    /**
     * @description Get the subscription of a property in real time
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 10/08/2023
     * @param {string} propertyUID The property UID
     * @returns {Observable<SubscriptionEntity>} The subscription of the property
     * @memberof SubscriptionService
     * @deprecated
     * @example
     * this.subscriptionService.getSubscriptionByProperty('propertyUID').subscribe((subscription) => {
     *   // Do something with the subscription
     * });
     */
    public getSubscriptionByProperty(propertyUID: string): Observable<SubscriptionEntity> {
        return this._search([
            {
                where: 'propertiesUID',
                operator: 'array-contains',
                value: propertyUID,
            },
        ]).pipe(map((subscriptions) => subscriptions[0]));
    }

    /**
     * @description Get the subscription of a property
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 10/08/2023
     * @param {string} propertyUID The property UID
     * @returns {Promise<SubscriptionEntity>} The subscription of the property
     * @memberof SubscriptionService
     * @deprecated
     * @example
     * const subscription = await this.subscriptionService.getSubscriptionByPropertyPromise('propertyUID');
     */
    public async getSubscriptionByPropertyPromise(
        propertyUID: string,
    ): Promise<SubscriptionEntity> {
        const res = await this.search([
            {
                where: 'propertiesUID',
                operator: 'array-contains',
                value: propertyUID,
            },
        ]);

        return res[0];
    }

    /**
     * @description Cancel a subscription
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 10/08/2023
     * @param {string} subscriptionUID The subscription UID
     * @returns {Promise<void>}
     * @memberof SubscriptionService
     * @example
     * await this.subscriptionService.cancelSubscription('subscriptionUID');
     */
    public async cancelSubscription(subscriptionUID: string): Promise<void> {
        return lastValueFrom(
            this.functions.httpsCallable('cancelSubscription')({
                subscriptionUID,
            }),
        );
    }

    /**
     * @description Update a subscription with new propertiesUID
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 10/08/2023
     * @param {string} subscriptionUID The subscription UID
     * @param {string[]} propertiesUID The new propertiesUID
     * @returns {Promise<void>}
     * @memberof SubscriptionService$
     * @example
     * await this.subscriptionService.updateSubscription('subscriptionUID', ['propertyUID']);
     */
    public async updateSubscription(
        subscriptionUID: string,
        propertiesUID: string[],
    ): Promise<void> {
        return lastValueFrom(
            this.functions.httpsCallable('updateSubscription')({
                subscriptionUID,
                propertiesUID,
            }),
        );
    }

    public async updateLocalSubscription(): Promise<SubscriptionDto | undefined> {
        try {
            const subscription = await this.fetchUserSubscriptionFromApi();
            if (subscription) {
                this.subscription.set(subscription);
                return subscription;
            }
            return undefined;
        } catch (error) {
            this.subscription.set(undefined);
            return undefined;
        }
    }

    private fetchUserSubscriptionFromApi(): Promise<SubscriptionDto> {
        const callable = this.functions.httpsCallable('getCurrentSubscription');
        return lastValueFrom(callable(null));
    }
}
