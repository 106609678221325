import { Component, Input, ViewChild } from '@angular/core';
import { IonModal, ModalController } from '@ionic/angular';
import {
    Appearance,
    DataTableAction,
    DataTableColumn,
    Primitive,
    ProAlertEntity,
    ProEntity,
} from '@omedom/data';
import { lastValueFrom, Observable, of } from 'rxjs';
import { ValidationModalComponent } from '../../components/validation-modal/validation-modal.component';
import {
    ChatMessageService,
    ChatService,
    ClientService,
    ProAlertService,
    UserService,
} from '@omedom/services';
import { Router } from '@angular/router';

@Component({
    selector: 'omedom-pro-alert-list',
    templateUrl: './pro-alert-list.component.html',
    styleUrls: ['./pro-alert-list.component.scss'],
})
export class ProAlertListComponent {
    /**
     * @description Pro alert
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 24/09/2024
     * @type {ProAlertEntity[]}
     * @memberof ProAlertListComponent
     */
    @Input() proAlerts$: Observable<ProAlertEntity[]> = of([]);
    /**
     * @description Pro data
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 19/10/2023
     * @type {ProEntity}
     * @memberof ClientListComponent
     */
    @Input()
    public pro?: ProEntity;

    @ViewChild('modal') modal?: IonModal;

    /**
     * @description Empty message when the pro doesn't have client
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 19/10/2023
     * @type {string}
     * @memberof ClientListComponent
     */
    @Input()
    public emptyMessage: string = `Aucun résultat pour cette recherche.`;

    /**
     * @description Link prefix to redirect to client profil
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 19/10/2023
     * @type {string}
     * @memberof ClientListComponent
     */
    @Input()
    public linkPrefix: string = '/clients';

    /**
     * @description Columns to display in the table (label, key, type, transformer, validator) to display in the table
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 12/09/2023
     * @type {DataTableColumn<UserEntity>[]}
     * @memberof ClientListComponent
     */
    @Input()
    public columns: DataTableColumn<ProAlertEntity>[] = [
        // {
        //     label: '',
        //     key: 'clientUID',
        //     type: Primitive.picture,
        //     sortable: false,
        //     transformer: (data: ProAlertEntity) => {
        //         const user = this.clientsUsers.find((user) => user.uid === data.userUID);
        //         return user?.avatar?.startsWith('https') ? user.avatar : 'user-circle';
        //     },
        //     width: '70px',
        // },
        {
            label: 'Prénom',
            key: 'clientFirstname',
            type: Primitive.string,
            sortable: true,
        },
        {
            label: 'Nom',
            key: 'clientName',
            type: Primitive.string,
            sortable: true,
        },
        {
            label: 'Alerte',
            key: 'title',
            type: Primitive.string,
            sortable: true,
        },

        {
            label: 'Contenue',
            key: 'body',
            type: Primitive.string,
        },
    ];

    /**
     * @description Action on the data by row (icon, title, callback, appearance) to display in the table
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 12/09/2023
     * @type {DataTableAction<UserEntity>[]}
     * @memberof ClientListComponent
     */
    @Input()
    public actions: DataTableAction<ProAlertEntity>[] = [
        {
            icon: 'envelope',
            callback: async (data: ProAlertEntity) => {
                await this.createChatWithClient(data);
            },
            appearance: Appearance.primary,
            minimal: true,
        },
        {
            icon: 'check',
            callback: async (data: ProAlertEntity) => {
                await this.openValidTreadModal(data);
            },
            appearance: Appearance.secondary,
            minimal: false,
        },
    ];

    /**
     * @description True if we let the possibility to disable the action on the data by row to display in the table, false otherwise (default: false)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 20/10/2023
     * @type {boolean}
     * @memberof ClientListComponent
     */
    @Input()
    public useDisabledCondition: boolean = false;

    constructor(
        private readonly modalController: ModalController,
        private readonly proAlertService: ProAlertService,
        private readonly userService: UserService,
        private readonly clientService: ClientService,
        private readonly chatService: ChatService,
        private readonly chatMessageService: ChatMessageService,
        private readonly router: Router
    ) {}

    // ngOnInit() {}

    /**
     * @description Open the validation modal to validate the tread of the alert
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 01/10/2024
     * @private
     * @param {ProAlertEntity} proAlert
     * @memberof ProAlertListComponent
     */
    private async openValidTreadModal(proAlert: ProAlertEntity) {
        const modal = await this.modalController.create({
            component: ValidationModalComponent,
            canDismiss: true,
            initialBreakpoint: 1,
            breakpoints: [0, 1],
            backdropDismiss: true,
            componentProps: {
                title: "Traiter l'alerte",
                message: 'Voulez-vous valider cette alerte ?',
                // information: 'Félicitations, vous venez de créer une propriété',
                iconInfo: '',
                validateButtonMessage: 'Valider',
                cancelButtonMessage: 'Plus tard',
            },
        });
        await modal.present();

        await modal.onDidDismiss().then(async (res) => {
            const confirmation = res.data;
            try {
                await this.proAlertService.update({
                    uid: proAlert.uid,
                    isTreated: confirmation ? true : false,
                });
            } catch (error) {
                console.error(error);
            }
        });
    }

    private async createChatWithClient(proAlert: ProAlertEntity) {
        try {
            await this.modal?.present();

            await this.modal?.onDidDismiss().then(async (res) => {
                const data = res.data;

                const chatName = data?.chatName;

                const message = data?.message;

                const treat = data?.treat;

                if (!chatName || !message) {
                    return;
                }

                const chatRef = await this.chatService.create({
                    userUIDs: [proAlert.userUID, proAlert.clientUID],
                    creatorUID: proAlert.userUID,
                    name: chatName,
                });

                await this.chatMessageService.create({
                    chatUID: chatRef.id,
                    userUID: proAlert.userUID,
                    content: message,
                });

                if (treat) {
                    await this.proAlertService.update({
                        uid: proAlert.uid,
                        isTreated: true,
                    });
                }

                await this.router.navigate(['/messaging'], {
                    queryParams: { chatUID: chatRef.id },
                });
            });
        } catch (error) {
            console.error(error);
        }
    }
}
