import { DateInterval } from '@omedom/data';
import { formatISO } from 'date-fns';
import { Timestamp } from 'firebase/firestore';

export class OmedomDate {
    public static INVALIDE_DATE = 'Invalid Date';

    public static formatDateToISO(dateToFormat: Date) {
        return formatISO(dateToFormat);
    }

    public static timestampToMilliseconds = (timestamp: Timestamp): number => {
        return timestamp.seconds * 1000 + timestamp.nanoseconds / 1000000;
    };

    /**
     * @description Get the number of days in a period of time (date interval) in days. The number of days is calculated by the difference between the end date and the start date in milliseconds divided by the number of milliseconds in a day (1000 * 60 * 60 * 24). The result is rounded to the nearest whole number using the Math.round() method. The result is returned as a number of days. This method is used to calculate the number of days in a period of time (date interval) in days. It is used to calculate the number of days in the document period and the fiscal period. It is used to calculate the amounts of the rent and charges for the document
     * @author Didier Pascarel <didier.pascarel@omedom.com>
     * @date 29/02/2024
     * @private
     * @static
     * @param {DateInterval} dateInterval
     * @returns {*}  {number}
     * @memberof ReceiptAndRelaunchData
     */
    public static numberOfDaysInAPeriod(dateInterval: DateInterval): number {
        return Math.round(
            (dateInterval.endDate.getTime() - dateInterval.startDate.getTime()) /
                (1000 * 60 * 60 * 24),
        );
    }

    /**
     * @description format Date to this format: 'le *jour à *heure'
     * @author ANDRE Felix
     * @memberof OmedomDate
     */

    public static formatDateToDayAndHour(date: Date) {
        const dayFormatter = new Intl.DateTimeFormat('fr-FR', {
            weekday: 'long',
            month: 'long',
            day: 'numeric',
            timeZone: 'Europe/Paris',
        });
        const timeFormatter = new Intl.DateTimeFormat('fr-FR', {
            hour: '2-digit',
            minute: '2-digit',
            timeZone: 'Europe/Paris',
        });

        const dayWithDate = dayFormatter.format(date);
        const time = timeFormatter.format(date);

        return `le ${dayWithDate}, à ${time}`;
    }

    // Convert Firestore timestamp to JavaScript Date object
    public static convertTimestampToDate(timestamp: any): Date | undefined {
        if (timestamp && timestamp.toDate && typeof timestamp.toDate === 'function') {
            return timestamp.toDate();
        } else if (timestamp && typeof timestamp === 'object') {
            return new Date(timestamp);
        }
        return undefined;
    }

    /**
     * @description Return true if the date plus the number of month is inferior to now
     * @author ANDRE Felix
     * @param {Date} date
     * @param {number} [month=3]
     * @returns {*}
     */
    public static isDateExpireInMonth(date: Date, month = 3) {
        const now = new Date();
        const datePlusMonths = new Date(date);
        datePlusMonths.setMonth(date.getMonth() + month);

        return datePlusMonths < now;
    }
}
