import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { IonModal, ToastController } from '@ionic/angular';
import { OverlayEventDetail } from '@ionic/core';
import { AssetType, AssetTypes, SelectOption, Sharing, SocietyRoleMember, UserEntity } from '@omedom/data';
import { PropertyService, SocietyService, UserService } from '@omedom/services';
import { OmedomRegex } from '@omedom/utils';
import { Observable, of } from 'rxjs';

interface SharingWithRoleOption extends Sharing {
    roleOptions: SelectOption[];
}

@Component({
    selector: 'omedom-asset-sharing-card',
    templateUrl: './asset-sharing-card.container.html',
    styleUrls: ['./asset-sharing-card.container.scss'],
})
export class AssetSharingCardContainer implements OnInit, OnChanges {
    /**
     * @description Asset to share
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 19/12/2024
     * @type {AssetType}
     * @memberof AssetSharingCardContainer
     */
    @Input({ required: true }) asset!: AssetType;

    /**
     * @description Asset options
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 19/12/2024
     * @readonly
     * @type {SelectOption}
     * @memberof AssetSharingCardContainer
     */
    public get assetOptions(): SelectOption {
        return this.asset.toSelectOption!();
    }

    /**
     * @description Email regex to validate email input field
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 19/12/2024
     * @memberof AssetSharingCardContainer
     */
    public emailRegex = OmedomRegex.emailRegex;

    /**
     * @description User in application to check if email is not the owner email
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 20/12/2024
     * @type {UserEntity}
     * @memberof AssetSharingCardContainer
     */
    @Input({ required: true })
    public user?: UserEntity;

    /**
     * @description User owner of the asset to check if email is not the owner email
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 09/01/2025
     * @type {UserEntity}
     * @memberof AssetSharingCardContainer
     */
    public userOwner$: Observable<UserEntity | undefined> = of(undefined);
    /**
     * @description List of sharings with role options for each sharing
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 19/12/2024
     * @type {SharingWithRoleOption[]}
     * @memberof AssetSharingCardContainer
     */
    public sharings: SharingWithRoleOption[] = [];

    /**
     * @description Check if all sharings are valid (email, role, name, firstname) to enable save button
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 19/12/2024
     * @readonly
     * @type {boolean}
     * @memberof AssetSharingCardContainer
     */
    public get allSharingsValid(): boolean {
        return this.allSharingsAreFilled && this.notDuplicateEmail && this.notEmailOfOwner;
    }

    /**
     * @description Check if all sharings are filled (email, role, name, firstname) to enable save button
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 02/01/2025
     * @readonly
     * @type {boolean}
     * @memberof AssetSharingCardContainer
     */
    public get allSharingsAreFilled(): boolean {
        return (
            this.sharings?.every((sharing) => {
                return (
                    sharing.email &&
                    sharing.role &&
                    sharing.email.match(this.emailRegex) &&
                    sharing.name &&
                    sharing.firstname
                );
            }) || false
        );
    }

    /**
     * @description Check if all sharings have different emails to enable save button
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 02/01/2025
     * @readonly
     * @type {boolean}
     * @memberof AssetSharingCardContainer
     */
    public get notDuplicateEmail(): boolean {
        return this.sharings?.every(
            (sharing, index) =>
                !this.sharings?.some(
                    (s, i) => i !== index && s.email === sharing.email && s.email !== '',
                ),
        );
    }

    /**
     * @description Check if all sharings have different emails from the owner email to enable save button
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 02/01/2025
     * @readonly
     * @type {boolean}
     * @memberof AssetSharingCardContainer
     */
    public get notEmailOfOwner(): boolean {
        return !!this.user && this.sharings?.every((sharing) => sharing.email !== this.user?.email);
    }

    /**
     * @description Error message to display if sharings are not valid to enable save button
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 02/01/2025
     * @readonly
     * @type {(string | undefined)}
     * @memberof AssetSharingCardContainer
     */
    public get errorMessage(): string | undefined {
        let message: string | undefined;
        if (!this.notDuplicateEmail) {
            message = 'Les emails des partages ne peuvent pas être identiques';
        } else if (!this.notEmailOfOwner) {
            message = 'Vous ne pouvez pas vous partagez un bien avec vous-même';
        } else if (!this.allSharingsAreFilled) {
            message = 'Veuillez remplir tous les champs';
        }
        return message;
    }

    /**
     * @description Check if sharings have been modified compared to the asset sharings
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 02/01/2025
     * @readonly
     * @type {boolean}
     * @memberof AssetSharingCardContainer
     */
    public get hasModifiedSharings(): boolean {
        return this.hasSharingsChanged(this.asset, this.sharings);
    }

    public get isOwner(): boolean {
        if (!this.user?.uid || !this.asset?.userUID) {
            return false;
        }
        return this.user.uid === this.asset.userUID;
    }

    public get role(): SocietyRoleMember | undefined {
        if (!this.user?.email || !this.asset?.sharing) {
            return undefined;
        }
        const sharing = this.asset.sharing.find((sharing) => sharing.email === this.user?.email);

        return sharing?.role;
    }

    constructor(
        private readonly propertyService: PropertyService,
        private readonly societyService: SocietyService,
        private readonly toastController: ToastController,
        private readonly userService: UserService,
    ) {}

    ngOnInit() {
        this.sharings =
            this.asset.sharing?.map((sharing) => {
                return {
                    ...sharing,
                    roleOptions: [
                        {
                            id: SocietyRoleMember.reader,
                            label: SocietyRoleMember.reader,
                        } as SelectOption,
                        {
                            id: SocietyRoleMember.editor,
                            label: SocietyRoleMember.editor,
                        } as SelectOption,
                    ],
                };
            }) || [];

        this.userOwner$ = this.isOwner
            ? of(this.user)
            : this.asset.userUID
              ? this.userService._get(this.asset.userUID)
              : of(undefined);
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes['asset']?.currentValue) {
            const previousAsset = changes['asset'].previousValue as AssetType | undefined;

            if (previousAsset && this.hasSharingsChanged(previousAsset, this.sharings)) {
                // Keep the modified sharings
            } else {
                this.sharings =
                    (changes['asset'].currentValue as AssetType).sharing?.map((sharing) => {
                        return {
                            ...sharing,
                            roleOptions: [
                                {
                                    id: SocietyRoleMember.reader,
                                    label: SocietyRoleMember.reader,
                                } as SelectOption,
                                {
                                    id: SocietyRoleMember.editor,
                                    label: SocietyRoleMember.editor,
                                } as SelectOption,
                            ],
                        };
                    }) || [];
            }
        }

        if (changes['user']?.currentValue || changes['asset']?.currentValue) {
            const currentAsset = changes['asset']?.currentValue as AssetType | undefined;
            const currentUser = changes['user']?.currentValue as UserEntity | undefined;
            this.userOwner$ = this.isOwner
                ? of(currentUser)
                : currentAsset?.userUID
                  ? this.userService._get(currentAsset.userUID)
                  : of(undefined);
        }
    }

    /**
     * @description Remove sharing from sharings list at index position
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 19/12/2024
     * @param {Sharing} sharing
     * @param {number} index
     * @memberof AssetSharingCardContainer
     */
    async removeSharing(modal: IonModal, sharing: Sharing, index: number) {
        if (sharing) {
            let message = ``;
            switch (true) {
                case !!sharing.email && !!sharing.name && !!sharing.firstname:
                    message = `Voulez-vous vraiment révoquer l'accès de <strong>${sharing.firstname} ${sharing.name} (${sharing.email})</strong>  ?`;
                    break;
                case !!sharing.email && !!sharing.name:
                    message = `Voulez-vous vraiment révoquer l'accès de <strong>${sharing.name} (${sharing.email})</strong> ?`;
                    break;
                case !!sharing.email && !!sharing.firstname:
                    message = `Voulez-vous vraiment révoquer l'accès de <strong>${sharing.firstname} (${sharing.email})</strong> ?`;
                    break;
                case !!sharing.email:
                    message = `Voulez-vous vraiment révoquer l'accès de <strong>${sharing.email}</strong>  ?`;
                    break;
                default:
                    message = `Voulez-vous vraiment révoquer l'accès à ce bien ?`;
                    break;
            }

            modal.componentProps = {
                message,
            };
        }
        await modal.present();

        await modal.onDidDismiss().then((res: OverlayEventDetail<boolean>) => {
            if (res.data) {
                this.sharings.splice(index, 1);
            }
        });
    }

    /**
     * @description Save sharings to the asset in database
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 20/12/2024
     * @memberof AssetSharingCardContainer
     */
    async saveSharings() {
        const propertyType = [AssetTypes.building, AssetTypes.property];
        let toast: HTMLIonToastElement | undefined;
        try {
            if (this.asset && this.assetOptions.assetType) {
                if (propertyType.includes(this.assetOptions.assetType)) {
                    await this.propertyService.update({
                        sharing: this.sharings,
                        uid: this.asset.uid,
                    });
                } else if (this.assetOptions.assetType === AssetTypes.society) {
                    await this.societyService.update({
                        sharing: this.sharings,
                        uid: this.asset.uid,
                    });
                }
                toast = await this.toastController.create({
                    position: 'top',
                    color: 'primary',
                    duration: 5000,
                    message: `Les partages de ${this.assetOptions.label} ont été mis à jour`,
                });
            } else {
                throw new Error('Asset type is not defined');
            }
        } catch (error) {
            toast = await this.toastController.create({
                position: 'top',
                color: 'danger',
                duration: 5000,
                message: "Une erreur s'est produite lors de la mise à jour des partages",
            });
        }

        if (await this.toastController.getTop()) {
            await this.toastController.dismiss();
        }

        if (toast) {
            await toast.present();
        }
    }

    /**
     * @description Add a new sharing to the sharings list
     * with default role reader and empty email, name, firstname fields
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 19/12/2024
     * @memberof AssetSharingCardContainer
     */
    addSharing() {
        if (!this.sharings) {
            this.sharings = [];
        }
        this.sharings.push({
            role: SocietyRoleMember.reader,
            email: '',
            name: '',
            firstname: '',
            roleOptions: [
                {
                    id: SocietyRoleMember.reader,
                    label: SocietyRoleMember.reader,
                } as SelectOption,
                {
                    id: SocietyRoleMember.editor,
                    label: SocietyRoleMember.editor,
                } as SelectOption,
            ],
        });
    }

    /**
     * @description Check if sharings have changed compared to the asset sharings
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 20/12/2024
     * @private
     * @param {AssetType} asset
     * @param {SharingWithRoleOption[]} sharings
     * @returns {*}  {boolean}
     * @memberof AssetSharingCardContainer
     */
    private hasSharingsChanged(asset: AssetType, sharings: SharingWithRoleOption[]): boolean {
        if (!asset.sharing && sharings.length > 0) {
            return true;
        }

        if (asset.sharing && asset.sharing.length !== sharings.length) {
            return true;
        }

        return (
            asset.sharing?.some((sharing, index) => {
                const currentSharing = sharings[index];
                return (
                    !currentSharing ||
                    sharing.email !== currentSharing.email ||
                    sharing.role !== currentSharing.role ||
                    sharing.name !== currentSharing.name ||
                    sharing.firstname !== currentSharing.firstname
                );
            }) || false
        );
    }
}
