import {
    Component,
    EventEmitter,
    Input,
    OnDestroy,
    OnInit,
    Output,
    ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import {
    IonRefresher,
    LoadingController,
    ModalController,
    Platform,
    ToastController,
} from '@ionic/angular';
import {
    defaultRoleState,
    EntityTypes,
    PDFUsage,
    PropertyEntity,
    RoleState,
    TutorialVideoName,
    UserEntity,
} from '@omedom/data';
import {
    AnalyticsService,
    PdfService,
    PropertyService,
    RoleService,
    TutorialVideoService,
    UserService,
} from '@omedom/services';
import { Subscription } from 'rxjs';

import { showLoading } from '../../functions/loader';
import { ModalPdfComponent } from '../modal-pdf/modal-pdf.component';
import { OmedomRadioOption } from '../radio';
import { OmedomSubTab } from '../sub-tab/omedom-sub-tab';
import { PropertyInfoTab } from './property-info-tab';

@Component({
    selector: 'omedom-property-info',
    templateUrl: './property-info.component.html',
    styleUrls: ['./property-info.component.scss'],
})
export class PropertyInfoComponent implements OnInit, OnDestroy {
    @Input() property?: PropertyEntity;
    @Input() editable: boolean = false;
    @Input() propertyImg?: string;
    @Input() missingDataInView: boolean = false;

    @Output() goToMissingDataView = new EventEmitter<void>();

    subTabs: OmedomSubTab[] = [
        new OmedomSubTab({ id: 1, label: 'Description' }),
        new OmedomSubTab({ id: 2, label: 'Valorisation' }),
        // new OmedomSubTab({ id: 3, label: 'Synthèse' }),
    ];

    selectedSubTab: OmedomSubTab = this.subTabs[0];

    propertyInfoTab = PropertyInfoTab;

    documentOptions: OmedomRadioOption[] = [
        new OmedomRadioOption({ id: true, label: 'Oui' }),
        new OmedomRadioOption({ id: false, label: 'Non' }),
    ];

    private toast?: HTMLIonToastElement;

    /**
     * @description If true, the app is in desktop mode
     * @author Jérémie Lopez
     * @readonly
     * @type {boolean}
     * @memberof PropertyTenantComponent
     */
    public get isDesktop(): boolean {
        return this.platform.is('desktop');
    }

    public get isMyProperty(): boolean {
        return (
            !!this.property &&
            !!this.property.userUID &&
            !!this.user &&
            this.property.userUID === this.user.uid
        );
    }

    /**
     * @description Access to pull to refresh
     * @author Jérémie Lopez
     * @type {IonRefresher}
     * @memberof PropertyTenantComponent
     */
    @ViewChild('refresher', { static: false })
    refresher?: IonRefresher;
    user?: UserEntity;
    subscriptions: Subscription[] = [];
    /**
     * @description informe si la modal de suppression de bien est ouverte
     * @author ANDRE Felix
     * @memberof PropertyInfoComponent
     */
    isDeleteModalOpen = false;

    private infoPropertyPDFModal?: HTMLIonModalElement;
    private loadingModal?: HTMLIonLoadingElement;
    // /**
    //  * @description Trigger loader during pdf creation
    //  * @author ANDRE Felix
    //  * @memberof PropertyInfoComponent
    //  */
    // public loader = false;

    public userRoleState: RoleState = defaultRoleState;

    private valoTutorialVideoLink?: string;

    constructor(
        private propertyService: PropertyService,
        private userService: UserService,
        private platform: Platform,
        private toastController: ToastController,
        private modalController: ModalController,
        private loadingController: LoadingController,
        private roleService: RoleService,
        private router: Router,
        private PDFService: PdfService,
        private analyticsService: AnalyticsService,
        private tutorialVideoService: TutorialVideoService,
    ) {}

    async ngOnInit() {
        this.selectedSubTab = this.subTabs[0];
        const user$ = this.userService.user$.subscribe((user) => {
            this.user = user;
        });

        this.subscriptions.push(user$);

        if (this.property) {
            this.userRoleState = await this.roleService.getRoleState(
                this.property,
                EntityTypes.property,
            );
        }

        await this.getTutorialVideoLink();
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    async refresh(fromRefresher?: any): Promise<void> {
        if (!this.property) {
            return;
        }
        this.property = await this.propertyService.get(this.property.uid);
        if (fromRefresher) {
            this.refresher?.complete();
        }
    }

    /**
     * @description Create a PDF with property infos
     * @author ANDRE Felix
     * @memberof PropertyInfoComponent
     */
    async createPropertyPDF() {
        if (!this.property) {
            return;
        }

        this.loadingModal = await showLoading(
            'PDF en cours de création...',
            this.loadingController,
        );
        const PDFCreatedDocument = await this.PDFService.createPDF(
            this.property,
            PDFUsage.propertyInfo,
        );

        if (PDFCreatedDocument.documentURL && PDFCreatedDocument.documentID) {
            this.toast = await this.toastController.create({
                position: 'top',
                color: 'primary',
                duration: 3000,
                message: 'Le pdf a été enregistré dans les documents de votre bien',
            });

            this.infoPropertyPDFModal = await this.openPDFModal(
                PDFCreatedDocument.documentURL,
                PDFCreatedDocument.documentID,
                this.userRoleState,
            );
        } else {
            this.toast = await this.toastController.create({
                position: 'top',
                color: 'danger',
                duration: 3000,
                message: "Le pdf n'a pas pu être enregistré",
            });
        }

        this.loadingModal.dismiss();
        this.analyticsService.logEvent('Property PDF info created');
        await this.toast.present();
    }

    private async openPDFModal(
        fileURL: string,
        documentUID: string,
        userRoleState: RoleState,
    ): Promise<HTMLIonModalElement> {
        const pdfModal = await this.modalController.create({
            component: ModalPdfComponent,
            breakpoints: [0, 1],
            initialBreakpoint: 1,
            componentProps: {
                loading: false,
                infoLabel: 'Ce pdf est automatiquement stocké dans les documents de votre bien.',
                title: 'Prévisualisation de la fiche informative de votre bien',
                fileURL: fileURL,
                documentUID: documentUID,
                userRoleState: userRoleState,
                propertyUID: this.property?.uid,
                modalController: this.modalController,
            },
        });

        await pdfModal.present();
        return pdfModal;
    }

    public goToSub() {
        this.router.navigate(['/tabs/menu/subscription/manage']);
    }

    async getTutorialVideoLink() {
        this.valoTutorialVideoLink = await this.tutorialVideoService.getVideoLinkByName(
            TutorialVideoName.valorisation,
        );
    }

    /**
     * @descriptionGo to youtube to watch video tuto
     * @author ANDRE Felix
     * @returns {*}
     * @memberof PropertyInfoComponent
     */
    public goToValoTutoVideo() {
        if (!this.valoTutorialVideoLink) {
            return;
        }
        window.location.href = this.valoTutorialVideoLink;
    }
}
