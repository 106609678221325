import { Component, HostBinding, Input } from '@angular/core';

import { elementAnimation, overlayAnimation } from '../../animations';

/**
 * @description Lock the content of the component if the isLocked property is set to true
 * @author Jérémie Lopez <jeremie.lopez@omedom.com>
 * @date 04/06/2024
 * @export
 * @class LockComponent
 */
@Component({
    selector: 'omedom-lock',
    templateUrl: './lock.component.html',
    styleUrls: ['./lock.component.scss'],
    animations: [elementAnimation, overlayAnimation],
})
export class LockComponent {
    /**
     * @description Used to determine if the component is locked or not
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 04/06/2024
     * @type {boolean}
     * @memberof LockComponent
     */
    @Input()
    public isLocked: boolean = false;

    /**
     * @description Type of lock to apply to the component and its content
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 04/06/2024
     * @type {LockType}
     * @memberof LockComponent
     */
    @Input()
    public type: 'view' | 'container' | 'top' = 'view';

    /**
     * @description
     * @author ANDRE Felix
     * @memberof LockComponent
     */
    @Input() bottomAuto = false;

    /**
     * @description Add the class 'locked' to the component if the isLocked property is set to true
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 04/06/2024
     * @readonly
     * @type {boolean}
     * @memberof LockComponent
     */
    @HostBinding('class.locked')
    get locked(): boolean {
        return this.isLocked;
    }
}
