import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { TutorialVideoEntity } from '@omedom/data';

@Component({
    selector: 'omedom-essential-video',
    templateUrl: './essential-video.component.html',
    styleUrls: ['./essential-video.component.scss'],
})
export class EssentialVideoComponent {
    @Input({ required: true }) video!: TutorialVideoEntity;
    @Input() isYoutubeVideo = false;

    constructor(private router: Router) {}

    public goToSubscription(): void {
        this.router.navigate(['/menu'], { queryParams: { menu: 'subscription' } });
    }
}
