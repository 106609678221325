import { Component, Input } from '@angular/core';
import { SavingEntity } from '@omedom/data';

import { elementAnimation } from '../../../animations/element.animation';

/* eslint-disable @angular-eslint/component-class-suffix */
/* eslint-disable no-unused-vars */

/**
 * @description Layout of the saving details container (default or mobile) to adapt the template to the screen size and orientation
 * @author Jérémie Lopez <jeremie.lopez@omedom.com>
 * @date 04/07/2024
 * @export
 * @enum {number}
 */
export enum SavingDetailsLayout {
    DEFAULT = 'default',
    MOBILE = 'mobile',
    DESKTOP = 'desktop',
}

@Component({
    selector: 'omedom-saving-details',
    templateUrl: './saving-details.container.html',
    styleUrls: ['./saving-details.container.scss'],
    animations: [elementAnimation],
})
export class SavingDetailsContainer {
    /**
     * @description Saving data to display in the template
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 03/07/2024
     * @type {SavingEntity}
     * @memberof SavingDetailsContainer
     */
    @Input({ required: true })
    public saving!: SavingEntity;

    /**
     * @description Layout of the saving details container (default or mobile) to adapt the template to the screen size and orientation
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 04/07/2024
     * @type {SavingDetailsLayout}
     * @memberof SavingDetailsContainer
     */
    @Input()
    public layout: SavingDetailsLayout = SavingDetailsLayout.DEFAULT;

    constructor() {}

    /**
     * @description Get the deposit amount of the saving entity in percentage of the total amount of the saving entity compare to withdraw amount of the saving entity in percentage of the total amount of the saving entity
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 12/07/2024
     * @readonly
     * @type {number}
     * @memberof SavingDetailsContainer
     */
    public get deposit(): number {
        // Check if the saving entity has a balance
        if (!this.saving.balance) {
            return 0;
        }

        // Check if the saving entity has a deposit amount
        if (!this.saving.depositAmount) {
            return 0;
        }

        // Return the deposit amount of the saving entity in percentage of the total amount of the saving entity
        return (this.saving.depositAmount / this.saving.balance) * 100;
    }

    /**
     * @description Get the withdraw amount of the saving entity in percentage of the total amount of the saving entity compare to deposit amount of the saving entity in percentage of the total amount of the saving entity
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 12/07/2024
     * @readonly
     * @type {number}
     * @memberof SavingDetailsContainer
     */
    public get withdraw(): number {
        // Check if the saving entity has a balance
        if (!this.saving.balance) {
            return 0;
        }

        // Check if the saving entity has a withdraw amount
        if (!this.saving.withdrawAmount) {
            return 0;
        }

        // Return the withdraw amount of the saving entity in percentage of the total amount of the saving entity
        return (this.saving.withdrawAmount / this.saving.balance) * 100;
    }

    /**
     * @description Get the filled limit of the saving (balance / limit) to display in the progress bar
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 11/07/2024
     * @readonly
     * @type {number}
     * @memberof SavingDetailsContainer
     */
    public get filledLimit(): number {
        // If the saving limit is 0, we return 0 to avoid division by 0
        if (!this.saving.limit) {
            return 0;
        }

        return (this.saving.balance / this.saving.limit) * 100;
    }
}
