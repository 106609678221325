export enum CollectionNames {
    Automations = 'automations',
    BankAccounts = 'bankAccounts',
    BankCategories = 'bankCategories',
    BankItemRefreshStatus = 'bankItemRefreshStatus',
    BankItems = 'bankItems',
    BankTransactions = 'bankTransactions',
    Banks = 'banks',
    Charges = 'charges',
    ChatMessages = 'chatMessages',
    Chats = 'chats',
    Clients = 'clients',
    // Codes = 'codes', // Not use actually, to delete ?
    Configuration = 'configuration',
    Contacts = 'contacts',
    Documents = 'documents',
    Family = 'family',
    FamilyInvitations = 'familyInvitations',
    Incomes = 'incomes',
    Leases = 'leases',
    Loans = 'loans',
    // Logs = 'logs', // too big, manipulate with caution
    // MetaData = 'metaData',
    Notifications = 'notifications',
    Payments = 'payments',
    Properties = 'properties',
    PropertyContest = 'propertyContest',
    Pros = 'pros',
    Reviews = 'reviews',
    Savings = 'savings',
    Societies = 'societies',
    Subscriptions = 'subscriptions',
    Tenants = 'tenants',
    TransactionCategories = 'transactionCategories',
    Trials = 'trials',
    TutorialVideos = 'tutorialVideos',
    // UserPreferences = 'userPreferences', `// Not use anymore, to delete
    Users = 'users',
    // Uses = 'uses' // too big, manipulate with caution
}

// deleteBridgeUser Delete all bankItems/accounts/transactions when user deleted
