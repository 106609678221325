import { Component, ViewChild, ElementRef, AfterViewInit, Input } from '@angular/core';
import { Animation, AnimationController } from '@ionic/angular';
import { Renderer2 } from '@angular/core';

@Component({
    selector: 'omedom-dropdown',
    templateUrl: './dropdown.component.html',
    styleUrls: ['./dropdown.component.scss'],
    host: {
        '(document:click)': 'onClick($event)',
    },
})
export class DropdownComponent implements AfterViewInit {
    /**
     * @description Icon class of the dropdown button
     * @author Brisset Killian
     * @date 25/04/2024
     * @type {string}
     * @memberof DropdownComponent
     */
    @Input() iconClass?: string;

    /**
     * @description Background color of the dropdown button
     * @author Brisset Killian
     * @date 25/04/2024
     * @memberof DropdownComponent
     */
    @Input() isButtonBackgroundTransparent = false;

    /**
     * @description Label of the dropdown button
     * @author Brisset Killian
     * @date 25/04/2024
     * @type {string}
     * @memberof DropdownComponent
     */
    @Input() label?: string;

    /**
     * @description display dropdown for only buttons
     * @author ANDRE Felix
     * @memberof DropdownComponent
     */
    @Input() onlyButtons = false;

    /**
     * @description Does rotation with transition the icon
     * @author ANDRE Felix
     * @type {false}
     * @memberof DropdownComponent
     */
    @Input() rotationAnimate: boolean = false;

    /**
     * @description Element de la liste du dropdown
     * @author ANDRE Felix
     * @type {ElementRef}
     * @memberof DropdownComponent
     */
    @ViewChild('omedomDropdown', { static: false }) dropdownListEl?: ElementRef;
    /**
     * @description Element du bouton du dropdown
     * @author ANDRE Felix
     * @type {ElementRef}
     * @memberof DropdownComponent
     */
    @ViewChild('dropdownIcon', { static: false }) dropdownButtonEl?: ElementRef;

    /**
     * @description Element du bouton du dropdown
     * @author ANDRE Felix
     * @type {ElementRef}
     * @memberof DropdownComponent
     */
    @ViewChild('dropdownButton', { static: false }) dropdownIconEl?: ElementRef;

    /**
     * @description Affiche ou non le contenu de la dropdown
     * @author ANDRE Felix
     * @private
     * @memberof DropdownComponent
     */
    protected isDropdownVisible: boolean = false;

    /**
     * @description anime la disparition du contenu du dropdown
     * @author ANDRE Felix
     * @private
     * @type {Animation}
     * @memberof DropdownComponent
     */
    private fadeOut?: Animation;

    /**
     * @description  anime l'apparition du contenu du dropdown
     * @author ANDRE Felix
     * @private
     * @type {Animation}
     * @memberof DropdownComponent
     */
    private fadeIn?: Animation;

    /**
     * @description  anime une rotation sur l'icone du dropdown
     * @author ANDRE Felix
     * @private
     * @type {Animation}
     * @memberof DropdownComponent
     */
    private rotate?: Animation;

    /**
     * @description Minimum space between dropdown and bottom of the screen to display the dropdown upward
     * @author Brisset Killian
     * @date 26/04/2024
     * @private
     * @memberof DropdownComponent
     */
    private MIN_SPACE_BETWEEN_DROPDOWN_AND_BOTTOM = 200;

    constructor(
        private animationCtrl: AnimationController,
        private hostEl: ElementRef,
        private renderer: Renderer2
    ) {}

    ngAfterViewInit(): void {
        if (this.dropdownListEl) {
            this.fadeOut = this.animationCtrl
                .create()
                .addElement(this.dropdownListEl.nativeElement)
                .duration(200)
                .fromTo('opacity', '1', '0.2');

            this.fadeIn = this.animationCtrl
                .create()
                .addElement(this.dropdownListEl.nativeElement)
                .duration(200)
                .fromTo('opacity', '0.2', '1');
        }

        if (this.dropdownButtonEl) {
            this.rotate = this.animationCtrl
                .create()
                .addElement(this.dropdownButtonEl.nativeElement)
                .duration(200)
                .fromTo('transform', 'rotate(0)', 'rotate(180deg)');
        }
    }

    /**
     * @description Change the visibility of the dropdown content
     * @author ANDRE Felix
     * @returns {*}  {Promise<void>}
     * @memberof DropdownComponent
     */
    async toggleVisibility(): Promise<void> {
        if (this.dropdownListEl) {
            this.renderer.addClass(this.dropdownListEl.nativeElement, this.dropdownDirection());
            if (this.isDropdownVisible) {
                this.toInvisibleAnimation();
            } else {
                this.toVisibleAnimation();
            }
            this.renderer.removeClass(this.dropdownListEl.nativeElement, 'dropdown-display-upward');
            this.renderer.removeClass(
                this.dropdownListEl.nativeElement,
                'dropdown-display-downward'
            );
        }
    }

    /**
     * @description Anime the dropdown content to be visible
     * @author Brisset Killian
     * @date 25/04/2024
     * @memberof DropdownComponent
     */
    private async toVisibleAnimation() {
        if (this.rotationAnimate && this.rotate) {
            await this.rotate.direction('normal').play();
        }
        this.isDropdownVisible = true;
        if (this.fadeIn) {
            await this.fadeIn.play();
            await this.fadeIn.stop();
        }
    }

    /**
     * @description Anime the dropdown content to be invisible
     * @author Brisset Killian
     * @date 25/04/2024
     * @private
     * @memberof DropdownComponent
     */
    private async toInvisibleAnimation() {
        if (this.rotationAnimate && this.rotate) {
            await this.rotate.direction('reverse').play();
        }
        if (this.fadeOut) {
            await this.fadeOut.play();
            this.isDropdownVisible = false;
            await this.fadeOut.stop();
        }
    }

    /**
     * @description determine la direction de l'affichage de la dropdown
     * @author ANDRE Felix
     * @returns {*}  {string}
     * @memberof DropdownComponent
     */
    dropdownDirection(): string {
        const buttomBottomPosition =
            window.innerHeight -
                this.dropdownButtonEl?.nativeElement.getBoundingClientRect().bottom;
        const isBottomLimitReached =
            this.MIN_SPACE_BETWEEN_DROPDOWN_AND_BOTTOM > buttomBottomPosition;
        return isBottomLimitReached ? 'dropdown-display-upward' : 'dropdown-display-downward';
    }

    /**
     * @description ferme la dropdown si click en dehors de la dropdown
     * @author ANDRE Felix
     * @param {any} event
     * @memberOf DropdownComponent
     */
    onClick(event: any) {
        if (!this.hostEl.nativeElement.contains(event.target)) {
            this.isDropdownVisible = false;
        }
    }
}
