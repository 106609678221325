import { Component, Input, OnDestroy, OnInit, ViewChild } from '@angular/core';
import {
    AbstractControl,
    UntypedFormBuilder,
    UntypedFormGroup,
    ValidationErrors,
    ValidatorFn,
    Validators,
} from '@angular/forms';
import { ToastController } from '@ionic/angular';
import {
    AssetType,
    AssetTypes,
    OmedomStep,
    SelectOption,
    Sharing,
    SocietyRoleMember,
    UserEntity,
} from '@omedom/data';
import { PropertyService, SocietyService, UserService } from '@omedom/services';
import { OmedomRegex } from '@omedom/utils';
import { BehaviorSubject, combineLatest, map, Observable, of, Subscription, switchMap } from 'rxjs';

import { StepperComponent } from '../../../components/stepper/stepper.component';

export enum ShareMultiFormStepEnum {
    userForm = 'userForm',
    assetSelection = 'assetSelection',
}

interface AssetItems {
    asset: AssetType;
    type: AssetTypes;
    selected: boolean;
    role?: SocietyRoleMember;
}

@Component({
    selector: 'omedom-share-multi-form',
    templateUrl: './share-multi-form.container.html',
    styleUrls: ['./share-multi-form.container.scss'],
})
export class ShareMultiFormContainer implements OnInit, OnDestroy {
    /**
     * @description Observable of assets to share
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 20/12/2024
     * @type {Observable<AssetType[]>}
     * @memberof ShareMultiFormContainer
     */
    @Input({ required: true }) assets$: Observable<AssetType[]> = of([]);

    /**
     * @description List of assets to share
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 20/12/2024
     * @private
     * @type {AssetType[]}
     * @memberof ShareMultiFormContainer
     */
    private assets: AssetType[] = [];

    /**
     * @description List of asset items to share with selected state and role for each asset item
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 20/12/2024
     * @type {AssetItems[]}
     * @memberof ShareMultiFormContainer
     */
    public assetItems: AssetItems[] = [];

    /**
     * @description State of the form (ok, pending, error) to display the form or a loader or an error message to the user
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 20/12/2024
     * @memberof ShareMultiFormContainer
     */
    public _state = new BehaviorSubject<'ok' | 'pending' | 'error'>('pending');

    /**
     * @description Pending state of the form to disable the submit button when the form is pending
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 20/12/2024
     * @memberof ShareMultiFormContainer
     */
    public _pending = new BehaviorSubject<boolean>(false);

    /**
     * @description List of asset types to display in the form
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 20/12/2024
     * @memberof ShareMultiFormContainer
     */
    public assetTypes = AssetTypes;

    /**
     * @description List of steps for the form
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 20/12/2024
     * @memberof ShareMultiFormContainer
     */
    public allSteps = ShareMultiFormStepEnum;

    /**
     * @description List of steps for the form
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 20/12/2024
     * @type {OmedomStep[]}
     * @memberof ShareMultiFormContainer
     */
    public steps: OmedomStep[] = [
        {
            id: ShareMultiFormStepEnum.userForm,
            label: 'Formulaire de partage',
            canGoNext: () => {
                return !!this.shareForm?.valid;
            },
        },
        {
            id: ShareMultiFormStepEnum.assetSelection,
            label: 'Sélection des biens',
            canGoNext: () => {
                // disable if pending is true
                return !this._pending.value;
            },
        },
    ];

    /**
     * @description Selected step of the form to display the selected step in the form
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 20/12/2024
     * @memberof ShareMultiFormContainer
     */
    public selectedStep = this.steps[0];

    /**
     * @description Stepper component to display the steps of the form and the selected step in the form
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 03/01/2025
     * @type {StepperComponent}
     * @memberof ShareMultiFormContainer
     */
    @ViewChild('stepper') stepper?: StepperComponent;

    /**
     * @description Regex for email validation in the form to check if the email is valid or not
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 20/12/2024
     * @type {string}
     * @memberof ShareMultiFormContainer
     */
    public emailRegex: string = OmedomRegex.emailRegex;

    /**
     * @description Form to share with email, name and firstname of the user to share with and role for each asset item to share with the user
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 20/12/2024
     * @type {UntypedFormGroup}
     * @memberof ShareMultiFormContainer
     */
    public shareForm?: UntypedFormGroup;

    /**
     * @description Placeholder for the role select option in the form to display a placeholder in the select option when the role is not selected yet
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 20/12/2024
     * @memberof ShareMultiFormContainer
     */
    public rolePlaceholder = {
        id: null,
        label: 'Rôle',
    } as SelectOption;

    /**
     * @description List of role options for the property to share with the user
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 20/12/2024
     * @memberof ShareMultiFormContainer
     */
    public roleOptions = [
        {
            id: SocietyRoleMember.reader,
            label: SocietyRoleMember.reader,
            icon: 'uil uil-eye',
        } as SelectOption,
        {
            id: SocietyRoleMember.editor,
            label: SocietyRoleMember.editor,
            icon: 'uil uil-edit',
        } as SelectOption,
    ];

    /**
     * @description Enum of roles for the society to share with the user
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 20/12/2024
     * @memberof ShareMultiFormContainer
     */
    public roleEnum = SocietyRoleMember;

    /**
     * @description List of subscriptions to unsubscribe when the component is destroyed to avoid memory leaks
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 20/12/2024
     * @private
     * @type {Subscription[]}
     * @memberof ShareMultiFormContainer
     */
    private subscriptions: Subscription[] = [];

    /**
     * @description Current user to share with the user in the form to avoid sharing with the owner of the asset or society
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 20/12/2024
     * @private
     * @type {UserEntity}
     * @memberof ShareMultiFormContainer
     */
    private currentUser?: UserEntity;

    constructor(
        private formBuilder: UntypedFormBuilder,
        private toastController: ToastController,
        private propertyService: PropertyService,
        private societyService: SocietyService,
        private userService: UserService,
    ) {}

    ngOnInit(): void {
        const userSub = this.userService.user$
            .pipe(
                switchMap((user) => {
                    this.currentUser = user;
                    if (!user || !user.uid) {
                        return of([]);
                    }

                    // Get the all the assets of the user
                    const properties$ = this.propertyService
                        ._getUserProperties(user.uid)
                        .pipe(
                            map((properties) =>
                                properties.filter((property) =>
                                    this.propertyService.isPropertyAccessible(property),
                                ),
                            ),
                        );
                    const societies = this.societyService._getUserSocieties(user.uid);

                    return combineLatest([properties$, societies]).pipe(
                        map(([properties, societies]) => {
                            return [...properties, ...societies];
                        }),
                    );
                }),
            )
            .subscribe((assets) => {
                if (!assets) {
                    this._state.next('pending');
                    return;
                }
                this.assets = assets;
                this.initAssetItems(assets);
                this._state.next('ok');
            });

        this.initForm();

        this.subscriptions.push(userSub);
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((sub) => sub.unsubscribe());
    }

    /**
     * @description Change the selected step of the form to display the selected step in the form
     * when the user click on a step in the form
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 20/12/2024
     * @param {OmedomStep} step
     * @memberof ShareMultiFormContainer
     */
    public onSelectedStepChange(step: OmedomStep): void {
        if (step.id === ShareMultiFormStepEnum.assetSelection) {
            this.initAssetItems(this.assets);
        }
    }

    /**
     * @description Change the selected state of the asset item when the user click on the asset item in the form
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 20/12/2024
     * @param {AssetItems} assetItem
     * @param {boolean} selected
     * @memberof ShareMultiFormContainer
     */
    public onSelectedChange(assetItem: AssetItems, selected: boolean): void {
        // New with assetItems
        const email = this.shareForm?.get('email')?.value;

        if (selected && !assetItem.role) {
            assetItem.role =
                assetItem.role ??
                assetItem.asset.sharing?.find((sharing) => sharing.email === email)?.role ??
                SocietyRoleMember.reader;
        }
        assetItem.selected = selected;
    }

    /**
     * @description Init the asset items with the assets to share and the sharing state of each asset item
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 20/12/2024
     * @private
     * @param {AssetType[]} assets
     * @memberof ShareMultiFormContainer
     */
    private initAssetItems(assets: AssetType[]): void {
        this.assetItems = assets
            .map((asset) => {
                const email = this.shareForm?.get('email')?.value;
                const sharing = asset.sharing?.find((sharing) => sharing.email === email);
                return {
                    asset,
                    type: asset.toSelectOption!().assetType,
                    selected: !!sharing,
                    role: sharing?.role ?? SocietyRoleMember.reader,
                };
            })
            .filter((asset) => asset.type) as AssetItems[];
    }

    /**
     * @description Init the form with the form builder to create the form with the email, name and firstname of the user to share with
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 20/12/2024
     * @private
     * @memberof ShareMultiFormContainer
     */
    private initForm(): void {
        this.shareForm = this.formBuilder.group({
            firstname: ['', [Validators.required]],
            name: ['', [Validators.required]],
            email: ['', [Validators.email, Validators.required, this.mailValidator()]],
        });
    }

    /**
     * @description Check if the email is the owner email to avoid sharing with the owner of the asset or society
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 20/12/2024
     * @private
     * @param {string} shareEmail
     * @returns {*}  {(ValidationErrors | null)}
     * @memberof ShareMultiFormContainer
     */
    private checkExistingSharing(shareEmail: string): ValidationErrors | null {
        if (shareEmail === this.currentUser?.email) {
            this.displayErrorToast('Vous ne pouvez pas vous partagez un bien');
            return { shareToOwner: true };
        }
        return null;
    }

    /**
     * @description Display an error toast with the message when an error occurs in the form to display an error message to the user
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 20/12/2024
     * @private
     * @param {string} message
     * @memberof ShareMultiFormContainer
     */
    private async displayErrorToast(message: string) {
        const toast = await this.toastController.create({
            position: 'top',
            color: 'danger',
            duration: 5000,
            message: message,
        });

        await toast.present();
    }

    /**
     * @description Display a success toast with the message when the form is submitted successfully to display a success message to the user
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 02/01/2025
     * @private
     * @param {string} message
     * @memberof ShareMultiFormContainer
     */
    private async displaySuccessToast(message: string) {
        const toast = await this.toastController.create({
            position: 'top',
            color: 'success',
            duration: 5000,
            message: message,
        });

        await toast.present();
    }

    /**
     * @description Submit the form to share the assets with the user with the email, name and firstname of the user to share with
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 20/12/2024
     * @returns {*}  {Promise<void>}
     * @memberof ShareMultiFormContainer
     */
    public async submit(): Promise<void> {
        if (!this.shareForm?.valid) {
            await this.displayErrorToast('Veuillez remplir correctement le formulaire');
            return;
        }

        this._pending.next(true);
        const email = this.shareForm?.get('email')?.value;
        const name = this.shareForm?.get('name')?.value;
        const firstname = this.shareForm?.get('firstname')?.value;

        try {
            // Share the assets with the user
            await Promise.all(
                this.assetItems.map((assetItem) =>
                    this.shareAsset(email, name, firstname, assetItem),
                ),
            );
            this.initForm();
            this.stepper?.onPrevious();

            this._pending.next(false);

            await this.displaySuccessToast('Les biens ont été partagés avec succès');
        } catch (error) {
            this._pending.next(false);
            await this.displayErrorToast('Une erreur est survenue');
        }
    }

    /**
     * @description Share the asset with the user with the email, name and firstname of the user to share with
     * and the role for the asset item to share with the user
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 20/12/2024
     * @private
     * @param {string} email
     * @param {string} name
     * @param {string} firstname
     * @param {AssetItems} assetItem
     * @returns {*}  {Promise<void>}
     * @memberof ShareMultiFormContainer
     */
    private async shareAsset(
        email: string,
        name: string,
        firstname: string,
        assetItem: AssetItems,
    ): Promise<void> {
        // Construct the sharing object
        const sharing: Sharing = {
            email,
            name,
            firstname,
            role: assetItem.role,
        };

        // Define the property types
        const propertyTypes = [AssetTypes.property, AssetTypes.building];

        // Get the sharings of the asset
        const sharings = assetItem.asset.sharing ?? [];

        // Find the sharing index in the sharings list
        const sharingIndex = sharings.findIndex((s) => s.email === email);

        if (assetItem.selected) {
            // If the sharing already exists, update the sharing
            if (sharingIndex !== -1) {
                sharings[sharingIndex] = sharing;
            } else {
                // Else, add the sharing to the sharings list
                sharings.push(sharing);
            }

            // Update the asset with the new sharings list
            if (propertyTypes.includes(assetItem.type)) {
                // Update the property with the new sharings list
                await this.propertyService.update({
                    sharing: sharings,
                    uid: assetItem.asset.uid,
                });
            } else {
                // Update the society with the new sharings list
                await this.societyService.update({
                    sharing: sharings,
                    uid: assetItem.asset.uid,
                });
            }
        } else {
            // If the asset is not selected

            // If the sharing exists, remove the sharing from the sharings list
            if (sharingIndex !== -1) {
                sharings.splice(sharingIndex, 1);
            }

            // Update the asset with the new sharings list
            if (propertyTypes.includes(assetItem.type)) {
                // Update the property with the new sharings list
                await this.propertyService.update({
                    sharing: sharings,
                    uid: assetItem.asset.uid,
                });
            } else {
                // Update the society with the new sharings list
                await this.societyService.update({
                    sharing: sharings,
                    uid: assetItem.asset.uid,
                });
            }
        }
    }

    /**
     * @description Validator for the email field in the form to check if the email is valid or not
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 20/12/2024
     * @private
     * @returns {*}  {ValidatorFn}
     * @memberof ShareMultiFormContainer
     */
    private mailValidator(): ValidatorFn {
        return (control: AbstractControl): ValidationErrors | null => {
            const email = control.value;
            if (!email) {
                return null;
            }
            const result = this.checkExistingSharing(email);
            return result;
        };
    }

    /**
     * @description Get index of role in role options
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 26/12/2024
     * @param {(SocietyRoleMember | undefined)} role
     * @returns {*}  {number}
     * @memberof ShareMultiFormContainer
     */
    public getRoleIndex(role: SocietyRoleMember | undefined): number {
        if (!role) {
            return -1;
        }
        return this.roleOptions.findIndex((option) => option.id === role);
    }

    /**
     * @description Change role of assetItem
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 26/12/2024
     * @param {AssetItems} assetItem
     * @param {('next' | 'previous')} option
     * @returns {*}  {void}
     * @memberof ShareMultiFormContainer
     */
    public changeRole(assetItem: AssetItems, option: 'next' | 'previous'): void {
        if (!assetItem.role) {
            assetItem.role = SocietyRoleMember.reader;
        }

        const roleIndex = this.getRoleIndex(assetItem.role);

        if (roleIndex === -1) {
            return;
        }

        if (option === 'next') {
            assetItem.role = this.roleOptions[roleIndex + 1]?.id ?? assetItem.role;
        } else if (option === 'previous') {
            assetItem.role = this.roleOptions[roleIndex - 1]?.id ?? assetItem.role;
        }
    }
}
