<div
    class="subscription-current-container"
    [style.background-color]="currentConfiguration?.color"
    [style.color]="currentConfiguration?.fontColor"
>
    <div class="card-header margin-bottom-1">
        <h3>
            @if (
                currentConfiguration?.docName !== SubscriptionRank.ESSENTIAL &&
                currentConfiguration?.docName !== SubscriptionRank.FAMILY &&
                currentConfiguration?.docName !== SubscriptionRank.NOEL2024
            ) {
                <i class="omedom-icon-crown margin-right-1"></i>
            }
            @if (currentConfiguration?.docName === SubscriptionRank.ESSENTIAL) {
                <i class="uil uil-star margin-right-1"></i>
            }
            @if (currentConfiguration?.docName === SubscriptionRank.FAMILY) {
                <i class="uil uil-users-alt margin-right-1"></i>
            }
            @if (currentConfiguration?.docName === SubscriptionRank.NOEL2024) {
                <i class="uil uil-snowflake margin-right-1"></i>
            }
            @if (configurations && subscription?.futureRank) {
                {{ futureConfigurationName! | capitalizeFirstLetter }}
            }
            @if (!configurations && subscription?.rank) {
                {{
                    currentConfiguration?.name ?? currentConfiguration?.docName!
                        | capitalizeFirstLetter
                }}
            }
        </h3>
        @if (
            subscription &&
            subscription.rank! !== SubscriptionRank.ESSENTIAL &&
            !subscription.fromFamily
        ) {
            @if (
                subscription.rank !== SubscriptionRank.BLACKFRIDAY2024 &&
                subscription.rank !== SubscriptionRank.NOEL2024 &&
                (!subscription?.futureRank || configurations)
            ) {
                <span class="price"
                    >{{
                        currentConfiguration?.price?.[
                            subscription.subscriptionType
                        ] | payplugNumberToPrice
                    }}€ TTC /
                    {{
                        SubscriptionType.MONTHLY ===
                        subscription.subscriptionType
                            ? 'mois'
                            : 'an'
                    }}</span
                >
            } @else if (
                (subscription.rank === SubscriptionRank.BLACKFRIDAY2024 ||
                    subscription.rank === SubscriptionRank.NOEL2024) &&
                (!subscription?.futureRank || configurations)
            ) {
                <span class="price"
                    >{{
                        currentConfiguration?.price?.[
                            subscription.subscriptionType
                        ] | payplugNumberToPrice
                    }}€ TTC
                    {{
                        SubscriptionType.MONTHLY ===
                        subscription.subscriptionType
                            ? 'pour 1 mois'
                            : 'pour 12 mois'
                    }}</span
                >
            }
        }
    </div>

    @if (
        subscription?.renewDate &&
        !subscription?.fromFamily &&
        subscription?.isSubscribed
    ) {
        <span class="price-renew margin-bottom-1">
            {{
                subscription?.canceled ||
                subscription?.trial ||
                subscription?.proUID ||
                (!configurations && subscription?.futureRank)
                    ? 'Fin de votre offre'
                    : 'Renouvellement automatique'
            }}
            le
            {{ subscription?.renewDate! | date: 'dd/MM/yyyy' }}
        </span>
    }
    @if (!currentConfiguration?.isActive && !subscription?.canceled) {
        <p class="color-red">
            Attention votre abonnement
            {{
                (currentConfiguration?.name ?? currentConfiguration?.docName)!
                    | capitalizeFirstLetter
            }}
            n'est plus disponible, si vous l'annulez vous ne pourrez plus le
            reprendre.
        </p>
    }
    @if (subscription?.fromFamily) {
        <span class="price-renew margin-bottom-1">
            Abonnement FAMILLE familial fournit par
            {{ subscription?.fromFamily?.firstName }}
            {{ subscription?.fromFamily?.name }}
        </span>
    }
    @if (!configurations) {
        <div>
            <hr class="omedom-divider" />
            @if (familyInvitations && familyInvitations.length > 0) {
                <button
                    class="omedom-button-primary w-100 margin-top-2"
                    (click)="manageFamilyInvitations.emit()"
                >
                    <i class="uil uil-bell shake-animation"></i> Vous avez une
                    invitation a rejoindre une famille
                </button>
            }
            @if (!subscription?.fromFamily) {
                <button
                    class="omedom-button-primary w-100 margin-top-2"
                    (click)="manageSubscription.emit()"
                >
                    Gérer mon abonnement
                </button>
            }
            @if (subscription?.rank! === SubscriptionRank.FAMILY) {
                <button
                    class="omedom-button-primary w-100 margin-top-2"
                    (click)="manageFamily.emit()"
                >
                    Gérer ma famille
                </button>
            }
            @if (subscription?.fromFamily) {
                <button
                    class="omedom-button-secondary w-100 margin-top-2"
                    (click)="leaveFamily.emit()"
                >
                    Quitter l'abonnement Famille
                </button>
            }
            <button
                class="omedom-button-secondary w-100 margin-top-2"
                (click)="paymentHistory.emit()"
            >
                <i class="uil uil-receipt"></i>
                Historique des paiements et factures
            </button>
        </div>
    }
</div>
