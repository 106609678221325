import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { TutorialVideoEntity, TutorialVideoName } from '@omedom/data';

import { RestService } from './rest.service';
import { SanitizerService } from '../services';
import { filter, map } from 'rxjs';
import { OmedomSanitiser } from '@omedom/utils';

@Injectable({ providedIn: 'root' })
export class TutorialVideoService extends RestService<TutorialVideoEntity> {
    constructor(
        protected override firestore: AngularFirestore,
        private sanitizerService: SanitizerService,
    ) {
        super(firestore, 'tutorialVideos');
    }

    /**
     * @description Get all video for desktop
     * @author ANDRE Felix
     * @returns {*}
     * @memberof TutorialVideoService
     */
    public _getDesktopVideo() {
        return this._list().pipe(
            map((allvideos) => {
                const desktopVideo = allvideos.filter((video) => video.isForDesktop);
                const desktopVideoSanitize = desktopVideo.map((video) => {
                    const videoSanitize = this.sanitizeYoutubeVideo(video);
                    return videoSanitize;
                });
                return desktopVideoSanitize;
            }),
        );
    }

    /**
     * @description Get all video for app
     * @author ANDRE Felix
     * @returns {*}
     * @memberof TutorialVideoService
     */
    public _getAppVideo() {
        return this._list().pipe(
            map((allvideos) => {
                const desktopVideos = allvideos.filter((video) => video.isForApp);
                return desktopVideos;
            }),
        );
    }

    /**
     * @description Get the link of the video to go to the website (ex: youtube)
     * @author ANDRE Felix
     * @param {string} videoName
     * @returns {*}
     * @memberof TutorialVideoService
     */
    public async getVideoLinkByName(videoName: string) {
        const isValidYoutubeURL = OmedomSanitiser.validateYoutubeURL(videoName);

        if (!isValidYoutubeURL) {
            return undefined;
        }
        const video = await this.get(videoName);

        if (!video) {
            return undefined;
        }
        return video.iframeSource.toString();
    }

    /**
     * @description get youtube video by name
     * @author ANDRE Felix
     * @param {string} videoName
     * @returns {Promise<TutorialVideoEntity | undefined>}
     * @memberof TutorialVideoService
     */
    async getYoutubeVideoByName(videoName: TutorialVideoName) {
        const video = await this.get(videoName);

        if (!video) {
            return undefined;
        }

        const videoSanitize = this.sanitizeYoutubeVideo(video);
        return videoSanitize;
    }

    private sanitizeYoutubeVideo(video: TutorialVideoEntity) {
        const url = video.iframeSource as string;
        const urlSanitized = this.sanitizerService.sanitizeYoutubeURL(url);
        const videoSanitized: TutorialVideoEntity = { ...video, iframeSource: urlSanitized };
        return videoSanitized;
    }
}
