import { Injectable } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { OmedomSanitiser } from '@omedom/utils';

@Injectable({
    providedIn: 'root',
})
export class SanitizerService {
    constructor(private sanitizer: DomSanitizer) {}

    public sanitizeFireStoreURL(url: string) {
        const trustedURLFromFirestore = OmedomSanitiser.validateFirestoreURL(url)
            ? this.sanitizer.bypassSecurityTrustResourceUrl(url)
            : '';
        return trustedURLFromFirestore;
    }

    public sanitizeYoutubeURL(url: string) {
        const trustedURLFromYoutube = OmedomSanitiser.validateYoutubeURL(url)
            ? this.sanitizer.bypassSecurityTrustResourceUrl(url)
            : '';
        return trustedURLFromYoutube;
    }
}
