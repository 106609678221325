import { Component, Input } from '@angular/core';
import { ChatMessageEntity, UserEntity } from '@omedom/data';

export interface ChatMessage {
    chatMessageEntity: ChatMessageEntity;
    userEntity?: UserEntity | Partial<UserEntity>;
}

@Component({
    selector: 'omedom-chat-message-card',
    templateUrl: './chat-message-card.component.html',
    styleUrls: ['./chat-message-card.component.scss'],
})
export class ChatMessageCardComponent {
    @Input({ required: true }) public message!: ChatMessage;

    @Input() public isCurrentUser: boolean = false;

    constructor() {}

    protected timestampToDate(timestamp: any): Date | undefined {
        if (timestamp && timestamp.toDate && typeof timestamp.toDate === 'function') {
            return timestamp.toDate();
        } else if (timestamp && typeof timestamp === 'object') {
            return new Date(timestamp);
        }

        return undefined;
    }
}
