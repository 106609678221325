import {
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { ProEntity, SocietyEntity } from '@omedom/data';

@Component({
    selector: 'omedom-society-card-selectable',
    templateUrl: './society-card-selectable.component.html',
    styleUrls: ['./society-card-selectable.component.scss'],
})
export class SocietyCardSelectableComponent implements OnInit, OnChanges {
    /**
     * @description Society Data
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 14/08/2024
     * @type {SocietyEntity}
     * @memberof SocietyCardSelectableComponent
     */
    @Input()
    public society?: SocietyEntity;

    /**
     * @description True if the society is selected (default: false) (used to display a check icon on the card when the society is selected in a list of societies to select for example)
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 14/08/2024
     * @type {boolean}
     * @memberof SocietyCardSelectableComponent
     */
    @Input()
    public selected: boolean = false;

    /**
     * @description Emit the selected state of the society (used to display a check icon on the card when the society is selected in a list of societies to select for example)
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 14/08/2024
     * @memberof SocietyCardSelectableComponent
     */
    @Output()
    public selectedChange = new EventEmitter<boolean>();

    /**
     * @description Pro Data (used to display a check icon on the card when the society is shared to pro for example) (default: undefined) (if undefined, the check icon is not displayed on the card even if the society is shared to pro)
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 14/08/2024
     * @type {ProEntity}
     * @memberof SocietyCardSelectableComponent
     */
    @Input()
    public pro?: ProEntity;

    /**
     * @description If the user use a pro licence to accept share conditions with pro (default: false)
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 14/08/2024
     * @type {boolean}
     * @memberof SocietyCardSelectableComponent
     */
    @Input()
    public shareWithProConditions?: boolean;

    constructor(private readonly elementRef: ElementRef<HTMLElement>) {}

    ngOnInit(): void {
        // Check if the property is already shared to pro
        // if (this.society?.sharedToPro) {
        //     this.onClick();
        // }

        if (this.selected) {
            this.elementRef.nativeElement.classList.add('selected');
        } else {
            this.elementRef.nativeElement.classList.remove('selected');
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['selected']?.currentValue) {
            this.elementRef.nativeElement.classList.add('selected');
        } else {
            this.elementRef.nativeElement.classList.remove('selected');
        }

        if (changes['society'] && this.selected && !changes['selected']) {
            this.elementRef.nativeElement.classList.add('selected');
        }

        if (changes['society'] && !this.selected && !changes['selected']) {
            this.elementRef.nativeElement.classList.remove('selected');
        }
    }

    /**
     * @description Toggle the selected state of the society
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 14/08/2024
     * @memberof SocietyCardSelectableComponent
     */
    @HostListener('click')
    public onClick(): void {
        // Toggle the selected state
        // this.selected = !this.selected;

        // Emit the selected state
        this.selectedChange.emit(!this.selected);

        // Toggle class selected
        // this.elementRef.nativeElement.classList.toggle('selected');
    }
}
