// PROD
export enum SendinblueListIdProd {
    newsletterSite = 7, // Newsletter Site
    app = 16, // Liste globale utilisateurs
    newsletterApp = 17, // Newsletter App
    deleted = 19, // Comptes supprimés
    validatedUsers = 26, // Comptes finalisés
    unvalidatedUsers = 30, // Comptes non finalisés
    metaAndroidJunto = 43, // Contacts CAMPAGNE ANDROID META JUNTO
    landingPageContacts = 45, // Contacts LANDING PAGE - Formulaire - JUNTO
    linkedInAndroidJunto = 52, // Contacts CAMPAGNE ANDROID LINKEDIN DOCUMENT ADS
    proCodeAccount = 59, // Comptes bénéficiaires licence appli offert par pro
    landingPageContactsPro = 67, // LP B2B - lead pro
    landingPageContactsCES = 90, // CES LP - Contacts formulaire
    toRemove = 95, // À supprimer
    smartUsers = 100, // SMART
    essentailUsers = 101, // ESSENTIAL
    trialUsers = 102, // DECOUVERTE
    premiumUsers = 103, // PREMIUM
    familyUsers = 104, // FAMILY
    monthlyUsers = 105, // Mensuel
    annuallyUsers = 106, // Annuel
    applePay = 107, // Apple Pay
    payplug = 108, // Payplug
    blackfriday2024Users = 121, // BLACKFRIDAY2024
    noel2024 = 125, // NOEL2024
};

// DEV
export enum SendinblueListIdDev {
    app = 32, // test dev - Liste globale utilisateurs
    newsletterApp = 33, // test dev - Newsletter App
    unvalidatedUsers = 34, // test dev - Comptes non
    validatedUsers = 35, // test dev - Comptes finalisés
    deleted = 36, // test dev - Comptes supprimés
    newsletterSite = 40, // test dev - Newsletter Site
    metaAndroidJunto = 44, // test dev - Contacts CAMPAGNE ANDROID META JUNTO
    landingPageContacts = 51, // test dev - Contacts LANDING PAGE - Formulaire - JUNTO
    linkedInAndroidJunto = 53, // test dev - Contacts CAMPAGNE ANDROID LINKEDIN DOCUMENT ADS
    proCodeAccount = 60, // test dev - Comptes bénéficiaires licence appli offert par pro
    landingPageContactsPro = 66, // test dev - LP B2B - lead pro
    landingPageContactsCES = 91, // test dev CES LP - Contacts formulaire
    toRemove = 96, // test dev - À supprimer
    smartUsers = 109, // SMART
    essentailUsers = 110, // ESSENTIAL
    trialUsers = 111, // DECOUVERTE
    premiumUsers = 112, // PREMIUM
    familyUsers = 113, // FAMILY
    monthlyUsers = 114, // Mensuel
    annuallyUsers = 115, // Annuel
    applePay = 116, // Apple Pay
    payplug = 117, // Payplug
    blackfriday2024Users = 122, // BLACKFRIDAY2024
    noel2024 = 127, // NOEL2024
};

