import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { AssetTypes, SelectOption, startDateTarification } from '@omedom/data';
import { BehaviorSubject } from 'rxjs';

import { SelectComponent } from '../../select/select.component';
import { IonPopover } from '@ionic/angular';
@Component({
    selector: 'omedom-asset-filter-generic',
    templateUrl: './asset-filter-generic.component.html',
    styleUrls: ['./asset-filter-generic.component.scss'],
})
export class AssetFilterGenericComponent implements OnInit, OnChanges {
    @Input() disabled = false;

    @Input() assetOptions$ = new BehaviorSubject<SelectOption[]>([]);

    @Input() allAssetsOptions!: SelectOption[];

    /**
     * @description Display filter icon if authorized by user subscription plan
     * @author Didier Pascarel <didier.pascarel@omedom.com>
     * @date 18/06/2024
     * @memberof AssetFilterGenericComponent
     */
    @Input() canFilter = false;

    @Output() assetSelectedChange = new EventEmitter();

    selectedAssets: SelectOption[] = [];

    /**
     * @description controls popover for properties
     * @author Hanane Djeddal
     * @memberof TreasuryBase
     */
    @ViewChild('popover') popover!: IonPopover;

    @ViewChild('smartPopover') smartPopover!: IonPopover;

    /**
     * @description the listing of properties for filtering
     * @author Hanane Djeddal
     * @type {SelectComponent}
     * @memberof AssetFilterGenericComponent
     */
    @ViewChild('selectApp') selectApp!: SelectComponent;

    isOpen = false;

    subjectPlaceholder = { label: 'Vos biens' } as SelectOption;

    /**
     * @description If the date is before the transition date (2024-03-01) or not
     * @author Brisset Killain
     * @type {boolean}
     * @memberof AssetFilterGenericComponent
     */
    private isBeforeTransitionDate = false;

    constructor() { }

    async ngOnInit() {
        const transitionDate = new Date(startDateTarification);
        const today = new Date();
        this.isBeforeTransitionDate = today.getTime() < transitionDate.getTime();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['allAssetsOptions'].currentValue) {
            this.allAssetsOptions = changes['allAssetsOptions'].currentValue;
        }
    }

    trackOptionsChanges(index: number, option: SelectOption) {
        return option.id;
    }
    /**
     * @description event when property is selected from filter
     * @param property
     */
    async propertySelected(assetOption: SelectOption) {
        this.popover?.dismiss();

        if (!this.isAssetSelectable(assetOption)) {
            return;
        }

        const index = this.selectedAssets.findIndex((element) => element.id === assetOption.id);
        if (index >= 0) {
            this.selectedAssets.splice(index, 1);

            this.assetOptions$.forEach((y) =>
                y.forEach((x) => {
                    if (x.id === assetOption.id) {
                        x.isSelected = false;
                    }
                })
            );
        } else {
            this.selectedAssets.push(assetOption);

            this.assetOptions$.forEach((y) =>
                y.forEach((x) => {
                    if (x.id === assetOption.id) {
                        x.isSelected = true;
                    }
                })
            );
        }
        this.assetSelectedChange.emit(this.selectedAssets);
    }
    // }

    private isAssetSelectable(propertyOption: SelectOption) {
        return (
            this.canFilter &&
            !this.disabled &&
            (propertyOption.isAccesible ||
                this.isBeforeTransitionDate ||
                propertyOption.assetType === AssetTypes.society ||
                propertyOption.assetType === AssetTypes.building)
        );
    }

    presentSmartPopover(e: Event) {
        if (!this.canFilter) {
            this.smartPopover.event = e;
            this.isOpen = true;
        }
    }
    public isUnlock(asset: SelectOption) {
        if (
            this.canFilter &&
            (asset.isAccesible ||
                this.isBeforeTransitionDate ||
                asset.assetType === AssetTypes.society ||
                asset.assetType === AssetTypes.building)
        ) {
            return true;
        }
        if (
            !this.canFilter &&
            !this.isBeforeTransitionDate &&
            asset.isAccesible &&
            !this.disabled
        ) {
            return true;
        }
        return false;
    }

    updateFilter() {
        // TODO mettre en stand by, fonctionne mal, il ne se déclenche que quand ferme modal avec la croix
        // PropertiesFilter.updateProperties(this.selectedAssets);
    }
    /**
     * @description allow to open the select-app via the filter icon
     */
    openSelect(): void {
        // TODO, filter not working, put in standby
        // this.selectApp.onClick();
    }
}
