/**
 * @description Enumération des attributs d'un contact Brevo
 *
 * This enum represents the different providers available for a Brevo contact.
 *
 * @author Didier PASCAREL <didier.pascarel@omedom.com>
 * @enum {string}
 * @property {string} google - Represents the Google button.
 * @property {string} apple - Represents the Apple button.
 * @property {string} default - Represents the default option (email and password).
 */
export enum BrevoProviderEnum {
    google = 'Bouton Google',
    apple = 'Bouton Apple',
    default = 'E-mail et mot de passe',
}
