import { Component, Input, OnInit } from '@angular/core';
import { ReviewEntity } from '@omedom/data';
import { Timestamp } from 'firebase-admin/firestore';

@Component({
    selector: 'omedom-review-display',
    templateUrl: './review-display.component.html',
    styleUrls: ['./review-display.component.scss'],
})
export class ReviewDisplayComponent implements OnInit {
    @Input({ required: true }) review!: ReviewEntity;

    constructor() {}

    ngOnInit() {}

    adaptDate(date: any) {
        return date.toDate().toLocaleDateString();
    }
}
