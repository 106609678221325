import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { Mode, UserEntity } from '@omedom/data';
import { AssetCalculationService } from '@omedom/services';
import { Subscription } from 'rxjs';

@Component({
    selector: 'omedom-toolbar',
    templateUrl: './toolbar.component.html',
    styleUrls: ['./toolbar.component.scss'],
})
export class ToolbarComponent implements OnChanges, OnInit, OnDestroy {
    /**
     * @description Title of the route (default: undefined) (optional) (used in toolbar components)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 26/09/2023
     * @type {string}
     * @memberof ToolbarComponent
     */
    @Input()
    public title?: string;

    /**
     * @description Icon of the route (default: undefined) (optional) (used in toolbar components)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 26/09/2023
     * @type {string}
     * @memberof ToolbarComponent
     */
    @Input()
    public icon?: string;

    /**
     * @description Font size of the title (default: '36px') (optional) (used in toolbar components)
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 04/09/2024
     * @type {string}
     * @memberof ToolbarComponent
     */
    @Input()
    public titleFontSize: number = 36;

    /**
     * @description True if the icon is an omedom icon, false otherwise (default: false) (optional) (used in toolbar components)
     * @author Killian Brisset
     * @date 09/08/2024
     * @type {boolean}
     * @memberof ToolbarComponent
     */
    @Input()
    public isOmedomIcon?: boolean;

    public get iconMask() {
        return `url("/assets/icon/${this.icon}.svg") no-repeat center`;
    }

    @Input()
    public mode: Mode = Mode.app;

    protected allMode = Mode;

    @Input()
    public user?: UserEntity;

    /**
     * @description Enable back button in toolbar (default: false) (optional) (used in toolbar components)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 26/09/2023
     * @type {boolean}
     * @memberof ToolbarComponent
     */
    @Input()
    public enableBack?: boolean = false;

    /**
     * @description Click event to emit when the profile picture is clicked
     * @author Brisset Killian
     * @date 10/06/2024
     * @memberof ToolbarComponent
     */
    @Output()
    public profileClick = new EventEmitter<void>();

    /**
     * @description Total calculated value of user assets, shared property aren't taken into account
     * @author ANDRE Felix
     * @type {number}
     * @memberof ToolbarComponent
     */
    totalAssetValue?: number;

    user$?: Subscription;

    globalAssetDetailMessage = [
        'Détail du calcul : solde global mes banques + valeur estimative patrimoine immobilier + épargnes - crédits ou emprunts',
        "Pensez à renseigner la valeur des biens pour qu'elle soit prise en compte dans le calcul",
        'Les biens qui vous sont partagés ne sont pas pris en compte dans ce calcul',
        "Si vous avez saisi ou demandé la valorisation d'un immeuble et d'un ou plusieurs lots dans cet immeuble, leurs valorisations seront additionnées dans le calcul de la valeur globale de votre patrimoine",
    ];

    constructor(private assetCalculationService: AssetCalculationService) {}

    ngOnInit(): void {
        this.assetCalculationService
            .getUserTotalAssets()
            .subscribe(
                ({
                    globalAssetsValue,
                    totalBankAccount,
                    totalUserManualLoans,
                    totalUserManualSavings,
                }) => {
                    this.totalAssetValue =
                        globalAssetsValue +
                        totalBankAccount -
                        totalUserManualLoans +
                        totalUserManualSavings;
                },
            );
    }

    ngOnDestroy(): void {
        this.user$?.unsubscribe();
    }

    ngOnChanges(changes: SimpleChanges): void {
        // Check if the title has changed
        if (changes['title']) {
            // Get the new title
            const title = changes['title'].currentValue;

            // Check if the title is valid
            if (typeof title !== 'string') {
                // Set the title to undefined
                this.title = undefined;
            }
        }

        // Check if the icon has changed
        if (changes['icon']) {
            // Get the new icon
            const icon = changes['icon'].currentValue;

            // Check if the icon is valid
            if (typeof icon !== 'string') {
                // Set the icon to undefined
                this.icon = undefined;
            }
        }

        // Check if the enableBack has changed
        if (changes['enableBack']) {
            // Get the new enableBack
            const enableBack = changes['enableBack'].currentValue;

            // Check if the enableBack is valid
            if (typeof enableBack !== 'boolean') {
                // Set the enableBack to false
                this.enableBack = false;
            }
        }
    }

    public back(): void {
        window.history.back();
    }

    public onProfileClick() {
        this.profileClick.emit();
    }
}
