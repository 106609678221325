<div class="header margin-bottom-1">
    <div
        class="icon"
        [class.background-color-light-orange]="isDocumentColor(colors.orange)"
        [class.background-color-light-grey]="isDocumentColor(colors.grey)"
        [class.background-color-light-green]="isDocumentColor(colors.green)"
        [class.background-color-light-blue]="isDocumentColor(colors.blue)"
        [class.background-color-light-yellow]="isDocumentColor(colors.yellow)"
    >
        @if (icon.startsWith('uil')) {
            <i class="uil {{ icon }}"></i>
        }
        @if (!icon.startsWith('uil')) {
            <img src="/assets/icon/{{ icon }}.svg" alt="Document icon" />
        }
    </div>

    <span class="title margin-left-1">{{
        document?.name ?? 'Document inconnu'
    }}</span>
</div>

@if (document?.date || document?.type) {
    <div class="sub-header margin-bottom-1">
        @if (document?.type) {
            <span class="category">{{ document?.type ?? 'Autre' }}</span>
        }
        @if (document?.date) {
            <span class="date">{{ document?.date | date: 'dd/MM/yyyy' }}</span>
        }
    </div>
}

@if (property) {
    <div class="footer">
        @if (property.photo) {
            <img
                [src]="property.photo"
                class="photo"
                alt="Photo de la propriété"
            />
        }
        @if (!property.photo) {
            <div class="property-icon background-color-light-grey">
                <i class="uil uil-home"></i>
            </div>
        }
        <span class="property-title margin-left-1">{{ property.name }}</span>
    </div>
}

@if (society) {
    <div class="footer">
        @if (society.photo) {
            <img
                [src]="society.photo"
                class="photo"
                alt="Photo de la société"
            />
        }
        @if (!society.photo) {
            <div class="property-icon background-color-light-grey">
                <i class="uil uil-home"></i>
            </div>
        }
        <span class="property-title margin-left-1">{{ society.name }}</span>
    </div>
}

@if (loan) {
    <div class="footer">
        <div class="loan-icon">
            <i class="uil uil-euro-circle"></i>
        </div>
        <span class="property-title margin-left-1">{{ loan.name }}</span>
    </div>
}

@if (saving) {
    <div class="footer">
        <div class="saving-icon">
            <i class="uil uil-wallet"></i>
        </div>
        <span class="property-title margin-left-1">{{ saving.name }}</span>
    </div>
}
