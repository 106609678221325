import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { SocietyEntity } from '@omedom/data';

@Component({
    selector: 'omedom-society-profil-info',
    templateUrl: './info.component.html',
    styleUrls: ['./info.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class SocietyProfilInfoComponent implements OnInit {
    /**
     * @description Society to display
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 29/09/2023
     * @type {SocietyEntity}
     * @memberof InfoComponent
     */
    @Input()
    public society?: SocietyEntity;

    constructor() {}

    ngOnInit(): void {}
}
