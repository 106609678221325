import { Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { Mode } from '@omedom/data';

import { OmedomSubTab } from './omedom-sub-tab';

@Component({
    selector: 'omedom-sub-tab',
    templateUrl: './sub-tab.component.html',
    encapsulation: ViewEncapsulation.None,
})
export class SubTabComponent {
    /**
     * @description Sub tabs of the sub tab component
     * @author Brisset Killian
     * @date 26/04/2024
     * @type {OmedomSubTab[]}
     * @memberof SubTabComponent
     */
    @Input() subTabs: OmedomSubTab[] = [];

    /**
     * @description Selected sub tab of the sub tab component
     * @author Brisset Killian
     * @date 26/04/2024
     * @memberof SubTabComponent
     */
    @Input() selectedSubTab?: OmedomSubTab;

    /**
     * @description Emit event when the selected sub tab is changed
     * @author Brisset Killian
     * @date 26/04/2024
     * @memberof SubTabComponent
     */
    @Output() selectedSubTabChange = new EventEmitter<OmedomSubTab>();

    @Input() mode: Mode = Mode.app;

    constructor() {}

    /**
     * @description Triggered when a sub tab is clicked, change the selected sub tab and
     * emit the event selectedSubTabChange with the new selected sub tab as parameter if the sub tab is not disabled
     * @author Brisset Killian
     * @date 26/04/2024
     * @param {OmedomSubTab} tab
     * @returns {*}  {void}
     * @memberof SubTabComponent
     */
    subTabClicked(tab: OmedomSubTab | undefined): void {
        if ((tab && tab.disabled) || !tab) {
            return;
        }

        this.selectedSubTab = tab;
        this.selectedSubTabChange.next(tab);
    }
}
