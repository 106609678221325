import {
    Component,
    ElementRef,
    EventEmitter,
    HostListener,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { ProEntity, PropertyEntity, PropertyType } from '@omedom/data';

@Component({
    selector: 'omedom-property-card-selectable',
    templateUrl: './property-card-selectable.component.html',
    styleUrls: ['./property-card-selectable.component.scss'],
})
export class PropertyCardSelectableComponent implements OnInit, OnChanges {
    /**
     * @description Property Data
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 27/09/2023
     * @type {PropertyEntity}
     * @memberof PropertyCardSelectableComponent
     */
    @Input()
    public property?: PropertyEntity;

    /**
     * @description True if the property is selected (default: false) (used to display a check icon on the card when the property is selected in a list of properties to select for example)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 27/09/2023
     * @type {boolean}
     * @memberof PropertyCardSelectableComponent
     */
    @Input()
    public selected: boolean = false;

    /**
     * @description Emit the selected state of the property (used to display a check icon on the card when the property is selected in a list of properties to select for example)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 27/09/2023
     * @memberof PropertyCardSelectableComponent
     */
    @Output()
    public selectedChange = new EventEmitter<boolean>();

    /**
     * @description Pro Data (used to display a check icon on the card when the property is shared to pro for example) (default: undefined) (if undefined, the check icon is not displayed on the card even if the property is shared to pro)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 27/09/2023
     * @type {ProEntity}
     * @memberof PropertyCardSelectableComponent
     */
    @Input()
    public pro?: ProEntity;

    /**
     * @description If the user use a pro licence to accept share conditions with pro (default: false)
     * @author Didier Pascarel <didier.pascarel@omedom.com>
     * @date 06/10/2023
     * @type {boolean}
     * @memberof PropertyCardSelectableComponent
     */
    @Input()
    public shareWithProConditions?: boolean;

    constructor(private readonly elementRef: ElementRef<HTMLElement>) {}

    public get isBuilding(): boolean {
        return !!this.property && this.property?.type === PropertyType.immeuble;
    }

    ngOnInit(): void {
        // Check if the property is already shared to pro
        // if (this.property?.sharedToPro) {
        //     this.onClick();
        // }

        if (this.selected) {
            this.elementRef.nativeElement.classList.add('selected');
        } else {
            this.elementRef.nativeElement.classList.remove('selected');
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['selected']?.currentValue) {
            this.elementRef.nativeElement.classList.add('selected');
        } else {
            this.elementRef.nativeElement.classList.remove('selected');
        }

        if (changes['property'] && this.selected && !changes['selected']) {
            this.elementRef.nativeElement.classList.add('selected');
        }

        if (changes['property'] && !this.selected && !changes['selected']) {
            this.elementRef.nativeElement.classList.remove('selected');
        }
    }

    /**
     * @description Toggle the selected state of the property
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 27/09/2023
     * @memberof PropertyCardSelectableComponent
     */
    @HostListener('click')
    public onClick(): void {
        // Toggle the selected state
        // this.selected = !this.selected;

        // Emit the selected state
        this.selectedChange.emit(!this.selected);

        // Toggle class selected
        // this.elementRef.nativeElement.classList.toggle('selected');
    }
}
