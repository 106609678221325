@for (tab of subTabs; track tab.id) {
    <div
        (click)="subTabClicked(tab)"
        class="omedom-sub-tab"
        [class]="'omedom-sub-tab-' + mode"
        [class.selected]="selectedSubTab?.id === tab.id"
        [class.disabled]="tab.disabled"
    >
        @if (tab.icon) {
            <i class="omedom-sub-tab-icon" [class]="tab.icon"></i>
        }
        @if (tab.label) {
            <p class="omedom-sub-tab-label">{{ tab.label }}</p>
        }
        @if (tab.popover) {
            <omedom-popover [content]="tab.popover"></omedom-popover>
        }
    </div>
}
