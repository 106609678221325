<div class="flex-1">
    <!-- Display information if user can edit the property and the property don't have lease -->
    @if (!lease && roleState?.create) {
        <omedom-info
            icon="house-user"
            [hasButton]="true"
            (buttonClick)="addLease()"
            information="Vous n'avez pas encore entré de location."
        >
        </omedom-info>
    }

    <!-- Display information if user cannot edit the property and the property don't have lease -->
    @if (!lease && !roleState?.create) {
        <omedom-info
            icon="house-user"
            [hasButton]="false"
            information="Ce bien n'a pas encore de location."
        >
        </omedom-info>
    }

    <!-- Display lease with data if lease exists -->
    @if (lease) {
        <div class="lease-info-container">
            @if (showLeaseInfo) {
                <div class="container-title-button margin-bottom-2">
                    <h3 class="header">Bail</h3>
                    @if (
                        lease &&
                        roleState?.update &&
                        showLeaseInfo &&
                        mode === allModes.desktop
                    ) {
                        <div class="omedom-sticky-button-container">
                            @if (roleState?.delete) {
                                <button
                                    id="delete-button"
                                    (click)="modal.present()"
                                    class="omedom-icon-button-warn-secondary"
                                >
                                    <i class="uil uil-trash-alt"></i>
                                </button>
                            }
                            <button
                                class="omedom-icon-button-primary"
                                (click)="editLease()"
                            >
                                <i class="uil uil-edit-alt"></i>
                            </button>
                        </div>
                    }
                </div>
                <div class="dates-info">
                    <div class="sub-title">Dates du bail</div>
                    <div class="dates-container">
                        <i class="uil uil-calendar-alt"></i>
                        <span
                            >{{ lease.leaseStart | date: 'dd/MM/YYYY' }}
                        </span>
                        <span class="line"></span>
                        <span>{{ lease.leaseEnd | date: 'dd/MM/YYYY' }}</span>
                    </div>
                </div>
                <div class="lease-type">
                    <div class="sub-title">Type de bail :</div>
                    <span>{{ lease.leaseType.label }}</span>
                </div>
                @if (leaseRent) {
                    <div class="rent-info-card">
                        <div class="card-header">
                            <span>Loyer perçu</span>
                            <span class="amount"
                                >{{ leaseRent?.amount }}&nbsp;€</span
                            >
                        </div>
                        <div class="card-footer">
                            <span>{{ leasePeriodicityLabel }}</span>
                            <span class="line"></span>
                            <span>{{ leaseDebitDate }} </span>
                        </div>
                    </div>
                }
                @if (!leaseRent) {
                    <div class="rent-info-card">
                        <omedom-info
                            style="width: 100%"
                            information="Ajouter un loyer à cette location."
                        ></omedom-info>
                    </div>
                }
            }
            @if (showTenantsInfo) {
                <div class="container-title-button margin-bottom-2">
                    <h3 class="header flex-1">Locataire(s)</h3>
                    @if (roleState?.create) {
                        <button
                            class="omedom-icon-button-primary w-100"
                            (click)="openModal()"
                        >
                            <i class="uil uil-user-plus"></i>
                        </button>
                    }
                </div>
                <div class="tenant-carrousel">
                    <div class="tenant-card-container-wrapper">
                        <div class="tenant-card-container">
                            @for (
                                tenant of tenants;
                                track tenant.email;
                                let i = $index
                            ) {
                                <div class="tenant-card" @element>
                                    <div class="header">
                                        <div class="tenant-info">
                                            <div class="title">
                                                <i
                                                    class="uil-users-alt margin-right-1"
                                                ></i>
                                                <span>{{
                                                    tenant.firstname +
                                                        ' ' +
                                                        tenant.lastname
                                                }}</span>
                                            </div>
                                            @if (tenant.email) {
                                                <span class="email">
                                                    <i
                                                        class="uil uil-envelope"
                                                    ></i>
                                                    {{ tenant.email }}
                                                </span>
                                            }
                                            @if (tenant.phone) {
                                                <span class="tel">
                                                    <i
                                                        class="uil uil-phone-volume"
                                                    ></i>
                                                    {{ tenant.phone }}
                                                </span>
                                            }
                                        </div>
                                        <!-- Tenant edit and delete buttons -->
                                        @if (roleState?.update) {
                                            <div
                                                class="side-action margin-top-1"
                                            >
                                                <div class="delete">
                                                    @if (roleState?.delete) {
                                                        <i
                                                            class="uil uil-trash-alt"
                                                            (click)="
                                                                openRemoveTenantModal(
                                                                    i
                                                                )
                                                            "
                                                        ></i>
                                                    }
                                                </div>
                                                <div class="edit">
                                                    <i
                                                        class="uil uil-edit-alt"
                                                        (click)="editTenant(i)"
                                                    ></i>
                                                </div>
                                            </div>
                                        }
                                        <!-- # -->
                                    </div>
                                    @if (tenant.helpUID) {
                                        <div class="rent-info-card">
                                            <div class="card-header">
                                                <span>Aides</span>
                                                <span class="amount"
                                                    >{{
                                                        tenantAids[i]?.amount
                                                    }}€</span
                                                >
                                            </div>
                                            <div class="card-footer">
                                                <span>{{
                                                    tenantAids[i]?.periodicity
                                                }}</span>
                                                <span class="line"></span>
                                                <span
                                                    >le
                                                    {{ tenantAids[i]?.date }} du
                                                    mois</span
                                                >
                                            </div>
                                        </div>
                                    }
                                    @if (
                                        !tenant.helpUID &&
                                        roleState?.create &&
                                        canAddAid
                                    ) {
                                        <button
                                            class="omedom-button-secondary w-100"
                                            (click)="addAid(tenant.uid)"
                                        >
                                            <i class="uil uil-plus"></i>
                                            <span>Ajouter une aide</span>
                                        </button>
                                    }
                                </div>
                            }
                        </div>
                    </div>
                    @if (tenants.length > 1) {
                        <div class="next-prev-container">
                            <button class="prev" (click)="prevSlide()">
                                &#10094;
                            </button>
                            <button class="next" (click)="nextSlide()">
                                &#10095;
                            </button>
                        </div>
                    }
                </div>
                @if (tenants.length === 0) {
                    <omedom-info
                        style="width: 100%"
                        information="Ajouter un locataire à cette location."
                    ></omedom-info>
                }
            }
            @if (showRentState) {
                <h3 class="header">Quittances et lettres de relances</h3>
                <button
                    class="omedom-button-primary w-100 margin-top-2"
                    routerLink="/tabs/home/rents"
                >
                    <img
                        src="/assets/icon/quittance-primary.svg"
                        alt="Icone quittance"
                    />
                    <span>Accéder à l'Etat des loyers</span>
                </button>
            }
        </div>
    }
</div>

<!-- Lease delete and edit buttons-->
@if (lease && roleState?.update && showLeaseInfo && mode === allModes.app) {
    <div class="omedom-sticky-button-container">
        @if (roleState?.delete) {
            <button
                id="delete-button"
                (click)="modal.present()"
                class="omedom-icon-button-warn-secondary"
            >
                <i class="uil uil-trash-alt"></i>
            </button>
        }
        <button class="omedom-icon-button-primary" (click)="editLease()">
            <i class="uil uil-edit-alt"></i>
        </button>
    </div>
}
<!-- # -->
<div class="omedom-sticky-button-container">
    @if (!lease && roleState?.create) {
        <button class="omedom-button-primary flex-1" (click)="addLease()">
            <i class="uil uil-house-user"></i>
            Ajouter une location
        </button>
    }
</div>

<ion-modal
    #modal
    trigger="delete-button"
    [breakpoints]="[0, 1]"
    initialBreakpoint="1"
>
    <ng-template>
        <div class="omedom-modal">
            <div class="omedom-modal-header">
                <div class="omedom-modal-header-title">
                    <i
                        class="uil uil-trash-alt color-light-black background-color-light-orange"
                    ></i>
                    <h3>Suppression de location</h3>
                </div>
                <i class="uil uil-times-circle" (click)="modal.dismiss()"></i>
            </div>
            <div class="omedom-modal-content">
                <p>Êtes-vous sûr.e de vouloir supprimer cette location ?</p>
                <omedom-info
                    information="En supprimant cette location vous allez perdre toutes les données entrées dans l''application."
                >
                </omedom-info>
                <hr class="omedom-divider" />
            </div>
            <div class="omedom-modal-buttons">
                <button
                    class="omedom-button-secondary"
                    (click)="modal.dismiss()"
                >
                    Annuler
                </button>
                <button
                    class="omedom-button-warn"
                    (click)="deleteConfirmed(modal)"
                >
                    <i class="uil uil-trash-alt"></i>
                    Oui supprimer
                </button>
            </div>
        </div>
    </ng-template>
</ion-modal>

<ion-modal #removeTenantModal [breakpoints]="[0, 1]" initialBreakpoint="1">
    <ng-template>
        <div class="omedom-modal">
            <div class="omedom-modal-header">
                <div class="omedom-modal-header-title">
                    <i
                        class="uil uil-trash-alt color-light-black background-color-light-orange"
                    ></i>
                    <h3>Suppression d'un locataire</h3>
                </div>
                <i
                    class="uil uil-times-circle"
                    (click)="removeTenantModal.dismiss()"
                ></i>
            </div>
            <div class="omedom-modal-content">
                <p>Êtes-vous sûr.e de vouloir supprimer ce locataire ?</p>
                <omedom-info
                    information="En supprimant ce locataire vous allez perdre toutes les données entrées dans l’application."
                >
                </omedom-info>
                <hr class="omedom-divider" />
            </div>
            <div class="omedom-modal-buttons">
                <button
                    class="omedom-button-secondary"
                    (click)="removeTenantModal.dismiss()"
                >
                    Annuler
                </button>
                <button class="omedom-button-warn" (click)="removeTenant()">
                    <i class="uil uil-trash-alt"></i>
                    Oui supprimer
                </button>
            </div>
        </div>
    </ng-template>
</ion-modal>
