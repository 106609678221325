import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { NgModel } from '@angular/forms';
import { Sharing, UserEntity } from '@omedom/data';

@Directive({
    selector: '[omedomDuplicateSharingEmail]',
    providers: [NgModel],
})
export class OmedomDuplicateSharingEmailDirective<T extends Sharing> {
    /**
     * @description List of sharings to compare with the input value to check if it is a duplicate email
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 02/01/2025
     * @type {T[]}
     * @memberof OmedomDuplicateSharingEmailDirective
     */
    @Input('omedomDuplicateSharingEmail') sharings: T[] = [];

    /**
     * @description Index of the current sharing in the list of sharings to exclude it from the comparison with the input value
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 02/01/2025
     * @type {number}
     * @memberof OmedomDuplicateSharingEmailDirective
     */
    @Input() currentIndex!: number;

    /**
     * @description Current user to exclude it from the comparison with the input value if it is present in the list of sharings
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 02/01/2025
     * @type {UserEntity}
     * @memberof OmedomDuplicateSharingEmailDirective
     */
    @Input() currentUser?: UserEntity;

    constructor(
        private el: ElementRef,
        private ngModel: NgModel,
    ) {}

    @HostListener('input', ['$event.target.value'])
    onInput(value: string) {
        // Vérifie si l'email est déjà présent dans la liste des partages, excluant l'élément courant
        const duplicate = this.sharings.some(
            (sharing, index) =>
                (index !== this.currentIndex && sharing.email === value) ||
                this.currentUser?.email === value,
        );

        // Ajoute ou enlève une classe d'erreur en fonction du résultat
        if (duplicate) {
            this.el.nativeElement.classList.add('duplicate-email-error');
            this.ngModel.control.setErrors({ duplicate: true });
        } else {
            this.el.nativeElement.classList.remove('duplicate-email-error');
            const errors = this.ngModel.control.errors;
            if (errors) {
                delete errors['duplicate'];
                if (Object.keys(errors).length === 0) {
                    this.ngModel.control.setErrors(null);
                }
            }
        }
    }
}
