import { Component, Input, OnInit, Output, EventEmitter, HostListener } from '@angular/core';
import { ProEntity } from '@omedom/data';

@Component({
    selector: 'omedom-directory-card',
    templateUrl: './directory-card.component.html',
    styleUrls: ['./directory-card.component.scss'],
})
export class DirectoryCardComponent implements OnInit {
    @Input({ required: true })
    pro!: ProEntity;

    @Output() detailClicked = new EventEmitter();

    get values(): string {
        return this.pro.values ? this.pro.values.join(', ') : '';
    }

    constructor() {}

    ngOnInit() {}

    onDetailClicked(event: MouseEvent) {
        event.stopPropagation();
        this.detailClicked.emit(this.pro.uid);
    }
}
