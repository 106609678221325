@if (asset) {
    <div class="asset-sharing-card-container">
        <div class="asset-sharing-avatar">
            @if (asset.photo) {
                <img
                    class="asset-sharing-avatar-image"
                    src="{{ asset.photo }}"
                    alt="{{ asset.name }}"
                />
            } @else {
                @if (assetOptions.icon; as icon) {
                    <div class="asset-sharing-avatar-placeholder">
                        <i class="{{ icon }}"></i>
                    </div>
                }
            }
        </div>
        <div class="asset-sharing-card-content">
            <div class="asset-sharing-header">
                <div class="asset-sharing-header-title">
                    {{ asset.name }}
                </div>
                @if (sharings && isOwner) {
                    <div class="asset-sharing-header-number">
                        {{ sharings.length }} partage{{
                            sharings.length > 1 ? 's' : ''
                        }}
                    </div>
                }
            </div>

            <div class="asset-sharing-list">
                @if (isOwner) {
                    @for (sharing of sharings; track $index) {
                        <div class="asset-sharing-list-item">
                            <div class="asset-sharing-list-item-form">
                                <omedom-input
                                    name="email-{{ $index }}"
                                    [(ngModel)]="sharing.email"
                                    [required]="true"
                                    [pattern]="emailRegex"
                                    placeholder="Email"
                                    [omedomDuplicateSharingEmail]="sharings"
                                    [currentIndex]="$index"
                                    [currentUser]="user"
                                ></omedom-input>
                                <omedom-input
                                    name="firstname-{{ $index }}"
                                    [(ngModel)]="sharing.firstname"
                                    [required]="true"
                                    placeholder="Prénom"
                                ></omedom-input>
                                <omedom-input
                                    name="name-{{ $index }}"
                                    [(ngModel)]="sharing.name"
                                    [required]="true"
                                    placeholder="Nom"
                                ></omedom-input>
                                <omedom-select
                                    name="role-{{ $index }}"
                                    [options]="sharing.roleOptions"
                                    [(ngModel)]="sharing.role"
                                    [required]="true"
                                    [placeholder]="{
                                        label: 'Choisir un rôle',
                                        id: null
                                    }"
                                ></omedom-select>
                            </div>

                            <div class="asset-sharing-list-item-actions">
                                <button
                                    class="omedom-icon-button-warn"
                                    (click)="
                                        removeSharing(modal, sharing, $index)
                                    "
                                >
                                    <i class="uil uil-trash-alt"></i>
                                </button>
                            </div>
                        </div>
                    } @empty {
                        <div class="asset-sharing-list-empty">
                            Aucun partage pour le moment
                        </div>
                    }
                } @else {
                    @if (userOwner$ | async; as userOwner) {
                        <div class="asset-sharing-list-item-info">
                            @if (role) {
                                <span class="margin-bottom-1">
                                    <strong>Votre rôle</strong> :
                                    {{ role }}
                                </span>
                            }
                            <span>
                                <strong>Partagé par</strong> :
                                {{ userOwner.firstname }} {{ userOwner.name }}
                            </span>

                            @if (userOwner.email) {
                                <span> {{ userOwner.email }} </span>
                            }
                        </div>
                    }
                }
            </div>
            @if (isOwner) {
                @if (errorMessage) {
                    <div class="asset-sharing-error">
                        {{ errorMessage }}
                    </div>
                }

                <div class="asset-sharing-footer">
                    <button
                        class="omedom-icon-button-primary"
                        (click)="addSharing()"
                    >
                        <i class="uil uil-plus"></i>
                    </button>

                    @if (hasModifiedSharings) {
                        <button
                            class="omedom-button-primary"
                            (click)="saveSharings()"
                            [disabled]="!sharings || !allSharingsValid"
                        >
                            <i class="uil uil-save"></i>
                            <span>Enregistrer</span>
                        </button>
                    } @else {
                        <div class="flex-1"></div>
                    }
                </div>
            }
        </div>
    </div>
}

<ion-modal
    #modal
    trigger="delete-button"
    [breakpoints]="[0, 1]"
    initialBreakpoint="1"
>
    <ng-template let-sharing="sharing">
        <div class="omedom-modal">
            <div class="omedom-modal-header">
                <div class="omedom-modal-header-title">
                    <i
                        class="uil uil-trash-alt color-light-black background-color-light-orange"
                    ></i>
                    <h3>Suppression de l'accès</h3>
                </div>
                <i class="uil uil-times-circle" (click)="modal.dismiss()"></i>
            </div>
            <div class="omedom-modal-content">
                @if (modal.componentProps?.['message']; as message) {
                    <p [innerHTML]="message"></p>
                } @else {
                    <p>Voulez-vous vraiment révoquer l'accès à ce bien ?</p>
                }
            </div>
            <div class="omedom-modal-buttons">
                <button
                    class="omedom-button-secondary"
                    (click)="modal.dismiss()"
                >
                    Annuler
                </button>
                <button
                    class="omedom-button-warn"
                    (click)="modal.dismiss(true)"
                >
                    <i class="uil uil-trash-alt"></i>
                    Oui supprimer
                </button>
            </div>
        </div>
    </ng-template>
</ion-modal>
