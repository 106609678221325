export enum SubscriptionRank {
    ESSENTIAL = 'essential',
    SMART = 'smart',
    PREMIUM = 'premium',
    FAMILY = 'family',
    PRO = 'pro',
    TRIAL = 'trial',
    BLACKFRIDAY2024 = 'blackfriday-2024',
    NOEL2024 = 'noel2024',
}
