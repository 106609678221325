import {
    Component,
    EventEmitter,
    HostBinding,
    Input,
    OnChanges,
    OnInit,
    Output,
    SimpleChanges,
} from '@angular/core';
import { Browser } from '@capacitor/browser';
import { ToastController } from '@ionic/angular';
import {
    BankAccountEntity,
    BankAccountType,
    BankEntity,
    bankErrorMessages,
    BankItemEntity,
    BankStatusCode,
} from '@omedom/data';
import { BankItemService, BankService } from '@omedom/services';
import { BehaviorSubject } from 'rxjs';

import { elementAnimation, listAnimation } from '../../../animations';

@Component({
    selector: 'omedom-bank-card',
    templateUrl: './bank-card.component.html',
    styleUrls: ['./bank-card.component.scss'],
    animations: [elementAnimation, listAnimation],
})
export class BankCardComponent implements OnInit, OnChanges {
    /**
     * @description Bank item data
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 22/04/2024
     * @type {BankItemEntity}
     * @memberof BankCardComponent
     */
    @Input({ required: true })
    public item!: BankItemEntity;

    /**
     * @description Bank accounts of the item
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 22/04/2024
     * @type {BankAccountEntity[]}
     * @memberof BankCardComponent
     */
    @Input({ required: true })
    public accounts!: BankAccountEntity[];

    /**
     * @description Bank data
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 22/04/2024
     * @type {BankEntity}
     * @memberof BankCardComponent
     */
    public bank?: BankEntity;

    /**
     * @description See more state of the item
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 22/04/2024
     * @memberof BankCardComponent
     */
    public seeMore = false;

    /**
     * @description State of the component
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 22/04/2024
     * @memberof BankCardComponent
     */
    public state$ = new BehaviorSubject<string>('ok');

    /**
     * @description Max height of the item card component
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 22/04/2024
     * @memberof BankCardComponent
     */
    @HostBinding('style.max-height')
    public maxHeight = '70px';

    /**
     * @description Emit when the item is deleted (used to delete the item in the parent component for example) (default: void)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 30/04/2024
     * @memberof BankCardComponent
     */
    @Output()
    public onDelete = new EventEmitter<void>();

    /**
     * @description Emit when the synthesis button is clicked (used to display the synthesis of the item in the parent component for example) (default: void)
     * @author Brisset Killian
     * @date 24/06/2024
     * @memberof BankCardComponent
     */
    @Output()
    public onSynthesis = new EventEmitter<void>();

    /**
     * @description Show the delete button (default: true)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 30/04/2024
     * @memberof BankCardComponent
     */
    @Input()
    public showDeleteButton = true;

    /**
     * @description Show the synthesis button (default: true)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 30/04/2024
     * @memberof BankCardComponent
     */
    @Input()
    public showSynthesisButton = true;

    /**
     * @description Show the action button (default: true)
     * @author Brisset Killian
     * @date 21/06/2024
     * @memberof BankCardComponent
     */
    @Input()
    public canSeeMore = true;

    /**
     * @description Bank status code enum to use in the template
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 30/04/2024
     * @memberof BankCardComponent
     */
    public statusCode = BankStatusCode;

    /**
     * @description Error message to display in the template
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 30/04/2024
     * @memberof BankCardComponent
     */
    public errorMessage$ = new BehaviorSubject<string>('');

    /**
     * @description Action enabled state of the item card component
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 30/04/2024
     * @memberof BankCardComponent
     */
    public actionEnabled$ = new BehaviorSubject<boolean>(false);

    /**
     * @description Action message to display in the template when the action is enabled
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 30/04/2024
     * @memberof BankCardComponent
     */
    public actionMessage$ = new BehaviorSubject<string>('');

    /**
     * @description State of the action of the item card component (pending, ok, error) to display the loader or an error message in the template
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 30/04/2024
     * @memberof BankCardComponent
     */
    public actionState$ = new BehaviorSubject<string>('ok');

    /**
     * @description Open the item card component by default (default: false)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 30/05/2024
     * @memberof BankCardComponent
     */
    @Input()
    public openByDefault = false;

    /**
     * @description Show the balance of the item card component (default: true)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 05/06/2024
     * @memberof BankCardComponent
     */
    @Input()
    public showBalance = true;

    constructor(
        private bankService: BankService,
        private bankItemService: BankItemService,
        private toastController: ToastController
    ) { }

    async ngOnInit(): Promise<void> {
        // Init state
        this.state$.next('pending');

        try {
            this.bank = await this.bankService.getBankFromID(this.item.bankID);
            this.state$.next('ok');
        } catch (error) {
            this.state$.next('error');
        }

        // Check if bank is not null
        if (!this.bank) {
            this.state$.next('error');
            this.openByDefault = true;
            this.toggle(this.openByDefault);
            return;
        }

        // If the bank has a status code different from OK, open the item by default
        if (this.item.status !== BankStatusCode.OK) {
            this.openByDefault = true;
            this.toggle(this.openByDefault);
        }

        // Check if the item should be open by default
        if (this.openByDefault) {
            this.toggle(this.openByDefault);
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['item'] && changes['item'].currentValue) {
            this.setErrorMessage();
        }
    }

    /**
     * @description Return the balance of the item
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 22/04/2024
     * @readonly
     * @type {number}
     * @memberof BankCardComponent
     */
    public get balance(): number {
        return this.accounts.reduce((acc, account) => {
            // Do not display the balance of a loan account
            // Or a brokerage account
            // Or a shared saving plan account
            if (
                account.type === BankAccountType.Loan ||
                account.type === BankAccountType.Brokerage ||
                account.type === BankAccountType.SharedSavingPlan ||
                account.type === BankAccountType.LifeInsurance ||
                account.type === BankAccountType.Savings
            ) {
                return acc;
            }

            return acc + account.balance;
        }, 0);
    }

    /**
     * @description Return the accounts of the item by type to display them in the template by type (current, saving, loan, etc.) (default: {})
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 04/07/2024
     * @readonly
     * @type {{type: BankAccountType, accounts: BankAccountEntity[]}[]}
     * @memberof BankCardComponent
     */
    public get accountsByType(): { type: BankAccountType, accounts: BankAccountEntity[], balance: number; }[] {
        return Object.values(BankAccountType).map((type) => {
            return {
                type,
                accounts: this.accounts.filter((account) => account.type === type),
                balance: this.accounts
                    .filter((account) => account.type === type)
                    .reduce((acc, account) => acc + account.balance, 0),
            };
        });
    }

    /**
     * @description Toggle the see more state of the item card component
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 22/04/2024
     * @memberof BankCardComponent
     * @param {boolean} [state] State to set
     */
    public toggle(state?: boolean): void {
        if (!this.canSeeMore) {
            this.seeMore = this.openByDefault;
            this.maxHeight = this.openByDefault ? '2000px' : '70px';
            return;
        }
        this.seeMore = state ?? !this.seeMore;
        this.maxHeight = this.seeMore ? '2000px' : '70px';
    }

    /**
     * @description Emit the delete event of the item
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 30/04/2024
     * @returns {Promise<void>}
     * @memberof BankCardComponent
     */
    public async deleteItem(): Promise<void> {
        this.onDelete.emit();
    }

    /**
     * @description Emit the synthesis event of the item
     * @author Brisset Killian
     * @date 24/06/2024
     * @returns {*}  {Promise<void>}
     * @memberof BankCardComponent
     */
    public async goToSynthesis(): Promise<void> {
        this.onSynthesis.emit();
    }

    /**
     * @description Set the error message of the item card component depending on the status of the item
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 30/04/2024
     * @private
     * @memberof BankCardComponent
     */
    private setErrorMessage(): void {
        const message = bankErrorMessages.find((m) => m.status === this.item.status);

        if (message) {
            this.errorMessage$.next(message.message);
            this.actionEnabled$.next(message.actionEnabled);
            this.actionMessage$.next(message.actionMessage || '');
        } else if (this.item.status !== BankStatusCode.OK) {
            this.errorMessage$.next(
                'Une erreur est survenue lors de la synchronisation de cette banque. Veuillez réessayer.'
            );
            this.actionEnabled$.next(true);
            this.actionMessage$.next('Réessayer');
        }
    }

    /**
     * @description Action to execute depend on the item status
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 30/04/2024
     * @returns {Promise<void>}
     * @memberof BankCardComponent
     */
    public async action(): Promise<void> {
        switch (this.item.status) {
            case BankStatusCode.CouldNotRefresh:
            case BankStatusCode.LoginFailed:
                await this.session('edit');
                break;
            case BankStatusCode.NeedsManuelRefresh:
                await this.session('sca');
                break;
            case BankStatusCode.ProAccountLocked:
                await this.session('pro');
                break;
            default:
                return;
        }
    }

    /**
     * @description Edit the item in the browser by generating an edit session URL and opening the browser with the URL
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 30/04/2024
     * @private
     * @returns {Promise<void>}
     * @memberof BankCardComponent
     */
    private async session(type: 'edit' | 'sca' | 'pro'): Promise<void> {
        // Set the state to pending
        this.actionState$.next('pending');

        try {
            // Set the message to display in the confirm button
            this.actionMessage$.next('Génération de la session...');

            // Generate the edit session URL
            let url: string;

            if (type === 'edit') {
                url = await this.bankItemService.generateBankEditSession(this.item);
            } else if (type === 'sca') {
                url = await this.bankItemService.generateBankSCASession(this.item);
            } else {
                url = await this.bankItemService.generateBankProSession();
            }

            // Open the browser with the URL
            await Browser.open({ url });
        } catch (error) {
            // Display a toast message
            const toast = await this.toastController.create({
                message: 'Impossible de générer la session de synchronisation à cette banque',
                duration: 3000,
                color: 'danger',
            });

            await toast.present();
        }

        // Set the message to display in the confirm button
        this.setErrorMessage();

        // Set the state to ok
        this.actionState$.next('ok');
    }

    /**
     * @description Return the label of the bank account type to display it in the template (current, saving, loan, etc.) (default: '')
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 04/07/2024
     * @param {BankAccountType} type
     * @returns {string}
     * @memberof BankCardComponent
     */
    public getTypeLabel(type: BankAccountType): string {
        switch (type) {
            case BankAccountType.Checking:
                return 'Comptes courants';
            case BankAccountType.Card:
                return 'Cartes';
            case BankAccountType.Savings:
                return 'Comptes épargnes';
            case BankAccountType.Loan:
                return 'Crédits';
            case BankAccountType.Brokerage:
                return 'Comptes titres';
            case BankAccountType.SharedSavingPlan:
                return 'PEA';
            case BankAccountType.LifeInsurance:
                return 'Assurances vie';
            default:
                return '';
        }
    }
}
