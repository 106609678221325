import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { ActivatedRoute, Params, Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import {
    ChargeEntity,
    DashboardType,
    IncomeEntity,
    OmedomDateType,
    PropertyEntity,
    PropertyType,
    SocietyEntity,
    Story,
    UserEntity,
} from '@omedom/data';
import { ChargeService, IncomeService, PropertyService, SocietyService, UserService } from '@omedom/services';
import { OmedomMovement, OmedomProperty } from '@omedom/utils';
import * as lodash from 'lodash';
import { BehaviorSubject, combineLatest, of, Subscription } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

import { elementAnimation } from '../../animations';
import {
    ModalSuggestSubscriptionComponent,
} from '../../components/modal-suggest-subscription/modal-suggest-subscription.component';
import { OmedomSubTab } from '../../components/sub-tab/omedom-sub-tab';
import { SubTabComponent } from '../../components/sub-tab/sub-tab.component';
import { StoryDisplayComponent } from '../story-display/story-display.component';
import { TreasuryEditComponent } from '../treasury-edit/treasury-edit.component';

@Component({
    selector: 'omedom-history-list',
    templateUrl: './history-list.component.html',
    styleUrls: ['./history-list.component.scss'],
    animations: [elementAnimation],
})
export class HistoryListComponent implements OnInit, OnDestroy {
    @Input({ required: true })
    dashboardType?: DashboardType;

    @Input()
    canManageSociety: boolean = false;

    @Output()
    dashboardTypeChange = new EventEmitter<DashboardType>();

    user!: UserEntity;

    storiesByDay: BehaviorSubject<{ date: Date; stories: Story[] }[]> = new BehaviorSubject(
        [] as { date: Date; stories: Story[] }[],
    );

    currentDate = new Date().toUTC();

    omedomDateType = OmedomDateType;

    /**
     * @description The last dashboard type selected by the user
     * @author Didier Pascarel <didier.pascarel@omedom.com>
     * @date 06/06/2024
     * @private
     * @type {DashboardType}
     * @memberof HomePage
     */
    private lastDashboardType?: DashboardType;

    /**
     * @description Charges from server, used to display stories by day and treasury by property or society or residency
     * @author Didier Pascarel <didier.pascarel@omedom.com>
     * @date 23/05/2024
     * @private
     * @type {ChargeEntity[]}
     * @memberof HistoryListComponent
     */
    private charges: ChargeEntity[] = [];

    /**
     * @description Incomes from server, used to display stories by day and treasury by property or society or residency
     * @author Didier Pascarel <didier.pascarel@omedom.com>
     * @date 23/05/2024
     * @private
     * @type {IncomeEntity[]}
     * @memberof HistoryListComponent
     */
    private incomes: IncomeEntity[] = [];

    private properties: PropertyEntity[] = [];

    private societies: SocietyEntity[] = [];

    private subscriptions: Subscription[] = [];

    private residencyProperties: PropertyEntity[] = [];

    private rentalProperties: PropertyEntity[] = [];

    /**
     * @description Total charges from server
     * @author Didier Pascarel <didier.pascarel@omedom.com>
     * @date 23/05/2024
     * @type {ChargeEntity[]}
     * @memberof HistoryListComponent
     */
    totalCharges: ChargeEntity[] = [];

    /**
     * @description Total incomes from server
     * @author Didier Pascarel <didier.pascarel@omedom.com>
     * @date 23/05/2024
     * @type {IncomeEntity[]}
     * @memberof HistoryListComponent
     */
    totalIncomes: IncomeEntity[] = [];

    maxDate: Date = new Date();

    subTabs: OmedomSubTab[] = [
        new OmedomSubTab({
            id: DashboardType.residency,
            label: 'Résidences',
        }),
        new OmedomSubTab({
            id: DashboardType.rental,
            label: 'Locatif',
        }),
        new OmedomSubTab({
            id: DashboardType.society,
            label: 'Sociétés',
        }),
    ];

    /**
     * @description The current sub tab selected by the user to display the right data
     * @author Didier Pascarel <didier.pascarel@omedom.com>
     * @date 06/06/2024
     * @type {SubTabComponent}
     * @memberof HomePage
     */
    @ViewChild('currentSubTab') currentSubTab?: SubTabComponent;

    selectedSubTab: OmedomSubTab = this.subTabs[0];

    public isLoaded: boolean = false;

    constructor(
        private chargeService: ChargeService,
        private incomeService: IncomeService,
        private propertyService: PropertyService,
        private societyService: SocietyService,
        private userService: UserService,
        private modalController: ModalController,
        private route: ActivatedRoute,
        private router: Router,
    ) {}

    ngOnInit(): void {
        this.maxDate = new Date();
        this.isLoaded = false;
        const user$ = this.userService.user$
            .pipe(
                tap(async (user) => {
                    this.user = user;
                    if (!this.user) {
                        return of();
                    }
                    return of(user);
                }),
                switchMap(() => {
                    // get properties and societies
                    const properties$ = this.propertyService._getUserPropertiesAndSharedAccessible(
                        this.user.uid,
                    );
                    const societies$ = this.societyService._getUserSocietiesAndShared(
                        this.user.uid,
                    );

                    const charges$ = this.chargeService._getUserCharges(this.user.uid);

                    const incomes$ = this.incomeService._getUserIncomes(this.user.uid);

                    return combineLatest([properties$, societies$, charges$, incomes$]);
                }),
                switchMap(([properties, societies, charges, incomes]) => {
                    // affect properties and societies to this
                    this.properties = properties ?? [];
                    this.societies = societies ?? [];

                    this.totalCharges = charges ?? [];
                    this.totalIncomes = incomes ?? [];

                    // get dashboard type from query params
                    const queryParams$ = this.route.queryParams;
                    return queryParams$;
                }),
                tap(async (queryParams) => {
                    this.onRouteChange(queryParams);
                    this.updateData();
                    this.isLoaded = true;
                }),
            )
            .subscribe();
        this.subscriptions.push(user$);
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((sub) => sub.unsubscribe());
    }

    public dateChange(date: Date) {
        this.currentDate = date;
        this.updateData();
    }

    async storyClicked(story: Story): Promise<void> {
        const flatStories = this.storiesByDay.value.flatMap((x) => x.stories);
        const selectedIndex = flatStories.findIndex((h) => h.uid === story.uid);

        const modal = await this.modalController.create({
            component: StoryDisplayComponent,
            breakpoints: [0, 1],
            initialBreakpoint: 1,
            canDismiss: true,
            componentProps: {
                selectedIndex: selectedIndex,
                stories: [...flatStories],
                canNavigate: true,
                isDesktop: true,
            },
        });

        await modal.present();
        modal.onDidDismiss().then(async (callback) => {
            if (
                callback.data?.updateType &&
                callback.data?.treasuryUid &&
                callback.data?.currentDate
            ) {
                const editModal = await this.modalController.create({
                    component: TreasuryEditComponent,
                    initialBreakpoint: 1,
                    breakpoints: [0, 1],
                    canDismiss: true,
                    componentProps: {
                        updateType: callback.data.updateType,
                        treasuryUid: callback.data.treasuryUid,
                        currentDate: callback.data.currentDate,
                        isCharge: callback.data.isCharge,
                    },
                    cssClass: 'max-heigth-mode',
                });

                await editModal.present();

                await editModal.onDidDismiss();
                this.updateData();
            } else {
                this.updateData();
            }
        });
    }

    /**
     * @description On route change, reload data and update treasury and stories by day
     * @author Didier Pascarel <didier.pascarel@omedom.com>
     * @date 23/05/2024
     * @private
     * @returns {*}  {Promise<void>}
     * @memberof HistoryListComponent
     */
    private onRouteChange(queryParams?: Params): void {
        // affect dashboard type to this
        // /!\ if not found in query params, we use dashboarType from Input. If not fount set default to residency
        if (queryParams?.['params']?.dashboardType) {
            this.dashboardType = queryParams['params'].dashboardType as DashboardType;
        } else if (queryParams?.['dashboardType']) {
            this.dashboardType = queryParams['dashboardType'] as DashboardType;
        } else if (!this.dashboardType) {
            this.dashboardType = DashboardType.residency;
        }
        if (!this.canManageSociety && this.dashboardType === DashboardType.society) {
            this.dashboardType = DashboardType.residency;
        }
        // await this.reloadData();
        // this.updateData();
    }

    /**
     * @description Update data and stories by day from charges and incomes
     * @author Didier Pascarel <didier.pascarel@omedom.com>
     * @date 23/05/2024
     * @private
     * @memberof HistoryListComponent
     */
    private updateData(): void {
        switch (this.dashboardType) {
            case DashboardType.rental:
                this.getRentalTreasury();
                this.lastDashboardType = DashboardType.rental;
                break;
            case DashboardType.society:
                this.getSocietyTreasury();
                this.lastDashboardType = DashboardType.society;
                break;
            case DashboardType.residency:
            default:
                this.getResidencyTreasury();
                this.lastDashboardType = DashboardType.residency;
                break;
        }

        const charges = lodash.cloneDeep(this.charges);
        const incomes = lodash.cloneDeep(this.incomes);

        const stories = OmedomMovement.getAllStoriesByPeriod(
            charges,
            incomes,
            this.properties,
            this.societies,
            this.currentDate.getUTCFirstDayOfMonth(),
            this.currentDate.getLastDayOfMonth(),
            false,
            true,
            false,
        );

        // transform treasury to stories
        this.storiesByDay.next(
            stories.reduce(
                (acc, story) => {
                    const date = story.date.getUTCDateWithoutTime();
                    const existing = acc.find((x) => x.date.getTime() === date.getTime());
                    if (existing) {
                        existing.stories.push(story);
                    } else {
                        acc.push({ date, stories: [story] });
                    }
                    return acc;
                },
                [] as { date: Date; stories: Story[] }[],
            ),
        );
    }

    /**
     * @description Get residency treasury
     * @author Didier Pascarel <didier.pascarel@omedom.com>
     * @date 22/05/2024
     * @memberof HistoryListComponent
     */
    getResidencyTreasury() {
        this.selectedSubTab = this.subTabs[0];
        this.residencyProperties = OmedomProperty.excludeRentalProperties(this.properties);
        const propertyIds = this.residencyProperties.map((x) => x.uid);

        //filter charges not deleted have notifications and property exists for residency
        const residencyCharges = this.totalCharges.filter((x) => {
            const isPropertyExists = x.propertyUID && propertyIds.includes(x.propertyUID);
            return isPropertyExists;
        });

        //filter incomes not deleted have notifications and property exists for residency
        const residencyIncomes = this.totalIncomes.filter((x) => {
            const isPropertyExists = x.propertyUID && propertyIds.includes(x.propertyUID);
            return isPropertyExists;
        });

        this.charges = residencyCharges;
        this.incomes = residencyIncomes;
    }

    /**
     * @description Get rental treasury
     * @author Didier Pascarel <didier.pascarel@omedom.com>
     * @date 22/05/2024
     * @memberof HistoryListComponent
     */
    getRentalTreasury() {
        this.selectedSubTab = this.subTabs[1];
        this.rentalProperties = OmedomProperty.excludeResidentialProperties(this.properties);
        // this.getRentalProperties();
        const propertyIds = this.rentalProperties.map((x) => x.uid);
        //filter charges not deleted have notifications and property exists for rental
        const rentalCharges = this.totalCharges.filter((x) => {
            const isPropertyExists = x.propertyUID && propertyIds.includes(x.propertyUID);
            return isPropertyExists;
        });
        //filter incomes not deleted have notifications and property exists for rental
        const rentalIncomes = this.totalIncomes.filter((x) => {
            const isPropertyExists = x.propertyUID && propertyIds.includes(x.propertyUID);
            return isPropertyExists;
        });

        this.charges = rentalCharges;
        this.incomes = rentalIncomes;
    }

    /**
     * @description Get society treasury
     * @author Didier Pascarel <didier.pascarel@omedom.com>
     * @date 22/05/2024
     * @memberof HistoryListComponent
     */
    getSocietyTreasury() {
        this.selectedSubTab = this.subTabs[2];
        const societiesIds = this.societies?.map((x) => x.uid);
        //filter charges not deleted have notifications and society exists for rental
        const rentalCharges = this.totalCharges.filter((x) => {
            const isSocietyExists = x.societyUID && societiesIds.includes(x.societyUID);
            return isSocietyExists;
        });
        //filter incomes not deleted have notifications and society exists for rental
        const rentalIncomes = this.totalIncomes.filter((x) => {
            const isSocietyExists = x.societyUID && societiesIds.includes(x.societyUID);
            return isSocietyExists;
        });

        this.charges = rentalCharges;
        this.incomes = rentalIncomes;
    }

    public changeView(dashboardType: DashboardType | undefined): void {
        if (!this.canManageSociety && dashboardType === DashboardType.society) {
            this.openModaSuggestSubscription();
            dashboardType = this.lastDashboardType;
            this.currentSubTab?.subTabClicked(
                this.subTabs.find((subTab) => subTab.id === this.lastDashboardType),
            );
        }
        this.dashboardType = dashboardType;
        this.dashboardTypeChange.emit(dashboardType);
        this.router.navigate([], {
            queryParams: { dashboardType: dashboardType },
            queryParamsHandling: 'merge',
        });
    }

    public isStorySinceOneMonth(story: Story): boolean {
        return (
            story.date.getUTCFirstDayOfMonth().getTime() <
            this.currentDate.getUTCFirstDayOfMonth().getTime()
        );
    }

    public getStoryIcon(story: Story): string {
        const isCharge = story.isCharge;
        let treasury: IncomeEntity | ChargeEntity | undefined;

        if (isCharge) {
            treasury = this.charges.find((c) => c.uid === story.uid);
        } else {
            treasury = this.incomes.find((i) => i.uid === story.uid);
        }

        if (!treasury) {
            return 'uil uil-home';
        }

        if (treasury.societyUID) {
            return 'uil uil-suitcase';
        }

        const property = this.properties.find((p) => p.uid === treasury?.propertyUID);
        if (property) {
            return property.type === PropertyType.immeuble ? 'uil uil-building' : 'uil uil-home';
        }
        return 'uil uil-home';
    }

    private async openModaSuggestSubscription() {
        const suggestSubscriptionModal = await this.modalController.create({
            component: ModalSuggestSubscriptionComponent,
            breakpoints: [0, 1],
            initialBreakpoint: 1,
            canDismiss: true,
            componentProps: {
                message: 'Fonctionnalité Premium',
                buttonText: 'Changer mon abonnement',
            },
        });
        suggestSubscriptionModal.present();
    }
}
