import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { LoadingController, ModalController, Platform, ToastController } from '@ionic/angular';
import { AppPlatforms, Mode, PropertyEntity, RoleEnum } from '@omedom/data';
import { OmedomEnvironment } from '@omedom/environment';
import { AnalyticsService, PropertyService, UserService } from '@omedom/services';
import { OmedomEmail, OmedomRegex } from '@omedom/utils';

import { CgvComponent } from '../../components/cgv/cgv.component';
import { ConfidentialityComponent } from '../../components/confidentiality/confidentiality.component';

interface RegisterForm {
    email: FormControl<string>;
    password: FormControl<string>;
    newsletter: FormControl<boolean>;
    conditions: FormControl<boolean>;
}

@Component({
    selector: 'omedom-user-register',
    templateUrl: './user-register.component.html',
    styleUrls: ['./user-register.component.scss'],
})
export class UserRegisterComponent implements OnInit {
    public postalCodeRegex = OmedomRegex.postalCodeRegex;
    public bdayRegex = OmedomRegex.birthdateRegex;
    public emailRegex = OmedomRegex.emailRegex;

    @Input()
    public environment?: typeof OmedomEnvironment.production | typeof OmedomEnvironment.development;

    @Input()
    mode: Mode = Mode.app;

    allMode = Mode;

    /**
     * @description Form Group to register
     * @author Jérémie Lopez
     * @type {FormGroup}
     * @memberof UserRegisterComponent
     */
    public registerForm: FormGroup<RegisterForm> = new FormGroup<RegisterForm>({
        email: new FormControl(this.email ?? '', {
            nonNullable: true,
            validators: [Validators.required, Validators.email],
        }),
        password: new FormControl('', {
            nonNullable: true,
            validators: [Validators.required, Validators.pattern(OmedomRegex.passwordRegex)],
        }),
        newsletter: new FormControl(false, {
            nonNullable: true,
            validators: [Validators.required],
        }),
        conditions: new FormControl(false, {
            nonNullable: true,
            validators: [Validators.required, Validators.requiredTrue],
        }),
    });

    /**
     * @description If true, the app is pending an action
     * @author Jérémie Lopez
     * @memberof UserRegisterComponent
     */
    public pending = false;

    /**
     * @description Society UID passed in the URL
     * @author Jérémie Lopez
     * @type {string}
     * @memberof UserRegisterComponent
     */
    public societyUID?: string;

    /**
     * @description Email passed int the URL
     * @author Jérémie Lopez
     * @type {string}
     * @memberof UserRegisterComponent
     */
    public email?: string;
    /**
     * @description List of email domains to display in the view for the user to choose from
     * @author Brisset Killian
     * @date 22/04/2024
     * @memberof UserRegisterComponent
     */
    public emailDomains = [
        '@icloud.com',
        '@me.com',
        '@gmail.com',
        '@hotmail.com',
        '@yahoo.com',
        '@live.com',
        '@outlook.com',
        '@orange.fr',
    ];

    /**
     * @description Password strength (0 to 5) to display a progress bar in the view
     * @author Jérémie Lopez
     * @memberof UserRegisterComponent
     */
    public passwordStrength = 0;
    numberOfCaracters?: number;
    hasNumber?: number;
    hasSpecialCaracters?: number;
    hasMajorLetter?: number;
    hasMinorLetter?: number;

    /**
     * @description Platform of the device where user has registred the app. Default is ('Web')
     * @author Didier Pascarel <didier.pascarel@omedom.com>
     * @type {AppPlatforms}
     * @memberof UserRegisterComponent
     */
    private registryPlatform: AppPlatforms = AppPlatforms.web;

    /**
     * @description If the user use a pro code to accept share conditions with pro (default: false)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 12/01/2024
     * @memberof UserRegisterComponent
     */
    public isAppleDevice = this.platform.is('ios');

    /**
     * @description Loading modal to display when the app is pending an action (register, login, etc.) to prevent user interaction with the app during this time
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 22/11/2024
     * @type {HTMLIonLoadingElement}
     * @memberof UserRegisterComponent
     */
    private loadingModal?: HTMLIonLoadingElement;

    constructor(
        private userService: UserService,
        private formBuilder: UntypedFormBuilder,
        private toast: ToastController,
        private router: Router,
        private route: ActivatedRoute,
        private modalController: ModalController,
        private platform: Platform,
        private analyticsService: AnalyticsService,
        private propertyService: PropertyService,
        private loadingController: LoadingController,
    ) {
        this.platform.ready().then(() => {
            if (this.platform.is('ios')) {
                this.registryPlatform = AppPlatforms.ios;
            } else if (this.platform.is('android')) {
                this.registryPlatform = AppPlatforms.android;
            } else {
                this.registryPlatform = AppPlatforms.web;
            }
        });
    }

    public get isAppleSignInAvailable() {
        // get if we are on android app but we can android web
        return (
            !(this.platform.is('android') && this.platform.is('mobile')) ||
            this.platform.is('ios') ||
            this.platform.is('mobileweb')
        );
    }

    /**
     * @description Return the explicit password strength (weak, medium, strong) to display a message in the view
     * @author Jérémie Lopez
     * @readonly
     * @type {string}
     * @memberof UserRegisterComponent
     */
    public get passwordStrengthExplicit(): string {
        if (this.passwordStrength < 3) {
            return 'Faible';
        } else if (this.passwordStrength < 5) {
            return 'Moyen';
        } else {
            return 'Fort';
        }
    }

    public get emailDomainFiltered(): string[] {
        const email = this.registerForm.get('email')?.value;
        if (!email || !email.includes('@')) {
            return [];
        }
        const emailDomain = email.split('@')[1];
        if (!emailDomain) {
            return this.emailDomains;
        }

        if (this.emailDomains.includes('@' + emailDomain)) {
            return [];
        }

        // Filter email domains to have the most relevant (depending on the email domain entered by the user)
        return this.emailDomains.filter((domain) => {
            const domainToCompare = domain.split('@')[1];
            let countGoodPosition = 0;
            // Find the minimum length between the two domains
            const minLength = Math.min(emailDomain.length, domainToCompare.length);
            for (let i = 0; i < minLength; i++) {
                if (emailDomain[i] === domainToCompare[i]) {
                    countGoodPosition++;
                }
            }
            let countCommon = 0;
            for (let i = 0; i < emailDomain.length; i++) {
                if (domainToCompare.includes(emailDomain[i])) {
                    countCommon++;
                }
            }
            //  Percentage of good position
            const percentagePosition = countGoodPosition / minLength;
            //  Percentage of common characters
            const percentageCommon = countCommon / emailDomain.length;

            const weightCommon = 0.4;
            const weightPosition = 0.6;

            return percentagePosition * weightPosition + percentageCommon * weightCommon > 0.5;
        });
    }

    async ngOnInit(): Promise<void> {
        // Retrieve memberSociety and email in URL
        const societyUID = this.route.snapshot.queryParamMap.get('memberSociety');
        if (societyUID) {
            this.societyUID = societyUID;
        }
        const email = this.route.snapshot.queryParamMap.get('email');
        if (email) {
            this.email = email;
        }

        this.initForm();
    }

    /**
     * @description Login with Google
     * @author Jérémie Lopez
     * @return {*}  {Promise<void>}
     * @memberof UserRegisterComponent
     */
    public async loginGoogle(): Promise<void> {
        try {
            this.loadingModal = await this.loadingController.create({
                message: 'Inscription en cours...',
            });
            await this.loadingModal.present();
            const credential = await this.userService.loginGoogle();
            this.loadingModal.dismiss();

            if (credential.additionalUserInfo?.isNewUser)
                window.localStorage.setItem('firstVisit', 'false');
            this.router.navigate(['/tunnel/verify-email']);
            if (credential.additionalUserInfo && !credential.additionalUserInfo.isNewUser)
                this.router.navigate(['/tabs/home']);

            const controller = await this.toast.create({
                position: 'top',
                color: 'primary',
                duration: 3000,
                message: `Vous êtes à présent connecté !`,
            });

            await controller.present();
        } catch (error: any) {
            await this.loadingModal?.dismiss();
            console.error(error);
            if (error?.error === 'popup_closed_by_user') {
                return;
            }
            const code = error?.code;

            switch (code) {
                case 'auth/cancelled-popup-request':
                    break;
                case 'auth/user-disabled':
                    const disabledCtrl = await this.toast.create({
                        position: 'top',
                        color: 'warning',
                        duration: 7000,
                        message: `
                        Votre compte a été désactivé. Il sera supprimé 30 jours après la demande de suppression. <br>
                        <strong>Pour annuler cette demande</strong>, dans le mail de confirmation que vous avez reçu, cliquez sur le bouton "Je souhaite continuer à utiliser Omedom".
                        `,
                    });

                    await disabledCtrl.present();
                    break;

                default:
                    const errorCtrl = await this.toast.create({
                        position: 'top',
                        color: 'danger',
                        duration: 4000,
                        message: `Une erreur s'est produite, veuillez réessayer plus tard.`,
                    });

                    await errorCtrl.present();
                    break;
            }
        }
    }

    /**
     * @description Login with Apple
     * @author Brisset Killian
     * @return {*}  {Promise<void>}
     * @memberof UserSigninComponent
     */
    public async loginApple(): Promise<void> {
        try {
            if (!this.environment) {
                throw new Error('environment-not-found');
            }
            this.loadingModal = await this.loadingController.create({
                message: 'Inscription en cours...',
            });
            await this.loadingModal.present();
            await this.userService.loginApple(this.environment?.production ?? false);
            window.localStorage.setItem('firstVisit', 'false');
            this.loadingModal.dismiss();
            this.router.navigate(['/']);

            const controller = await this.toast.create({
                position: 'top',
                color: 'primary',
                duration: 3000,
                message: `Vous êtes à présent connecté !`,
            });

            await controller.present();
        } catch (error: any) {
            await this.loadingModal?.dismiss();
            const code = error?.code;

            switch (code) {
                case 'auth/cancelled-popup-request':
                    break;
                case 'auth/user-disabled':
                    const disabledCtrl = await this.toast.create({
                        position: 'top',
                        color: 'warning',
                        duration: 7000,
                        message: `
                        Votre compte a été désactivé. Il sera supprimé 30 jours après la demande de suppression. <br>
                        <strong>Pour annuler cette demande</strong>, dans le mail de confirmation que vous avez reçu, cliquez sur le bouton "Je souhaite continuer à utiliser Omedom".
                        `,
                    });

                    await disabledCtrl.present();
                    break;

                default:
                    const errorCtrl = await this.toast.create({
                        position: 'top',
                        color: 'danger',
                        duration: 4000,
                        message: `Une erreur s'est produite, veuillez réessayer plus tard.`,
                    });

                    await errorCtrl.present();
                    break;
            }
        }
    }

    public setEmailDomain(emailDomain: string): void {
        const email = this.registerForm.get('email')?.value;
        const emailWithoutDomain = email?.split('@')[0];
        this.registerForm.get('email')?.setValue(emailWithoutDomain + emailDomain);
    }

    /**
     * @description Try to register with email and password
     * @author Jérémie Lopez
     * @return {*}  {Promise<void>}
     * @memberof UserRegisterComponent
     */
    public async submit(): Promise<void> {
        this.analyticsService.logEvent('User Inscription');
        this.pending = true;

        const data = this.registerForm.getRawValue();

        // Control if disposable email
        const emailDomain = data.email.split('@')[1];
        const findBlacklisEmail = OmedomEmail.blackList.find((domain) => domain === emailDomain);
        const emailsDomainErrorMessage: Error | null = !!findBlacklisEmail
            ? Error('email-domain-blacklisted')
            : null;

        try {
            if (emailsDomainErrorMessage) {
                throw emailsDomainErrorMessage;
            }
            this.loadingModal = await this.loadingController.create({
                message: 'Inscription en cours...',
            });
            await this.loadingModal.present();
            const userUID = await this.userService.registerEmailAndPassword(
                {
                    email: data.email,
                    role: [RoleEnum.customer],
                    newsletter: data.newsletter,
                    memberSocietiesUID: this.societyUID ? [this.societyUID] : [],
                    address: {
                        city: '',
                        street: '',
                        postalCode: '',
                    },
                    cgvValidatedVersion: this.environment?.cgvCurrentDate,
                    registryPlatform: this.registryPlatform,
                },
                data.password,
            );

            // Generate and send verification e-mail
            const newData = { ...data, uid: userUID };
            await this.userService.generateVerficationEmail(newData);

            const firstProperty = window.localStorage.getItem('userRegistrationProperty');
            if (firstProperty) {
                const firstPropertyParsed: Partial<PropertyEntity> = JSON.parse(firstProperty);
                firstPropertyParsed.userUID = userUID;
                await this.propertyService.create(firstPropertyParsed);
                window.localStorage.removeItem('userRegistrationProperty');
            }

            window.localStorage.setItem('firstVisit', 'false');
            this.loadingModal.dismiss();
            this.router.navigate(['/']); // TODO OM-963 try to rdirect to /tunnel/verify-email

            const controller = await this.toast.create({
                position: 'top',
                color: 'primary',
                duration: 3000,
                message: `Vous êtes maintenant inscrit !`,
            });

            await controller.present();
            this.pending = false;
        } catch (error: any) {
            await this.loadingModal?.dismiss();
            const code = typeof error === 'string' ? error : error.message;
            switch (code) {
                case 'email-domain-blacklisted':
                    this.registerForm.controls.email.setValue('');
                    this.pending = false;
                    const errorEmailDomain = await this.toast.create({
                        position: 'top',
                        color: 'danger',
                        duration: 4000,
                        message: `Le fournisseur de mail ${emailDomain} n'est pas autorisé`,
                    });

                    await errorEmailDomain.present();
                    break;
                case 'auth/email-already-in-use':
                    this.registerForm.controls.email.setValue('');
                    this.pending = false;

                    const errorEmail = await this.toast.create({
                        position: 'top',
                        color: 'danger',
                        duration: 4000,
                        message: `L'adresse mail existe déjà.`,
                    });

                    await errorEmail.present();
                    break;
                case 'auth/google-user':
                    this.registerForm.controls.email.setValue('');
                    this.pending = false;
                    const errorGoogle = await this.toast.create({
                        position: 'top',
                        color: 'danger',
                        duration: 4000,
                        message: `Vous avez déjà un compte avec Google, veuillez vous connecter avec Google.`,
                    });

                    await errorGoogle.present();
                    break;
                case 'auth/weak-password':
                    this.registerForm.controls.password.setValue('');
                    this.pending = false;
                    const errorPassword = await this.toast.create({
                        position: 'top',
                        color: 'danger',
                        duration: 4000,
                        message: `Le mot de passe est très faible.`,
                    });

                    await errorPassword.present();
                    break;
                default:
                    const errorCtrl = await this.toast.create({
                        position: 'top',
                        color: 'danger',
                        duration: 4000,
                        message: `Une erreur s'est produite, veuillez réessayer plus tard.`,
                    });

                    await errorCtrl.present();
                    break;
            }
            this.pending = false;
        }
    }

    /**
     * @description Create the form
     * @author Jérémie Lopez
     * @private
     * @memberof UserRegisterComponent
     */
    private initForm(): void {
        this.registerForm.get('password')?.valueChanges.subscribe(() => {
            this.setPasswordStrength();
        });
    }

    /**
     * @description Set the password strength value to display the password strength bar in the view
     * @author Jérémie Lopez
     * @private
     * @memberof UserRegisterComponent
     */
    private setPasswordStrength(): void {
        const passwordValue = this.registerForm.get('password')?.value ?? '';
        this.numberOfCaracters = passwordValue.length >= 8 ? 1 : 0;
        this.hasNumber = passwordValue.match(/\d+/g) ? 1 : 0;
        this.hasSpecialCaracters = passwordValue.match(/[!@#$%^&*(),.?":{}|<>]/g) ? 1 : 0;

        this.hasMajorLetter = passwordValue.match(/[A-Z]/g) ? 1 : 0;
        this.hasMinorLetter = passwordValue.match(/[a-z]/g) ? 1 : 0;

        this.passwordStrength =
            this.numberOfCaracters +
            this.hasNumber +
            this.hasSpecialCaracters +
            this.hasMajorLetter +
            this.hasMinorLetter;
    }

    public getError(field: string): string {
        let error = '';

        if (this.registerForm.get(field)?.errors?.['required']) {
            error += 'Ce champ est requis. ';
        }

        if (this.registerForm.get(field)?.errors?.['email']) {
            error += "L'adresse mail n'est pas valide. ";
        }

        if (this.registerForm.get(field)?.errors?.['isChecked']) {
            error += "Vous devez accepter les conditions d'utilisation. ";
        }

        if (this.registerForm.get(field)?.errors?.['pattern'] && field === 'password') {
            error +=
                'Le mot de passe doit contenir au moins 8 caractères, une majuscule, une minuscule, un chiffre et un caractère spécial. ';
        }

        const status = this.registerForm.get(field)?.dirty ? error : '';

        return status;
    }

    public async showMention(mention: string): Promise<void> {
        let component;
        switch (mention) {
            case 'cgv':
                component = CgvComponent;
                break;

            case 'confidentiality':
                component = ConfidentialityComponent;
                break;

            default:
                break;
        }
        if (!component) {
            return;
        }
        const modal = await this.modalController.create({
            component,
            breakpoints: [0, 1],
            initialBreakpoint: 1,
            canDismiss: true,
            componentProps: {
                modalController: this.modalController,
                isModal: true,
                cgvCurrentDate: this.environment?.cgvCurrentDate,
            },
        });

        await modal.present();
    }
}


