import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'omedom-mentions-legal',
    templateUrl: './mentions-legal.component.html',
    styleUrls: ['./mentions-legal.component.scss'],
})
export class MentionsLegalComponent implements OnInit {
    constructor() {}

    ngOnInit() {}
}
