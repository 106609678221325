import {
    ChangeDetectionStrategy,
    Component,
    ElementRef,
    Input,
    OnChanges,
    OnInit,
    QueryList,
    SimpleChanges,
    ViewChild,
    ViewChildren,
} from '@angular/core';
import { ChartStackedBarData } from '@omedom/data';
import { OmedomChart, OmedomRandom, OmedomStackedBarChart } from '@omedom/utils';
import { BaseChartDirective } from 'ng2-charts';

import { OmedomChartLegend } from '../chart-legend';
import { ChartData, ChartDataset, ChartOptions } from 'chart.js';

@Component({
    selector: 'omedom-stacked-bar-graph',
    templateUrl: './stacked-bar-graph.component.html',
    styleUrls: ['./stacked-bar-graph.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default,
})
export class StackedBarGraphComponent implements OnInit, OnChanges {
    /**
     * @description Label of the graph (optional) (default: undefined)
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 03/09/2024
     * @type {string}
     * @memberof StackedBarGraphComponent
     */
    @Input()
    public label?: string;

    /**
     * @description Position of the label of the graph (optional) (default: 'top')
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 03/09/2024
     * @type {('top' | 'right')}
     * @memberof StackedBarGraphComponent
     */
    @Input()
    public labelPosition: 'top' | 'right' = 'top';

    /**
     * @description Icon in the label of the graph (optional) (default: undefined)
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 03/09/2024
     * @type {string}
     * @memberof StackedBarGraphComponent
     */
    @Input()
    public icon?: string;

    /**
     * @description Data to display in the graph (required) (default: [])
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 03/09/2024
     * @type {ChartStackedBarData[]}
     * @memberof StackedBarGraphComponent
     */
    @Input()
    public data: ChartStackedBarData[] = [];

    /**
     * @description Show legend of the graph(optional) (default: true)
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 03/09/2024
     * @memberof StackedBarGraphComponent
     */
    @Input()
    public showLegend = true;

    /**
     * @description Show total of the graph (optional) (default: true)
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 03/09/2024
     * @memberof StackedBarGraphComponent
     */
    @Input()
    public showTotal = true;

    /**
     * @description Is legend clickable (optional)
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 03/09/2024
     * @memberof StackedBarGraphComponent
     */
    @Input()
    public isLegendClickable = true;

    /**
     * @description Gap between charts (optional) (default: 40)
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 03/09/2024
     * @memberof StackedBarGraphComponent
     */
    @Input()
    public gap = 40;

    /**
     * @description Get charts from the view to update them when data change
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 03/09/2024
     * @type {QueryList<BaseChartDirective>}
     * @memberof StackedBarGraphComponent
     */
    @ViewChildren(BaseChartDirective)
    public charts?: QueryList<BaseChartDirective>;

    @ViewChild('stackedBarChart')
    public stackedBarChart?: ElementRef<HTMLCanvasElement>;
    /**
     * @description Chart to update when data change (optional) (default: undefined)
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 03/09/2024
     * @type {BaseChartDirective}
     * @memberof StackedBarGraphComponent
     */
    public chart?: BaseChartDirective;

    /**
     * @description Data to display in the graph
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 03/09/2024
     * @memberof StackedBarGraphComponent
     */
    public stackedBarChartData: ChartData<'bar'> = { ...OmedomStackedBarChart.stackedBarChartData };

    /**
     * @description Options of the graph (colors, spacing, etc.)
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 03/09/2024
     * @memberof StackedBarGraphComponent
     */
    public stackedBarChartOptions: ChartOptions<'bar'> = {
        ...OmedomStackedBarChart.stackedBarChartOptions,
    };

    /**
     * @description Id of the graph to get it from the view to update it when data change
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 03/09/2024
     * @memberof StackedBarGraphComponent
     */
    public id = `stacked-bar-graph-${Math.floor(OmedomRandom.getRandomNumber() * 1000)}`;

    /**
     * @description Legends to display under the graph (optional) (default: [])
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 03/09/2024
     * @type {OmedomChartLegend[]}
     * @memberof StackedBarGraphComponent
     */
    public legends: OmedomChartLegend[] = [];

    public legendsByLabels: {
        label: string;
        legends: OmedomChartLegend[];
    }[] = [];

    /**
     * @description Total data of the graph
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 03/09/2024
     * @type {number}
     * @memberof StackedBarGraphComponent
     */
    public total: number = 0;

    constructor(private elementRef: ElementRef) {}

    ngOnInit(): void {
        this.updateChart();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['data']) {
            this.updateChart();
        }

        if (changes['gap']) {
            this.updateGap();
        }
    }

    /**
     * @description Update chart when data change (labels, data, legends, etc.) (private) (default: void)
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 03/09/2024
     * @private
     * @returns {*}  {void}
     * @memberof StackedBarGraphComponent
     */
    private updateChart(retry?: number): void {
        // Check if data is empty
        if (!this.data.length) {
            this.resetChart();
            return;
        }

        // Remove space between charts if there is only one
        if (this.data.length === 1) {
            this.stackedBarChartOptions = {
                ...OmedomStackedBarChart.stackedBarChartOptions,
                elements: OmedomStackedBarChart.stackedBarChartOptions.elements,
            };
        } else {
            this.stackedBarChartOptions = {
                ...OmedomStackedBarChart.stackedBarChartOptions,
                elements: OmedomStackedBarChart.stackedBarChartOptions.elements,
            };
        }

        // Get chart
        this.chart = this.charts?.find((c) => c.chart?.canvas.id === this.id);

        // Check if chart is defined
        if (!this.chart) {
            // Check if retry is defined
            if (retry ?? 0 >= 3) {
                // Stop retrying
                return;
            } else {
                // Retry to get chart
                setTimeout(() => this.updateChart(retry ? retry + 1 : 1), 500);
                return;
            }
        }

        // Set total
        this.total = this.data.reduce((acc, curr) => acc + curr.value, 0);

        // Set labels and data
        this.stackedBarChartData.labels = this.data.reduce((acc, curr) => {
            if (!acc.includes(curr.label)) {
                acc.push(curr.label);
            }
            return acc;
        }, [] as string[]);
        const datasets: ChartDataset<'bar', number[]>[] = this.data.map((d) => {
            return {
                label: d.datasetLabel,
                data:
                    this.stackedBarChartData.labels?.map((l) => {
                        const value = d.label === l ? d.value : 0;
                        return value;
                    }) ?? [],
                barPercentage: 0.4,
                backgroundColor: d.color ?? 'red',
                hoverBackgroundColor: d.hatch ? OmedomChart.hatchColor(d.color) : d.color,
            } as ChartDataset<'bar', number[]>;
        });
        this.stackedBarChartData.datasets = datasets;

        // Set legends
        this.legends = this.data.map((d) => {
            return new OmedomChartLegend({
                label: d.datasetLabel,
                amount: d.value,
                color: d.hatch ? (OmedomChart.hatchColor(d.color, 'legend') as string) : d.color,
            });
        });

        this.legendsByLabels = this.data
            .reduce((acc, curr) => {
                if (!acc.find((a) => a.label === curr.label)) {
                    acc.push({
                        label: curr.label,
                        legends: [],
                    });
                }
                acc.find((a) => a.label === curr.label)?.legends.push(
                    new OmedomChartLegend({
                        label: curr.datasetLabel,
                        amount: curr.value,
                        color: curr.hatch
                            ? (OmedomChart.hatchColor(curr.color, 'legend') as string)
                            : curr.color,
                    })
                );
                return acc;
            }, [] as { label: string; legends: OmedomChartLegend[] }[])
            .sort((a, b) => {
                return this.stackedBarChartData.labels
                    ? this.stackedBarChartData.labels.indexOf(a.label) -
                          this.stackedBarChartData.labels.indexOf(b.label)
                    : 0;
            });

        // Update chart
        this.chart.update();
    }

    /**
     * @description Reset chart when data is empty
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 03/09/2024
     * @private
     * @memberof StackedBarGraphComponent
     */
    private resetChart(): void {
        this.stackedBarChartData = { ...OmedomStackedBarChart.stackedBarChartData };
        this.stackedBarChartData.labels = [];

        this.total = 0;
        this.legends = [];
        this.legendsByLabels = [];
    }

    /**
     * @description Update gap between charts when gap change
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 03/09/2024
     * @private
     * @memberof StackedBarGraphComponent
     */
    private updateGap(): void {
        this.elementRef.nativeElement.style.setProperty('--gap', `${this.gap}px`);
    }
}
