import { Component, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import {
    AssetTypes,
    BANK_CATEGORIES_LINK,
    BankTransactionCategoryForm,
    BankTransactionEntity,
    ChargeCategoryBuilding,
    ChargeCategoryInfo,
    ChargeCategoryProperty,
    ChargeCategorySociety,
    IncomeCategoryBuilding,
    IncomeCategoryInfo,
    IncomeCategoryProperty,
    IncomeCategorySociety,
} from '@omedom/data';

import { elementAnimation } from '../../../../animations';

@Component({
    selector: 'omedom-bank-transaction-category-step',
    templateUrl: './bank-transaction-category-step.container.html',
    styleUrls: ['./bank-transaction-category-step.container.scss'],
    animations: [elementAnimation],
})
export class BankTransactionCategoryStepContainer implements OnInit {
    /**
     * @description Form group for the category step of the transaction association form
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 06/05/2024
     * @type {FormGroup<{
     *         category: FormControl<string>;
     *     }>}
     * @memberof BankTransactionCategoryStepContainer
     */
    @Input({ required: true })
    public form?: FormGroup<BankTransactionCategoryForm>;

    /**
     * @description Transaction data to associate
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 06/05/2024
     * @type {BankTransactionEntity}
     * @memberof BankTransactionCategoryStepContainer
     */
    @Input({ required: true })
    public transaction?: BankTransactionEntity;

    /**
     * @description Asset type of the transaction to associate (charge or income) to display the right categories list and recommendation message according to the transaction amount sign
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 06/05/2024
     * @type {AssetTypes}
     * @memberof BankTransactionCategoryStepContainer
     */
    @Input()
    public assetType: AssetTypes = AssetTypes.property;

    /**
     * @description List of categories to display in the select input
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 06/05/2024
     * @type {Array<ChargeCategoryInfo | IncomeCategoryInfo>}
     * @memberof BankTransactionCategoryStepContainer
     */
    public categories: Array<ChargeCategoryInfo | IncomeCategoryInfo> = [];

    /**
     * @description Recommendation message to display according to the selected category
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 06/05/2024
     * @type {string}
     * @memberof BankTransactionCategoryStepContainer
     */
    public recommendation?: string;

    constructor() {}

    ngOnInit(): void {
        this.setCategories();
        this.setRecommendation();
    }

    /**
     * @description Set the categories list according to the transaction amount sign
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 06/05/2024
     * @private
     * @memberof BankTransactionCategoryStepContainer
     */
    private setCategories(): void {
        if (!this.transaction) {
            return;
        }
        // Check if the transaction is a charge or an income
        const isCharge = this.transaction.amount < 0;

        let categories = [];

        // Set the categories list according to the asset type and the transaction amount sign
        switch (this.assetType) {
            case AssetTypes.property:
                categories = isCharge
                    ? Object.values(ChargeCategoryProperty)
                    : Object.values(IncomeCategoryProperty);
                break;
            case AssetTypes.society:
                categories = isCharge
                    ? Object.values(ChargeCategorySociety)
                    : Object.values(IncomeCategorySociety);
                break;
            case AssetTypes.building:
                categories = isCharge
                    ? Object.values(ChargeCategoryBuilding)
                    : Object.values(IncomeCategoryBuilding);
                break;
        }

        // Set the categories list according to the transaction amount sign
        this.categories = categories.map((category) => {
            if (isCharge) {
                return new ChargeCategoryInfo(
                    category as
                        | ChargeCategoryProperty
                        | ChargeCategorySociety
                        | ChargeCategoryBuilding
                );
            } else {
                return new IncomeCategoryInfo(
                    category as
                        | IncomeCategoryProperty
                        | IncomeCategorySociety
                        | IncomeCategoryBuilding
                );
            }
        });
    }

    /**
     * @description Get the options list for the select input from the categories list
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 06/05/2024
     * @readonly
     * @type {{ value: string; label: string; icon: string; }[]}
     * @memberof BankTransactionCategoryStepContainer
     */
    public get options(): { value: string; label: string; icon: string }[] {
        return this.categories.map((category) => ({
            value: category.category,
            label: category.label,
            icon: category.icon,
        }));
    }

    /**
     * @description Set the recommendation message according to the transaction category ID
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 06/05/2024
     * @private
     * @returns {void}
     * @memberof BankTransactionCategoryStepContainer
     */
    private setRecommendation(): void {
        // Find the recommendation according to the transaction category ID
        const recommendation = BANK_CATEGORIES_LINK.find((link) => {
            if (this.transaction) {
                return link.ids.includes(this.transaction.categoryID);
            }
            return false;
        });

        if (!recommendation) {
            return;
        }

        // Find the category object according to the recommendation value
        const category = this.categories.find(
            (category) => category.category === recommendation.value
        );

        if (!category) {
            return;
        }

        this.recommendation = category.label;

        // Sort the categories list to display the recommended category first
        this.categories = this.categories.sort((a, b) => {
            if (a.category === recommendation.value) {
                return -1;
            }

            if (b.category === recommendation.value) {
                return 1;
            }

            return 0;
        });

        // Update the form value with the recommended category
        this.form?.controls.category.setValue(recommendation.value);
    }
}
