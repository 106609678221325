@if (rentValuation) {
    <div class="valo-title">Estimation de la valeur locative du bien</div>
    <div class="info margin-bottom-2">valeur du loyer BRUT hors charges</div>
    <div class="valuation-container margin-bottom-2">
        <div class="valuation-price margin-right-2">
            <div class="margin-bottom-1 sub-title">Valeur locative estimée</div>
            <span>{{ rentValuation.value | number }} € / mois</span>
            <span class="info"
                >{{ meterSquarePriceAvg | number }} € / m<sup>2</sup></span
            >
        </div>
        <div
            class="valuation-price valuation-interval"
            style="font-weight: 400"
        >
            <div class="margin-bottom-1">Fourchette</div>
            <span
                >{{ rentValuation.valueRange.lower | number }} -
                {{ rentValuation.valueRange.upper | number }}
                € / mois</span
            >
            <span class="meter-price"
                >{{ meterSquarePriceLower | number }} -
                {{ meterSquarePriceUpper | number }} € / m<sup>2</sup></span
            >
        </div>
    </div>
    <div class="margin-top-1">
        <span class="information-pertinance">
            Dernière mise à jour :
            {{ lastValuationUpdate }}. Indice de confiance de l'estimation :
        </span>
        <span class="bold" [style.color]="valuationConfidenceColor">
            {{ valuationConfidence }}
        </span>
        <span></span>
    </div>
    <hr class="omedom-divider" />
}
