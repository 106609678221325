import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
    selector: 'omedom-button-change-subscription',
    templateUrl: './button-change-subscription.component.html',
    styleUrls: ['./button-change-subscription.component.scss'],
})
export class ButtonChangeSubscriptionComponent {
    constructor(private router: Router) {}

    public goToSubscription(): void {
        this.router.navigate(['/menu'], { queryParams: { menu: 'subscription' } });
    }
}
