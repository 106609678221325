import { IncomingMessage } from "http";
import * as sendinblue from '@getbrevo/brevo';
import { BrevoProviderEnum } from "../../enums";

export interface UpdateContactAttributesInterface {
    code_postal: string | null;
    compte_finalise: boolean;
    date_de_naissance: string | null;
    date_derniere_connexion: Date | null;
    email: string;
    media_inscription: BrevoProviderEnum;
    nom: string | null;
    objectif: string | null;
    origine: string;
    prenom: string | null;
    sms: string | null;
    telephone: number | null;
    telephone_app: string | null;
    ville: string | null;
}

export interface ContactInfosInterface {
    response: IncomingMessage;
    body: sendinblue.GetExtendedContactDetails;
}
