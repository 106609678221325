<div
    class="section color-light-black avatar-container"
    (click)="editAvatar()"
    [class.clickable]="edit"
>
    <img
        class="photo"
        *ngIf="property?.photo; else avatar"
        [src]="property?.photo"
        alt="Photo du bien"
    />
    <ng-template #avatar>
        <div class="avatar">
            <i *ngIf="!isBuilding" class="uil uil-home ng-star-inserted"></i>
            <i *ngIf="isBuilding" class="uil uil-building ng-star-inserted"></i>
        </div>
    </ng-template>
    <!-- <button class="omedom-medium-icon-button-primary" (click)="editAvatar()">
        <i class="uil uil-edit-alt"></i>
    </button> -->
    <!-- <button class="omedom-medium-icon-button-primary" (click)="uplaodAvatar()">
        <i
            *ngIf="(pendingPhoto$.asObservable() | async) === false"
            class="uil uil-import"
        ></i>
        <ion-spinner
            *ngIf="pendingPhoto$.asObservable() | async"
            color="default"
            class="omedom-upload-spinner"
        ></ion-spinner>
    </button> -->
</div>
<hr *ngIf="property?.photo" class="omedom-divider" />

<div class="section color-light-black">
    <span>Type de bien</span>
    <span>
        <strong>{{ property?.type || '-' }}</strong>
    </span>
</div>

<hr class="omedom-divider" />

<div class="section color-light-black">
    <span>Adresse</span>
    <span>
        <div
            *ngIf="
                property?.address?.streetNumber ||
                    property?.address?.street ||
                    property?.address?.city ||
                    property?.address?.addressLine2 ||
                    property?.address?.postalCode;
                then address;
                else empty
            "
        ></div>
        <ng-template #address>
            <i class="uil uil-location-point margin-right-1"></i>
            <strong>
                {{ property?.address?.streetNumber || '-' }}
                {{ property?.address?.suffixNumber || '' }}
                {{ property?.address?.street || '-' }}</strong
            >
            <br />
            <div
                *ngIf="
                    !property?.address?.postalCode && !property?.address?.city;
                    then empty;
                    else city
                "
            ></div>
            <ng-template #city>
                <strong>
                    {{ property?.address?.postalCode || '-' }}
                    {{ property?.address?.city || '-' }}
                </strong>
            </ng-template>
        </ng-template>
    </span>
</div>
<hr class="omedom-divider" />
<div class="section color-light-black">
    <span>Année de construction</span>
    <span>
        <strong>{{ property?.buildingYear || '-' }}</strong>
    </span>
</div>
<hr class="omedom-divider" />
<div class="section color-light-black">
    <span>Nom de l’assureur</span>
    <span>
        <strong>{{ property?.assuranceDetails?.assuranceName || '-' }}</strong>
    </span>
</div>
<div class="section color-light-black">
    <span>N° de contrat</span>
    <span>
        <strong>{{
            property?.assuranceDetails?.assuranceContractNumber || '-'
        }}</strong>
    </span>
</div>

<hr class="omedom-divider" />

<omedom-widget-rentability
    [type]="rentabilityTypeEnum.RENTABILITY"
    [value]="rentability"
    [isProperty]="true"
    *ngIf="
        property?.purchaseDetails?.use !== uses.principale &&
        property?.purchaseDetails?.use !== uses.secondaire
    "
>
</omedom-widget-rentability>
<!-- <div
    class="section color-light-black"
    *ngIf="
        property.purchaseDetails.use !== uses.principale &&
        property.purchaseDetails.use !== uses.secondaire
    "
>
    <span
        >Taux de rentabilité brut
        <app-popover class="margin-left-1">
            content="Ratio entre le prix d'acquisition de votre bien locatif
            (incluant les frais) et le total des loyers annuels chs." >
        </app-popover></span
    >
    <span>
        <strong>{{
            incomes.length > 0
                ? (rentability | number : "0.2-2" | omedomNumber) + "%"
                : "-"
        }}</strong>
    </span>
</div> -->

<hr
    class="omedom-divider"
    *ngIf="
        property?.purchaseDetails?.use !== uses.principale &&
        property?.purchaseDetails?.use !== uses.secondaire
    "
/>

<div
    class="section color-light-black"
    style="margin-bottom: 10px"
    class="notes-label"
>
    <div (click)="editNote()" tabIndex="0" aria-label="add a note">
        Notes
        <!-- <i *ngIf="!editable" class="uil uil-edit-alt margin-left-1"></i> -->
    </div>
    <button
        class="omedom-medium-icon-button-secondary-outline margin-left-1"
        style="cursor: pointer"
        *ngIf="!editable && edit"
        aria-label="add a note"
        (click)="editNote()"
    >
        <i *ngIf="property?.notes" class="uil uil-edit-alt"></i>
        <i *ngIf="!property?.notes" class="uil uil-plus"></i>
    </button>
    <!-- <div class="save-notes" *ngIf="edit"> -->
    <!-- <button
            class="omedom-medium-icon-button-primary"
            (click)="editNote()"
            style="cursor: pointer"
            *ngIf="!editable"
        >
        <i class="uil uil-edit-alt"></i>
        </button> -->
    <!-- </div> -->
    <div class="save-notes" *ngIf="editable && edit">
        <button
            class="omedom-medium-icon-button-secondary-outline"
            (click)="cancelNoteEdit()"
        >
            <i class="uil uil-times-circle"></i>
        </button>
        <button class="omedom-medium-icon-button-warn" (click)="deleteNote()">
            <img src="/assets/icon/eraser.svg" alt="Effacer" />
        </button>
        <button class="omedom-medium-icon-button-green" (click)="saveNote()">
            <i class="uil uil-save"></i>
        </button>
    </div>
</div>
<ion-textarea
    class="notes"
    [class.selected]="editable"
    *ngIf="editable"
    rows="5"
    maxrows="5"
    [readonly]="!editable"
    [(ngModel)]="notes"
></ion-textarea>
<div class="notes" *ngIf="!editable">
    {{ property?.notes }}
</div>

<ng-template #empty>
    <strong>-</strong>
</ng-template>
