import { Component, Input, OnInit } from '@angular/core';

type levelType = { letter: string; range: string; color: string; active: boolean };

@Component({
    selector: 'omedom-dpe-interface',
    templateUrl: './dpe-interface.component.html',
    styleUrls: ['./dpe-interface.component.scss'],
})
export class DPEInterfaceComponent implements OnInit {
    @Input() rating = 0;

    ratingType = 'kWh/m²/an';

    defaultLeftRatingPosition = 247.5;
    topRatingPadding = 14;
    isScreenWidthLarge = true;
    widthStep = 27.5;
    heightStep = 37;

    /**
     * @description This coefficient is user to adapt the default left position of rating, for small screen
     * @author ANDRE Felix
     * @memberof DPEInterfaceComponent
     */
    coefLeftRatingProsition = 3.15;

    /**
     * @description This coefficient is user to adapt the left position of rating at each scale of the DPE
     * @author ANDRE Felix
     * @memberof DPEInterfaceComponent
     */
    coefWidthStepSmallScreen = 0.045;

    levels: levelType[] = [
        { letter: 'A', range: '< 71', color: '#00E400', active: false },
        { letter: 'B', range: '71 - 110', color: '#9BCA02', active: false },
        { letter: 'C', range: '111 - 180', color: '#FEF300', active: false },
        { letter: 'D', range: '181 - 250', color: '#F29C00', active: false },
        { letter: 'E', range: '251 - 330', color: '#E20000', active: false },
        { letter: 'F', range: '331 - 420', color: '#980004', active: false },
        { letter: 'G', range: '> 421', color: '#6B0022', active: false },
    ];

    ratingText = '';

    ratingLeftProsition = `${this.defaultLeftRatingPosition}px`;
    ratingPosition = '0';

    constructor() {}

    ngOnInit() {
        this.setSizes();
        this.ratingText = `${this.rating} ${this.ratingType}`;
        this.getRatingPosition();
    }

    getRatingPosition() {
        const index = this.levels.findIndex((level) => {
            const range = level.range.trim(); // Supprimez les espaces autour de la plage pour éviter les problèmes de comparaison

            if (range.startsWith('<')) {
                const max = parseInt(range.substring(1).trim(), 10);
                return this.rating < max;
            } else if (range.startsWith('>')) {
                const min = parseInt(range.substring(1).trim(), 10);
                return this.rating > min;
            } else {
                const [min, max] = range.split('-').map((num) => parseInt(num.trim(), 10));
                return this.rating >= min && this.rating <= max;
            }
        });

        if (index) {
            this.levels[index].active = true;
        }
        this.ratingLeftProsition =
            index !== -1 ? this.defaultLeftRatingPosition + index * this.widthStep + 'px' : '270px';
        this.ratingPosition =
            index !== -1 ? index * this.heightStep - this.topRatingPadding + 'px' : '0'; // Ajuster la position en fonction de la hauteur des niveaux
    }

    setSizes() {
        this.isScreenWidthLarge = window.innerWidth > 550;
        if (!this.isScreenWidthLarge) {
            this.defaultLeftRatingPosition = window.innerWidth / this.coefLeftRatingProsition;
            this.widthStep = window.innerWidth * this.coefWidthStepSmallScreen;
            this.topRatingPadding = 9;
            this.heightStep = 35;
        }
    }
}
