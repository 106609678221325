<div class="omedom-page-container-content flex-1 w-100">
    <div class="cards-container w-100">
        <div class="title-container w-100">
            <omedom-sub-tab
                #currentSubTab
                [subTabs]="subTabs"
                [(selectedSubTab)]="selectedSubTab"
                (selectedSubTabChange)="changeView(selectedSubTab.id)"
            >
            </omedom-sub-tab>
        </div>
        <ng-container
            [ngTemplateOutlet]="
                !!(storiesByDay | async)?.length
                    ? notEmptyTemplate
                    : emptyTemplate
            "
        ></ng-container>
        <ng-template #emptyTemplate>
            @if (isLoaded) {
                <omedom-info
                    class="margin-top-1"
                    information="Vous n'avez pas encore de mouvements enregistrés."
                ></omedom-info>
            } @else {
                <div class="loading-container">
                    <ion-spinner></ion-spinner>
                </div>
            }
        </ng-template>
        <ng-template #notEmptyTemplate>
            @if (isLoaded) {
                @for (
                    storyByDay of storiesByDay | async;
                    track storyByDay.date
                ) {
                    <div @element class="w-100">
                        <p class="margin-0 margin-bottom-2">
                            {{ storyByDay.date | date: 'dd/MM/YYYY' }}
                        </p>
                        <div
                            class="charges-container padding-left-1 margin-bottom-2 w-100"
                        >
                            @for (
                                story of storyByDay.stories;
                                track story.uid
                            ) {
                                <div
                                    class="margin-bottom-2 main-card flex-1 w-100"
                                >
                                    <div
                                        class="omedom-card padding-1 flex-1"
                                        [class.payed]="story.isPayed"
                                        [class.pending]="
                                            !story.isPayed &&
                                            !story.isReaded &&
                                            !isStorySinceOneMonth(story)
                                        "
                                        [class.readed]="
                                            (story.isReaded &&
                                                !story.isPayed) ||
                                            (!story.isPayed &&
                                                isStorySinceOneMonth(story))
                                        "
                                        (click)="storyClicked(story)"
                                    >
                                        <div class="info flex-1">
                                            <div class="info-category">
                                                <i
                                                    class="margin-right-1"
                                                    [class]="
                                                        story.categoryInfo.icon
                                                    "
                                                ></i>
                                                <strong>{{
                                                    story.categoryInfo.label
                                                }}</strong>
                                            </div>
                                            <div class="info-property">
                                                @if (story.propertyImg) {
                                                    <img
                                                        [src]="
                                                            story.propertyImg
                                                        "
                                                        alt="Photo du bien"
                                                    />
                                                } @else {
                                                    <div class="header-icon">
                                                        <i
                                                            [class]="
                                                                getStoryIcon(
                                                                    story
                                                                )
                                                            "
                                                        ></i>
                                                    </div>
                                                }
                                                <em class="property-name">{{
                                                    story.propertyName
                                                }}</em>
                                                @if (
                                                    user.uid !== story.ownerUID
                                                ) {
                                                    <i
                                                        class="uil uil-share-alt share"
                                                    ></i>
                                                }
                                            </div>
                                            @if (story.notes) {
                                                <span class="info-notes">
                                                    <em>{{ story.notes }}</em>
                                                </span>
                                            }
                                            @if (story.designation) {
                                                <span class="info-designation">
                                                    <em
                                                        ><strong>{{
                                                            story.designation
                                                        }}</strong></em
                                                    >
                                                </span>
                                            }
                                        </div>
                                        <div class="amount margin-left-1">
                                            <strong class="amount-value">
                                                @if (story.isCharge) {
                                                    -
                                                }
                                                @if (!story.isCharge) {
                                                    +
                                                }
                                                {{
                                                    story.amount
                                                        | number
                                                            : (story.amount <=
                                                              10000
                                                                  ? ('0.2-2'
                                                                    | omedomNumber)
                                                                  : ('0.0-0'
                                                                    | omedomNumber))
                                                }}&nbsp;€
                                            </strong>
                                            <span class="amount-periodicity">
                                                <em>{{
                                                    story.periodicityInfo.label
                                                }}</em>
                                            </span>
                                        </div>
                                    </div>
                                    <div class="state margin-left-1">
                                        @if (story.isPayed) {
                                            <i
                                                class="uil uil-check-circle color-green"
                                            ></i>
                                        }
                                        @if (story.isReaded && !story.isPayed) {
                                            <i
                                                class="uil uil-times-circle color-red"
                                            ></i>
                                        }
                                        @if (
                                            !story.isPayed &&
                                            !story.isReaded &&
                                            !isStorySinceOneMonth(story)
                                        ) {
                                            <i
                                                class="uil uil-question-circle color-yellow"
                                            ></i>
                                        }
                                    </div>
                                </div>
                            }
                        </div>
                    </div>
                }
            } @else {
                <div class="loading-container">
                    <ion-spinner></ion-spinner>
                </div>
            }
        </ng-template>
        <ng-template #loading>
            <div class="loading-container">
                <ion-spinner></ion-spinner>
            </div>
        </ng-template>
        @if (currentDate) {
            <omedom-date
                [type]="omedomDateType.month"
                [date]="currentDate"
                [maxDate]="maxDate"
                (dateChange)="dateChange($event)"
            ></omedom-date>
        }
    </div>
</div>
