import { Params } from '@angular/router';

/**
 * @description Route data interface for sidenav items (title, icon, path, children?)
 * @author Jérémie Lopez <jeremie.lopez@omedom.com>
 * @date 08/09/2023
 * @export
 * @interface RouteData
 */
export interface RouteData {
    /**
     * @description Title of the route (displayed in sidenav item label and page title)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 08/09/2023
     * @type {string}
     * @memberof RouteData
     */
    title: string;

    /**
     * @description Icon name (displayed in sidenav item icon and page title icon)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 08/09/2023
     * @type {string}
     * @memberof RouteData
     */
    icon: string;

    /**
     * @description Path of the route
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 08/09/2023
     * @type {string}
     * @memberof RouteData
     */
    path: string;

    /**
     * @description Query params of the route (optional) (default: undefined) (used for sidenav item order)
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 26/11/2024
     * @type {Params}
     * @memberof RouteData
     */
    queryParams?: Params;

    /**
     * @description Index of the route (used for sidenav item order)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 08/09/2023
     * @type {number}
     * @memberof RouteData
     */
    index: number;

    /**
     * @description Type of the route
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 08/09/2023
     * @type {('primary' | 'secondary')}
     * @memberof RouteData
     */
    type: 'primary' | 'secondary';

    /**
     * @description Children routes if there is a sub navigtion(optional)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 08/09/2023
     * @type {RouteData[]}
     * @memberof RouteData
     */
    children?: RouteData[];

    /**
     * @description Full path with query parameters (optional) (default: false) (used for sidenav item active class)
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 26/11/2024
     * @type {string}
     * @memberof RouteData
     */
    fullPathWithQueryParams?: string;

    /**
     * @description Indicates if the path must be matched exactly (optional) (default: false) (used for sidenav item active class)
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 26/11/2024
     * @type {boolean}
     * @memberof RouteData
     */
    exactPathMatch?: boolean;

    /**
     * @description If the route is an omedom icon (optional) (default: false)
     * @author Brisset Killian
     * @date 15/06/2024
     * @type {boolean}
     * @memberof RouteData
     */
    isOmedomIcon?: boolean;
}
