import { Component, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { Mode, Story } from '@omedom/data';
import { OmedomChart } from '@omedom/utils';

@Component({
    selector: 'omedom-movement-card',
    templateUrl: './movement-card.component.html',
    styleUrls: ['./movement-card.component.scss'],
})
export class MovementCardComponent {
    @Input() story?: Story;

    @Input() isPropertyCard = false;

    @Input() isSocietyStory?: boolean;

    @Input() isBuildingStory?: boolean;

    @Input() futureCard?: boolean;

    @Input() mode: Mode = Mode.app;

    @Input() deactivateNoSmart?: boolean;

    @Output() storyClicked = new EventEmitter();

    colorByTreasury = OmedomChart.colorByTreasury;

    protected allMode = Mode;

    public now: Date = new Date();

    @HostListener('click')
    onClick(): void {
        this.storyClicked.next(null);
    }

    getColorByTreasury(category: string): string {
        return this.colorByTreasury[category as keyof typeof OmedomChart.colorByTreasury];
    }
}
