/**
 * @description DateInterval interface for date interval object
 * @author Didier Pascarel <didier.pascarel@omedom.com>
 * @date 17/11/2023
 * @export
 * @interface DateInterval
 */
export interface DateInterval {
    startDate: Date;
    endDate: Date;
}

/**
 * @description StringDateInterval interface for string date interval object
 * @author Killian Brisset <killian.brisset@omedom.com>
 * @date 18/11/2024
 * @export
 * @interface StringDateInterval
 */
export interface StringDateInterval {
    startDate: string;
    endDate: string;
}

/**
 * @description OmedomDateType enum for date type
 * @author Didier Pascarel <didier.pascarel@omedom.com>
 * @date 07/02/2023
 * @export
 * @enum {number}
 */
export enum OmedomFrenshMonth {
    'janvier',
    'février',
    'mars',
    'avril',
    'mai',
    'juin',
    'juillet',
    'août',
    'septembre',
    'octobre',
    'novembre',
    'décembre',
}
