<div class="propertiesFilter" (click)="presentSmartPopover($event)">
    @if (!canFilter) {
        <div class="smart">
            <i class="omedom-icon-crown"></i>
            <ion-popover
                #smartPopover
                class="omedom-popover"
                [isOpen]="isOpen"
                (didDismiss)="isOpen = false"
            >
                <ng-template>
                    <div class="omedom-popover-container">
                        <span
                            >Fonctionnalité Premium, changez d'abonnement.</span
                        >
                        <button
                            class="omedom-icon-button-primary"
                            (click)="smartPopover.dismiss()"
                            routerLink="/tabs/menu/subscription/manage"
                        >
                            <i class="omedom-icon-crown"></i>
                        </button>
                    </div>
                </ng-template>
            </ion-popover>
        </div>
    }
    @if (!disabled) {
        <div
            [ngClass]="{
                unlocked: canFilter,
                locked: !canFilter,
                'w-100': iconFilterToLeft
            }"
            (click)="openSelect()"
        >
            <div
                class="filter-icon"
                [ngClass]="{ 'float-left': iconFilterToLeft }"
            >
                <i class="uil uil-filter"></i>
                @if (iconFilterToLeft) {
                    <span class="margin-left-2">Filtrez vos biens :</span>
                }
            </div>
            <omedom-select
                name="bien"
                [hidden]="true"
                #selectApp
                (selectExited)="updateFilter()"
                [(ngModel)]="selectedAssets"
                [placeholder]="subjectPlaceholder"
                [options]="(propertyOptions$ | async) ?? []"
                [isMultiple]="true"
                [disabled]="!canFilter"
            ></omedom-select>
        </div>
    }

    <div class="property-slider">
        @if (showLabel) {
            <ng-container
                [ngTemplateOutlet]="propertySliderTrees"
                [ngTemplateOutletContext]="{
                    treesOption: allAssetsTreesOptions
                }"
            ></ng-container>
        } @else {
            <ng-container
                [ngTemplateOutlet]="propertySliderList"
            ></ng-container>
        }
    </div>
</div>

<ng-template #propertySliderList>
    @for (property of allPropertiesOptions; track property.id; let i = $index) {
        <omedom-property-filter-item
            id="{{ property.id }}"
            [asset]="property"
            [showLabel]="showLabel"
            [ngClass]="
                ((canFilter &&
                    (property.isAccesible || isBeforeTransitionDate)) ||
                    (!canFilter &&
                        !isBeforeTransitionDate &&
                        property.isAccesible)) &&
                !disabled
                    ? 'unlocked'
                    : 'locked'
            "
            (click)="propertySelected(property)"
        ></omedom-property-filter-item>
    }
</ng-template>

<ng-template #propertySliderTrees let-treesOption="treesOption">
    @for (treeOption of treesOption; track $index) {
        <div class="property-tree">
            @if (treeOption.level) {
                <div class="breadcrumb breadcrumb-{{ treeOption.level }}"></div>

                <div class="property-tree-level-{{ treeOption.level }}"></div>
            }
            <omedom-property-filter-item
                class="property-tree-item-level-{{ treeOption.level }}"
                id="{{ treeOption.parent.id }}"
                [asset]="treeOption.parent"
                [showLabel]="showLabel"
                [ngClass]="
                    ((canFilter &&
                        (treeOption.parent.isAccesible ||
                            isBeforeTransitionDate)) ||
                        (!canFilter &&
                            !isBeforeTransitionDate &&
                            treeOption.parent.isAccesible)) &&
                    !disabled
                        ? 'unlocked'
                        : 'locked'
                "
                (click)="propertySelected(treeOption.parent)"
            ></omedom-property-filter-item>
        </div>
        @if (treeOption.children && treeOption.children.length) {
            <ng-container
                *ngTemplateOutlet="
                    propertySliderTrees;
                    context: { treesOption: treeOption.children }
                "
            ></ng-container>
        }
    }
</ng-template>
