import { RestEntity } from './rest.entity';

export class PropertyContestEntity extends RestEntity {
    /**
     * @description The photo of property contest entity
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 10/12/2024
     * @type {string}
     * @memberof PropertyContestEntity
     */
    photo!: string;

    photoExtension!: string;

    photoName!: string;

    /**
     * @description The description of property contest entity
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 10/12/2024
     * @type {string}
     * @memberof PropertyContestEntity
     */
    description!: string;

    /**
     * @description The name of property contest entity
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 10/12/2024
     * @type {string}
     * @memberof PropertyContestEntity
     */
    name!: string;

    /**
     * @description The property UID for the contest
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 10/12/2024
     * @type {string}
     * @memberof PropertyContestEntity
     */
    propertyUID?: string | null;

    /**
     * @description The user UID of the user who submited the form for the contest
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 10/12/2024
     * @type {string}
     * @memberof PropertyContestEntity
     */
    userUID!: string;

    /**
     * @description Boolean to know if user send form for the contest
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 10/12/2024
     * @type {boolean}
     * @memberof PropertyContestEntity
     */
    isSubmitted?: boolean;

    /**
     * @description Boolean to know if form submitted by user has been send a email to admin
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 10/12/2024
     * @type {boolean}
     * @memberof PropertyContestEntity
     */
    hasEmailSent?: boolean;
}
