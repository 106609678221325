import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ModalController, NavController, ToastController } from '@ionic/angular';
import { ContactEntity, UserEntity } from '@omedom/data';
import { AnalyticsService, ContactService } from '@omedom/services';

import { elementAnimation } from '../../../animations';
import { AlertComponent } from '../../../components/alert/alert.component';

@Component({
    selector: 'omedom-help-contact',
    templateUrl: './help-contact.component.html',
    styleUrls: ['./help-contact.component.scss'],
    animations: [elementAnimation],
})
export class HelpContactComponent implements OnInit {
    /**
     * @description The current user information: for sending the email
     * @author Hanane Djeddal
     * @type {UserEntity}
     * @memberof HelpFeedbackComponent
     */
    @Input() public user?: UserEntity;

    /**
     * @description Event emitter for the submit button clicked event
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 16/10/2024
     * @memberof HelpContactComponent
     */
    @Output() public submitClicked = new EventEmitter();

    /**
     * @description the text message of the email
     * @author Hanane Djeddal
     * @type {string}
     * @memberof HelpFeedbackComponent
     */
    public message?: string;

    /**
     * @description subject of the email
     * @author Hanane Djeddal
     * @type {string}
     * @memberof HelpContactComponent
     */
    public subject?: string;

    /**
     * @description storing the email entity
     * @author Hanane Djeddal
     * @private
     * @type {Partial<ContactEntity>}
     * @memberof HelpFeedbackComponent
     */
    private email: Partial<ContactEntity> = {};

    constructor(
        private modalController: ModalController,
        private navController: NavController,
        private toast: ToastController,
        private contactService: ContactService,
        private analyticsService: AnalyticsService
    ) {}

    ngOnInit() {}

    ionViewDidEnter() {
        this.analyticsService.setCurrentScreen('Help contact Page');
    }

    async existClicked(label: string, icon: string, img: string | null) {
        const modal = await this.modalController.create({
            component: AlertComponent,
            initialBreakpoint: 1,
            breakpoints: [0, 1],
            canDismiss: true,
            componentProps: { title: label, avatar: icon, image: img },
        });

        modal.present();
    }

    back(): void {
        this.navController.pop();
    }

    public async submit(): Promise<void> {
        if (!this.user) {
            console.error('No user found');
            return;
        }
        this.email.senderEmail = this.user.email;
        this.email.userUID = this.user.uid;
        this.email.senderName = this.user.name;
        this.email.senderFirstName = this.user.firstname;
        this.email.message = this.message;
        this.email.sendto = 'serviceclient@omedom.com';
        this.email.subject = this.subject;
        try {
            await this.contactService.create(this.email);
            const toast = await this.toast.create({
                position: 'top',
                color: 'primary',
                duration: 4000,
                message: 'Votre message a bien été envoyé.',
            });

            await toast.present();
            this.submitClicked.emit();
            this.back();
        } catch (error) {
            console.error(error);
            const toast = await this.toast.create({
                position: 'top',
                color: 'danger',
                duration: 4000,
                message: "Une erreur s'est produite, veuillez réessayer plus tard.",
            });
            await toast.present();
        }
    }
}
