import { Observable } from "rxjs";
import { FormItemName } from "../enums";

export interface FormItem {
    id: FormItemName;
    icon: string;
    label: string;
    disabled?: boolean;
    isLocked$?: Observable<boolean>;
    isOmedomIcon?: boolean; // If true, the icon will be fetched from the omedom icon library
    type: 'primary' | 'secondary';
}
