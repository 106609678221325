import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

@Component({
    selector: 'omedom-security-password',
    templateUrl: './security-password.component.html',
    styleUrls: ['./security-password.component.scss'],
})
export class SecurityPasswordComponent implements OnChanges {
    /**
     * @description Password strength (0 to 5) to display a progress bar in the view
     * @author Jérémie Lopez
     * @memberof UserRegisterComponent
     */
    public passwordStrength = 0;
    numberOfCaracters?: number;
    hasNumber?: number;
    hasSpecialCaracters?: number;
    hasMajorLetter?: number;
    hasMinorLetter?: number;

    @Input()
    password: string = '';

    @Output()
    public passwordStrengthChange = new EventEmitter<number>();

    constructor() { }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes['password']) {
            this.setPasswordStrength();
        }
    }

    public get passwordStrengthExplicit(): string {
        if (this.passwordStrength < 3) {
            return 'Faible';
        } else if (this.passwordStrength < 5) {
            return 'Moyen';
        } else {
            return 'Fort';
        }
    }

    private setPasswordStrength(): void {
        this.numberOfCaracters =
            this.password.length >= 8 ? 1 : 0;
        this.hasNumber = this.password.match(/\d+/g)
            ? 1
            : 0;
        this.hasSpecialCaracters = this.password.match(/[!@#$%^&*(),.?":{}|<>-]/g)
            ? 1
            : 0;
        this.hasMajorLetter = this.password.match(/[A-Z]/g)
            ? 1
            : 0;
        this.hasMinorLetter = this.password.match(/[a-z]/g)
            ? 1
            : 0;

        this.passwordStrength =
            this.numberOfCaracters +
            this.hasNumber +
            this.hasSpecialCaracters +
            this.hasMajorLetter +
            this.hasMinorLetter;

        this.passwordStrengthChange.emit(this.passwordStrength);
    }
}
