/**
 * @description Utils for regex (email, phone, etc.) validation
 * @author Jérémie Lopez <jeremie.lopez@omedom.com>
 * @date 02/08/2023
 * @export
 * @class OmedomRegex
 */
export class OmedomRegex {
    /**
     * @description Regex for birthdate validation (dd/mm/yyyy format)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 02/08/2023
     * @static
     * @memberof Regex
     */
    public static readonly birthdateRegex = new RegExp(
        /^(0?[1-9]|[12][0-9]|3[01])[\/](0?[1-9]|1[012])[\/]\d{4}$/,
    );

    /**
     * @description Regex for postal code validation (5 digits format, no space allowed)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 02/08/2023
     * @static
     * @memberof Regex
     */
    public static readonly postalCodeRegex = new RegExp(/^\d{5}(\s)*$/);

    /**
     * @description Regex for phone validation (10 digits format, no space allowed)
     * @author Killian Brisset
     * @date 21/11/2023
     * @static
     * @memberof Regex
     */
    public static readonly phoneRegex = new RegExp(
        /^\+?\s?\(?\d{1,3}\)?\s?([\s\.\-]?\(\d{1,3}\)[\s\.\-]?)?(\d([\s\.\-]?)){4,11}\d$/,
    );

    /**
     * @description Regex for Brevo SMS validation (11 digits format, no space allowed, with or without country code)
     * @author Didier Pascarel <didier.pascarel@omedom.com>
     * @date 23/12/2024
     * @static
     * @memberof OmedomRegex
     */
    public static readonly brevoSMSRegex = new RegExp(/^((?:\+|00)(\d{2})|0)(\d{9})$/);

    /**
     * @description Regex for email validation (RFC 5322 Official Standard format, no space allowed)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 02/08/2023
     * @static
     * @memberof Regex
     */
    public static readonly emailRegex = '[a-z0-9._%+-]+@[a-z0-9.-]+.[a-z]{2,4}$';

    /**
     * @description Regex for password validation (8 characters minimum, 1 uppercase, 1 lowercase, 1 number, 1 special character, no space allowed)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 02/08/2023
     * @static
     * @memberof Regex
     */
    public static readonly passwordRegex = new RegExp(
        '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@-_#$%^&*])(?=.{8,})',
    );

    /**
     * @description Regex for SCI closing date validation (dd/mm format)
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 21/10/2024
     * @static
     * @memberof OmedomRegex
     */
    public static readonly closingDateRegex = new RegExp(
        /^(0[1-9]|[12][0-9]|3[01])[\/](0[1-9]|1[012])$/,
    );

    public static omedomFirebaseDeepLinksRegExps(appURL: string): RegExp[] {
        return [
            new RegExp(`^${appURL}\/links\/\\\?link=`), // links come from Brevo on iOS
            new RegExp(`^${appURL}(?!\/links*)`), // deeplinks on Android (or after fixed Brevo on iOS) exlude raw iOSdeeplinks iOS
            new RegExp(`^${appURL}\/links\/(?!\\\?link=)`), // raw deeplink on iOS
        ];
    }
}
