<div class="video-container">
    <omedom-action
        (actionClicked)="goToSubscription()"
        @element
        class="w-100 margin-top-1 margin-bottom-1"
        text="fonctionnalité PREMIUM, changez vite d'abonnement pour en profiter !"
        actionIcon="omedom-icon-crown"
    ></omedom-action>
    @if (video && video.iframeSource) {
        <iframe
            class="video margin-x-auto"
            [src]="video.iframeSource"
            title="{{ video.title }}"
            frameborder="0"
            allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen
        ></iframe>
    }
    <button
        class="omedom-button-primary margin-x-auto w-100 margin-top-1"
        (click)="goToSubscription()"
    >
        Changer d'abonnement
    </button>
</div>
