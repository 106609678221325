import { Component, ContentChild } from '@angular/core';
import { IonInput } from '@ionic/angular';
@Component({
    selector: 'omedom-show-hide-password',
    templateUrl: './show-hide-password.component.html',
    styleUrls: ['./show-hide-password.component.scss'],
})
export class ShowHidePasswordComponent {
    /**
     * @description If the password is visible or not (default: false)
     * @author Brisset Killian
     * @date 26/04/2024
     * @memberof ShowHidePasswordComponent
     */
    showPassword = false;

    /**
     * @description Input to toggle the password visibility on/off
     * @author Brisset Killian
     * @date 26/04/2024
     * @type {IonInput}
     * @memberof ShowHidePasswordComponent
     */
    @ContentChild(IonInput) input?: IonInput;

    constructor() {}

    /**
     * @description Toggle the password visibility on/off
     * @author Brisset Killian
     * @date 26/04/2024
     * @memberof ShowHidePasswordComponent
     */
    toggleShow() {
        this.showPassword = !this.showPassword;
        if (this.input) this.input.type = this.showPassword ? 'text' : 'password';
    }
}
