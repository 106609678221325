import { RestEntity } from './rest.entity';
import { SubscriptionAppleIDs, SubscriptionPrices } from './subscription.entity';

export class ConfigurationEntity extends RestEntity {
    /**
     * @description
     * @author Vidjay Seganti (Keleo)
     * @type {string}
     */
    public order?: number;

    /**
     * @description The name of subscription (Trial, Premium, etc.) of the configuration entity
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 19/11/2024
     * @type {string}
     * @memberof ConfigurationEntity
     */
    public name?: string;

    /**
     * @description
     * @author Vidjay Seganti (Keleo)
     * @type {string}
     */
    public subTitle?: string;

    /**
     * @description
     * @author Vidjay Seganti (Keleo)
     * @type {string}
     */
    public isActive?: boolean;

    /**
     * @description The limit of properties available
     * @author Didier Pascarel <didier.pascarel@omedom.com>
     * @date 19/06/2024
     * @type {number}
     * @memberof ConfigurationEntity
     */
    public propertyLimit?: number;

    /**
     * @description number of property that can be shared by the user (-1 for unlimited)
     * @author Vidjay Seganti (Keleo)
     * @type {number}
     */
    public propertyShare?: number;

    /**
     * @description Price list of the configuration
     * @author Vidjay Seganti (Keleo)
     * @type {number}
     */
    public price?: SubscriptionPrices;

    /**
     * @description array of key string that the user can access with this configuration
     * @author Vidjay Seganti (Keleo)
     * @type {string[]}
     */
    public canAccess?: string[];

    /**
     * @description color of the configuration
     * @author Vidjay Seganti (Keleo)
     * @type {string}
     */
    public color?: string;

    /**
     * @description Font color of the configuration entity
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 19/11/2024
     * @type {string}
     * @memberof ConfigurationEntity
     */
    public fontColor?: string;

    /**
     * @description Only for new user of the configuration entitys
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 19/11/2024
     * @type {boolean}
     * @memberof ConfigurationEntity
     */
    public isOnlyForNewUser?: boolean;

    /**
     * @description Only for desktop of the configuration entitys
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 19/11/2024
     * @type {boolean}
     * @memberof ConfigurationEntity
     */
    public isOnlyForDesktop?: boolean;

    /**
     * @description Id of the subscription on apple
     * @author Didier Pascarel <didier.pascarel@omedom.com>
     * @type {SubscriptionAppleIDs}
     */
    public appleIds?: SubscriptionAppleIDs;

    /**
     * @description Enable accessing object using string key
     * @author Vidjay Seganti (Keleo)
     * @type {any}
     */
    [key: string]: any;
}
