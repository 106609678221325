@for (chat of chats; track $index) {
    <omedom-chat-card
        [chat]="chat"
        [user]="user"
        (chatSelected)="chatSelected.emit($event)"
        [isSelected]="selectedChat?.chatEntity?.uid === chat.chatEntity.uid"
    ></omedom-chat-card>
} @empty {
    <p @element>Vous n'avez pas encore de conversation.</p>
}
