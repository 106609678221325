import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class AnalyticsService {
    //TODO avoir l'instance selon IOS/ANdroid, ou web, le getInstance de capacitor est available seuement pour mobile

    constructor() {}

    public async setUserId(userId: string) {
        /*await FirebaseAnalytics.setUserId({
            userId,
        });*/
    }

    public async setCurrentScreen(screenName: string) {
        /*await FirebaseAnalytics.setCurrentScreen({
            screenName: screenName,
        });*/
    }

    public async logEvent(eventName: string, ...params: Object[]) {
        /*await FirebaseAnalytics.logEvent({
            name: eventName,
            params: params,
        });*/
    }

    // public async setSessionTimeoutDuration(duration: number) {
    //     await FirebaseAnalytics.setSessionTimeoutDuration({
    //         duration: duration,
    //     });
    // }

    // This was analytics configure with firebase/FirebaseAnalytics

    //     analytics = getAnalytics();
    //     constructor() {}

    //     public async setUserId(userUid: string) {
    //         setUserId(this.analytics, userUid);
    //     }

    //     public async setCurrentScreen(screenName: string, screenClass?: string) {
    //         logEvent(this.analytics, 'screen_view', {
    //             firebase_screen: screenName,
    //             firebase_screen_class: screenClass,
    //         });
    //     }

    //     public async logEvent(eventName: string, ...params: Object[]) {
    //         logEvent(this.analytics, eventName, { parameters: params });
    //     }

    //     // public async setSessionTimeoutDuration(duration: number) {
    //     //     await FirebaseAnalytics.setSessionTimeoutDuration({
    //     //         duration: duration,
    //     //     });
    //     // }
}
