/* eslint-disable @angular-eslint/contextual-lifecycle */
import { DecimalPipe } from '@angular/common';
import { ElementRef, Injectable, Input, OnChanges, OnDestroy, Output, SimpleChanges, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import {
    AllChargeCategories,
    AllIncomeCategories,
    CategoryInfo,
    ChargeEntity,
    IncomeEntity,
    OmedomDateType,
    PropertiesFilter,
    PropertyEntity,
    SelectOption,
    SocietyEntity,
    UserEntity,
} from '@omedom/data';
import { PropertyService, RefreshService, SocietyService, UserService } from '@omedom/services';
import { OmedomChart, OmedomDate, OmedomTreasury } from '@omedom/utils';
import { ActiveElement, ChartData, ChartEvent, ChartOptions } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import { Subscription } from 'rxjs';
import { map } from 'rxjs/operators';

import { OmedomChartLegend } from '../components';

@Injectable()
export abstract class TreasuryBase<
        TEntity extends ChargeEntity | IncomeEntity,
        TCategory extends AllChargeCategories | AllIncomeCategories,
    >
    implements OnChanges, OnDestroy
{
    @ViewChild(BaseChartDirective) baseChart?: BaseChartDirective;

    /**
     * @description the payed portion of the graph
     * @author Hanane Djeddal
     * @type {ElementRef}
     * @memberof TreasuryBase
     */
    @ViewChild('payed') payed?: ElementRef;

    /**
     * @description not yet payed portion of the graph
     * @author Hanane Djeddal
     * @type {ElementRef}
     * @memberof TreasuryBase
     */
    @ViewChild('notPayed') notPayed?: ElementRef;

    @Output() hideSticker = false;
    @Output() alreadyClicGraph = false;

    @Input() typeOfChart: 'pie' | 'doughnut' = 'pie';

    chartData: ChartData<'pie' | 'doughnut'> = {
        labels: [],
        datasets: [{ data: [], backgroundColor: [] }],
    };

    chartOptions: ChartOptions<'pie' | 'doughnut'> = {
        animation: {
            animateScale: false,
        },
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                backgroundColor: 'white',
                cornerRadius: 10,
                caretSize: 0,
                bodyColor: '#04151c',
                bodyAlign: 'center',
                bodyFont: {
                    family: 'Asap',
                    size: 16,
                    lineHeight: 1.1,
                    weight: '700',
                },
                borderWidth: 1.5,
                displayColors: false,
                usePointStyle: true,
                padding: 10,
                callbacks: {
                    label: (context: any) => {
                        const labels: string[] = [];
                        const percent = this.decimalPipe.transform(
                            context.dataset.data[context.dataIndex],
                            '0.0-2',
                        );

                        labels.push(context.label);
                        labels.push(percent + '%');
                        return labels;
                    },
                },
            },
        },
        elements: {
            arc: {
                borderWidth: 3,
                borderRadius: 20,
                hoverBorderWidth: 1,
                borderColor: OmedomChart.whiteLight,
            },
        },
        onClick: (event: ChartEvent, elements: ActiveElement[]) => {
            this.alreadyClicGraph = true;
            return this.graphClick(event, elements);
        },
    };

    selectedProperties: SelectOption[] = [];

    selectedSocieties: SelectOption[] = [];

    chartLegends: OmedomChartLegend[] = [];

    totalAmount = 0;

    totalAmountPayed = 0;

    totalAmountNotPayed = 0;

    public displayAmount = 0;

    treasuryList: {
        categoryInfo: CategoryInfo<TCategory>;
        amount: number;
        percent: number;
        isPayed: boolean;
    }[] = [];

    payedNotpayedInfo: {
        label: string;
        color: string;
        amount: number;
        percent: number;
    }[] = [];

    omedomDateType = OmedomDateType;

    onlyPayed = false;

    onlyNotPayed = false;

    currentDate = new Date();

    public startDate = new Date().getUTCFirstDayOfMonth();

    public endDate = new Date().getUTCLastDayOfMonth();

    treasuryType?: string;

    private refreshSubscription?: Subscription;

    private subscription?: Subscription;

    private routerSubscription?: Subscription;

    private assetsSubscription?: Subscription;

    private currentUser?: UserEntity;

    private entities: TEntity[] = [];

    @Input()
    protected properties: PropertyEntity[] = [];

    @Input()
    protected societies: SocietyEntity[] = [];

    protected constructor(
        protected userService: UserService,
        protected decimalPipe: DecimalPipe,
        protected refreshService: RefreshService,
        protected route: ActivatedRoute,
        protected propertyService: PropertyService,
        protected societyService: SocietyService,
        protected router: Router,
    ) {}

    async ngOnChanges(changes: SimpleChanges): Promise<void> {
        this.selectedProperties = PropertiesFilter.getFilteredProperties();
        this.selectedSocieties = PropertiesFilter.getFilteredSocieties();

        if (changes['properties'] || changes['societies']) {
            this.selectedProperties = [];
            this.selectedSocieties = [];
        }

        this.refreshSubscription = this.refreshService.viewDidEnter$.subscribe(() =>
            this.updateData(),
        );

        const starDate$ = this.route.queryParams
            .pipe(map((params) => params['startDate']))
            .subscribe((date) => {
                if (date) {
                    this.startDate = new Date(date).toUTC().getUTCDateWithoutTime();
                    if (this.properties?.length > 0 || this.societies?.length > 0) {
                        this.updateGraphData();
                    }
                }
            });

        const endDate$ = this.route.queryParams
            .pipe(map((params) => params['endDate']))
            .subscribe((date) => {
                if (date) {
                    this.endDate = new Date(date).toUTC().getUTCDateWithoutTime();
                    if (this.properties?.length > 0 || this.societies?.length > 0) {
                        this.updateGraphData();
                    }
                }
            });

        this.subscription = this.userService.user$.subscribe(async (user) => {
            this.currentUser = user;
            this.updateData();
        });

        this.updateData();
    }

    ngOnDestroy(): void {
        this.refreshSubscription?.unsubscribe();
        this.subscription?.unsubscribe();
        this.routerSubscription?.unsubscribe();
        this.assetsSubscription?.unsubscribe();
    }

    startDateChange(date: Date) {
        this.startDate = date;
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: {
                startDate: OmedomDate.formatDateToISO(this.startDate),
            },
            queryParamsHandling: 'merge',
        });
    }

    endDateChange(date: Date) {
        this.endDate = date;
        this.router.navigate([], {
            relativeTo: this.route,
            queryParams: {
                endDate: OmedomDate.formatDateToISO(this.endDate),
            },
            queryParamsHandling: 'merge',
        });
    }

    toggleOnlyPayed() {
        this.onlyPayed = !this.onlyPayed;
        this.updateGraphData();
    }

    toggleOnlyNotPayed() {
        this.onlyNotPayed = !this.onlyNotPayed;
        this.updateGraphData();
    }

    /**
     * @description updates graph and data when the filter has been changed (through select app)
     */
    async updateFilter() {
        this.selectedProperties = PropertiesFilter.getFilteredProperties();
        this.selectedSocieties = PropertiesFilter.getFilteredSocieties();

        if (!this.currentUser) {
            return;
        }

        await this.loadTreasury(this.currentUser.uid).then((entities) => {
            this.entities = entities;
        });
        this.updateGraphData();
    }

    legendClicked(legend: OmedomChartLegend): void {
        const index = this.treasuryList.findIndex((x) => {
            const isIndex = x.categoryInfo.label === legend.label;
            return isIndex;
        });
        if (index >= 0) {
            this.navigateToDetail(this.treasuryList[index].categoryInfo.category);
        }
    }

    graphClick(event: ChartEvent, elements: ActiveElement[]): void {
        if (elements && !elements.length) {
            return;
        }
        // Vers le détail d'un type de charge ou revenu
        const element = this.treasuryList[elements[0].index];
        this.navigateToDetail(element.categoryInfo.category);
    }

    private updateData(): void {
        // const propSub$ = this.propertyService._getUserPropertiesAndSharedAccessible(user.uid);
        // const socSub$ = this.societyService._getUserSocietiesAndShared(user.uid);

        // this.assetsSubscription = combineLatest([propSub$, socSub$]).subscribe(
        //     ([properties, societies]) => {
        //         this.properties = properties;
        //         this.societies = societies;

        if (!this.currentUser) {
            return;
        }

        this.loadTreasury(this.currentUser.uid).then((entities) => {
            this.entities = entities;

            if (!this.startDate) {
                this.startDate = new Date().getUTCFirstDayOfMonth();
            }

            if (!this.endDate) {
                this.endDate = new Date().getUTCLastDayOfMonth();
            }

            this.entities = this.entities.filter((entity) => {
                const isPropertyExisting = this.properties.some(
                    (property) => property.uid === entity.propertyUID,
                );

                const isSocietyExisting = this.societies.some(
                    (society) => society.uid === entity.societyUID,
                );

                return isPropertyExisting || isSocietyExisting;
            });

            this.updateGraphData();
            // });
        });
    }

    private updateGraphData(): void {
        const startDate = this.startDate;
        const endDate = this.endDate;
        let currentDate = new Date(startDate).getUTCDateWithoutTime();

        let index = 0;
        let amount: {
            category: AllChargeCategories | AllIncomeCategories;
            amount: number;
            isPayed: boolean;
        }[] = [];
        while (
            currentDate.getUTCFirstDayOfMonth().getTime() <=
                endDate.getUTCFirstDayOfMonth().getTime() &&
            index < 1000
        ) {
            const currentStartDate = currentDate.getUTCFirstDayOfMonth();
            const currentEndDate = currentDate.getUTCLastDayOfMonth();

            const monthEntities = OmedomTreasury.filterTreasury<TEntity>(
                this.entities,
                currentStartDate,
                currentEndDate,
            );

            const monthAmount = OmedomTreasury.getByType<TEntity>(
                monthEntities,
                currentStartDate,
                currentEndDate,
            );

            amount = amount.concat(monthAmount);
            currentDate = currentDate.addUTCMonths(1);
            index++;
        }

        this.totalAmount = amount.sumBy((x) => x.amount);
        this.totalAmountPayed = amount.filter((x) => x.isPayed).sumBy((x) => x.amount);
        this.totalAmountNotPayed = amount.filter((x) => !x.isPayed).sumBy((x) => x.amount);

        switch (true) {
            case this.onlyPayed && !this.onlyNotPayed:
                this.displayAmount = this.totalAmountPayed;
                break;
            case this.onlyNotPayed && !this.onlyPayed:
                this.displayAmount = this.totalAmountNotPayed;
                break;
            default:
                this.displayAmount = this.totalAmount;
                break;
        }

        if (this.onlyPayed && !this.onlyNotPayed) {
            amount = amount.filter((x) => x.isPayed);
        }

        if (this.onlyNotPayed && !this.onlyPayed) {
            amount = amount.filter((x) => !x.isPayed);
        }

        // Pour calculer le %, on ne prend que le total filtré.
        const totalFilteredAmount = amount.sumBy((x) => x.amount);

        const treasuryListByType = amount.reduce(
            (
                tbt: {
                    [key: string]: {
                        category: AllChargeCategories | AllIncomeCategories;
                        amount: number;
                        isPayed: boolean;
                    }[];
                },
                treasury: {
                    category: AllChargeCategories | AllIncomeCategories;
                    amount: number;
                    isPayed: boolean;
                },
            ) => ({
                ...tbt,
                [this.transformCategoryToLabel(treasury.category).category]: [
                    ...(tbt[treasury.category] || []),
                    treasury,
                ],
            }),
            {},
        );

        const sortedTreasuryNames = Object.keys(treasuryListByType).sort((a, b) =>
            a.localeCompare(b),
        );

        if ((this.onlyPayed && !this.onlyNotPayed) || (this.onlyNotPayed && !this.onlyPayed)) {
            this.treasuryList = sortedTreasuryNames.map((treasuryName) => {
                const treasuryAmount = treasuryListByType[treasuryName].reduce(
                    (sum: number, x) => sum + x.amount,
                    0,
                );
                const treasuryIsPayed = treasuryListByType[treasuryName][0].isPayed;
                return {
                    categoryInfo: this.transformCategoryToLabel(treasuryName),
                    amount: Math.abs(treasuryAmount),
                    percent: Math.round((Math.abs(treasuryAmount) * 100) / totalFilteredAmount),
                    isPayed: treasuryIsPayed,
                };
            });
        } else {
            let payedTreasuryList: {
                categoryInfo: CategoryInfo<TCategory>;
                amount: number;
                percent: number;
                isPayed: boolean;
            }[] = [];
            let notPayedTreasuryList: {
                categoryInfo: CategoryInfo<TCategory>;
                amount: number;
                percent: number;
                isPayed: boolean;
            }[] = [];
            sortedTreasuryNames.forEach((treasuryName) => {
                // Payed treasury
                const payedTreasury = treasuryListByType[treasuryName].filter((x) => x.isPayed);
                if (payedTreasury.length > 0) {
                    const treasuryPayedAmount = payedTreasury.reduce((sum, x) => sum + x.amount, 0);
                    payedTreasuryList.push({
                        categoryInfo: this.transformCategoryToLabel(treasuryName),
                        amount: Math.abs(treasuryPayedAmount),
                        percent: Math.round(
                            (Math.abs(treasuryPayedAmount) * 100) / totalFilteredAmount,
                        ),
                        isPayed: true,
                    });
                }
                // Not payed treasury
                const notPayedTreasury = treasuryListByType[treasuryName].filter((x) => !x.isPayed);
                if (notPayedTreasury.length > 0) {
                    const treasuryNotPayedAmount = notPayedTreasury.reduce(
                        (sum, x) => sum + x.amount,
                        0,
                    );
                    notPayedTreasuryList.push({
                        categoryInfo: this.transformCategoryToLabel(treasuryName),
                        amount: Math.abs(treasuryNotPayedAmount),
                        percent: Math.round(
                            (Math.abs(treasuryNotPayedAmount) * 100) / totalFilteredAmount,
                        ),
                        isPayed: false,
                    });
                }
            });

            notPayedTreasuryList = notPayedTreasuryList.sort((a, b) =>
                b.categoryInfo.label > a.categoryInfo.label ? -1 : 1,
            );
            payedTreasuryList = payedTreasuryList.sort((a, b) =>
                b.categoryInfo.label > a.categoryInfo.label ? -1 : 1,
            );
            this.treasuryList = [...payedTreasuryList, ...notPayedTreasuryList];
        }

        this.hideSticker = !this.treasuryList.length;

        const colorsWithSaturation = this.treasuryList.map((x) => {
            const color = (
                OmedomChart.colorByTreasury as {
                    [key: string]: string;
                }
            )[x.categoryInfo.category];
            return x.isPayed ? color : OmedomChart.hatchColor(color);
        });

        this.chartData.labels = this.treasuryList.map((x) => x.categoryInfo.label);
        this.chartData.datasets[0].data = this.treasuryList.map((x) => x.percent);
        this.chartData.datasets[0].backgroundColor = colorsWithSaturation;
        this.chartData.datasets[0].hoverBackgroundColor = colorsWithSaturation;
        this.chartData.datasets[0].hoverBorderColor = colorsWithSaturation;

        if (
            this.typeOfChart === 'pie' &&
            (this.treasuryList.length === 0 || this.treasuryList.length === 1)
        ) {
            // Aucune charge ou revenu
            this.chartOptions = {
                ...this.chartOptions,
                elements: { arc: { borderWidth: 5, borderRadius: 0 } },
            };
        } else if (
            this.typeOfChart === 'doughnut' &&
            (this.treasuryList.length === 0 || this.treasuryList.length === 1)
        ) {
            // Une seule charge ou un seul revenu, le graphique est fermé
            this.chartOptions = {
                ...this.chartOptions,
                spacing: 0,
                elements: { arc: { borderWidth: 0, borderRadius: 0 } },
            };
        } else {
            // Plusieurs charges ou revenus
            this.chartOptions = {
                ...this.chartOptions,
                elements: {
                    arc: {
                        borderWidth: 3,
                        borderRadius: 20,
                        hoverBorderWidth: 3,
                        borderColor: OmedomChart.whiteLight,
                    },
                },
            };
        }

        if (this.typeOfChart === 'doughnut') {
            this.chartOptions = {
                ...this.chartOptions,
                cutout: '70%',
            };
        }

        this.chartLegends = this.treasuryList.map((x) => {
            const color = (
                OmedomChart.colorByTreasury as {
                    [key: string]: string;
                }
            )[x.categoryInfo.category];
            return new OmedomChartLegend({
                label:
                    // x.categoryInfo.category === 'rent'       // OM 955 - Can't open the detail page when clicking on the rent
                    //     ? 'Loyer perçu' :       // OM 955 - Can't open the detail page when clicking on the rent
                    x.categoryInfo.label,
                amount: Math.abs(x.amount),
                color: x.isPayed ? color : OmedomChart.hatchColor(color, 'legend').toString(),
            });
        });

        this.baseChart?.update();
    }

    abstract loadTreasury(userUid: string): Promise<TEntity[]>;

    abstract transformCategoryToLabel(category: string): CategoryInfo<TCategory>;

    abstract navigateToDetail(category: TCategory): void;
}
