<ng-container>
    @if ((rentOptions || []).length > 0) {
        <omedom-select
            [(ngModel)]="rentSelected"
            [placeholder]="rentPlaceholder"
            (ngModelChange)="onRentChange($event)"
            [disabled]="rents.length === 0"
            [required]="true"
            [options]="rentOptions || []"
        ></omedom-select>
    }

    @if (rents.length > 0) {
        <div class="text-center margin-top-2 margin-bottom-2">Ou</div>
    }

    <button class="omedom-button-primary w-100" (click)="addRent()">
        <i class="uil uil-plus"></i>
        <span>Ajouter un loyer</span>
    </button>
</ng-container>
@if (rent) {
    <h3 class="margin-top-2 margin-bottom-2">Loyer</h3>
    <div class="rent-card">
        <div class="header">
            <div class="title">
                <i class="uil uil-euro-circle margin-right-1"></i>
                <span
                    >Loyer
                    {{
                        rent?.designation ? '(' + rent.designation + ')' : null
                    }}</span
                >
            </div>
            <div class="price margin-right-1">
                <span>+ {{ rent?.amount }} €</span>
            </div>
            @if (roleState?.delete) {
                <div class="delete">
                    <i class="uil uil-trash-alt" (click)="removeRent()"></i>
                </div>
            }
        </div>
        <div class="content margin-top-1">
            <div class="periodicity">
                {{ periodicityLabel }}
            </div>
            <div class="spacer"></div>
        </div>
        <div class="footer margin-top-1">
            @if (property) {
                <div class="property">
                    @if (property.photo) {
                        <img
                            [src]="property.photo"
                            class="photo"
                            alt="Photo de la propriété"
                        />
                    }
                    @if (!property.photo) {
                        <div class="property-icon background-color-light-grey">
                            <i class="uil uil-home"></i>
                        </div>
                    }
                    <span class="property-title margin-left-1">{{
                        property.name
                    }}</span>
                </div>
            }
        </div>
    </div>
}
