export enum FormItemName {
    chargeAsset = 'charge-asset', // used for a charge type of asset
    incomeAsset = 'income-asset', // used for an income type of asset
    charge = 'charge',
    income = 'income',
    propertyBuilding = 'property-building', // used for a building type of property
    propertySociety = 'property-society', // used for property in a society
    property = 'property',
    society = 'society',
    document = 'document',
    documentProperty = 'document-property',
    documentSociety = 'document-society',
    loan = 'loan',
    lease = 'lease',
    saving = 'saving',
    bank = 'bank',
    share = 'share',
    multiShare = 'multi-share',
}
