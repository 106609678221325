import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalController, NavController, ToastController } from '@ionic/angular';
import {
    LoanDueDateForm,
    LoanEntity,
    LoanInfosFormType,
    OmedomStep,
    UserEntity,
} from '@omedom/data';
import { AnalyticsService, LoanService, SmartService, UserService } from '@omedom/services';
import { BehaviorSubject, Observable, Subscription } from 'rxjs';
import { elementAnimation, listAnimation } from '../../../animations';

@Component({
    selector: 'omedom-loan-form',
    templateUrl: './loan-form.container.html',
    styleUrls: ['./loan-form.container.scss'],
    animations: [listAnimation, elementAnimation],
})
export class LoanFormContainer implements OnInit, OnDestroy {
    /**
     * @description User data observable
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 03/05/2024
     * @type {UserEntity}
     * @memberof LoanFormComponent
     */
    public user?: UserEntity;

    /**
     * @description State of the page (pending, ok, error, etc.)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 03/05/2024
     * @memberof LoanFormComponent
     */
    public state$ = new BehaviorSubject<string>('pending');

    /**
     * @description Loan data
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 03/05/2024
     * @type {LoanEntity}
     * @memberof LoanFormComponent
     */
    @Input()
    public loan$?: Observable<LoanEntity>;

    /**
     * @description Loan data
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 03/05/2024
     * @type {LoanEntity}
     * @memberof LoanFormComponent
     */
    public loan?: LoanEntity;

    /**
     * @description List of subscriptions to unsubscribe when the component is destroyed
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 03/05/2024
     * @private
     * @type {Subscription[]}
     * @memberof LoanFormComponent
     */
    private subscriptions: Subscription[] = [];

    /**
     * @description List of steps for the stepper component in the form
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 06/05/2024
     * @type {OmedomStep[]}
     * @memberof LoanFormComponent
     */
    public steps: OmedomStep[] = [
        new OmedomStep({
            id: 'infos',
            label: 'Informations',
            canGoNext: () => {
                const form = this.getForm('infos');

                return form.valid;
            },
        }),
        new OmedomStep({
            id: 'dueDate',
            label: 'Échéances',
            canGoNext: () => {
                const form = this.getForm('dueDate');

                return form.valid || form.disabled;
            },
        }),
        new OmedomStep({
            id: 'confirmation',
            label: 'Confirmation',
            canGoNext: () => {
                return this.saveState$.value === 'ok';
            },
        }),
    ];

    /**
     * @description The selected step of the stepper component in the form
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 06/05/2024
     * @type {OmedomStep}
     * @memberof LoanFormComponent
     */
    public selectedStep: OmedomStep = this.steps[0];

    /**
     * @description List of forms for each step of the form in the page
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 06/05/2024
     * @type {FormGroup[]}
     * @memberof LoanFormComponent
     */
    public forms: { stepId: string; form: FormGroup }[] = [];

    /**
     * @description State of the save button (pending, ok, error, etc.)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 23/05/2024
     * @memberof LoanFormComponent
     */
    public saveState$ = new BehaviorSubject<string>('ok');

    /**
     * @description Observable to check if the user has a smart subscription or not using the smart service in the page
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 09/07/2024
     * @type {Observable<boolean>}
     * @memberof LoanFormComponent
     */
    public hasSmart$: Observable<boolean> = this.smartService.hasSmart$;

    /**
     * @description Label of the page depending on the loan data in the page
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 09/07/2024
     * @memberof LoanFormComponent
     */
    public label$ = new BehaviorSubject<string>('Chargement en cours...');

    /**
     * @description Demo loan data for the page
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 09/07/2024
     * @type {LoanEntity}
     * @memberof LoanFormComponent
     */
    public demo?: LoanEntity;

    constructor(
        private readonly userService: UserService,
        private readonly loanService: LoanService,
        private readonly route: ActivatedRoute,
        private readonly navController: NavController,
        private readonly modalController: ModalController,
        private readonly toastController: ToastController,
        private readonly router: Router,
        private readonly smartService: SmartService,
        private readonly analyticsService: AnalyticsService,
    ) {}

    ngOnInit(): void {
        this.subscriptions.push(
            this.userService.user$.subscribe((user) => {
                this.user = user;
            }),
        );

        this.getLoan();
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((sub) => sub.unsubscribe());
    }

    /**
     * @description Get the loan data from the database using the loan UID from the route params
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 03/05/2024
     * @private
     * @param {string} transactionUID
     * @memberof LoanFormComponent
     */
    private getLoan(): void {
        if (this.loan$) {
            const loan$ = this.loan$.subscribe((loan: LoanEntity | undefined) => {
                this.loan = loan;

                // Set the label of the page depending on the loan data
                if (loan) {
                    this.label$.next(loan.name);
                } else {
                    this.label$.next('Ajouter un crédit');
                }

                // Initialize the forms for each step of the form in the page
                this.initForms();

                this.state$.next('ok');
            });
            this.subscriptions.push(loan$);
        }
    }

    /**
     * @description Initialize the forms for each step of the form in the page
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 06/05/2024
     * @private
     * @memberof LoanFormComponent
     */
    private initForms(): void {
        const infosForm = new FormGroup<LoanInfosFormType>({
            name: new FormControl<string>(
                {
                    value: this.loan?.name || '',
                    disabled: false,
                },
                {
                    validators: [Validators.required, Validators.minLength(1)],
                    nonNullable: true,
                },
            ),
            bankID: new FormControl<number | undefined>(
                {
                    value: this.loan?.bankID,
                    disabled: this.loan?.accountID !== undefined,
                },
                {
                    nonNullable: true,
                },
            ),
            borrowedCapital: new FormControl<number | undefined>(
                {
                    value: this.loan?.borrowedCapital,
                    disabled: this.loan?.accountID !== undefined,
                },
                {
                    validators: [Validators.min(0)],
                    nonNullable: true,
                },
            ),
            repaidCapital: new FormControl<number | undefined>(
                {
                    value: this.loan?.repaidCapital,
                    disabled: this.loan?.accountID !== undefined,
                },
                {
                    validators: [Validators.min(0)],
                    nonNullable: true,
                },
            ),
            remainingCapital: new FormControl<number | undefined>(
                {
                    value: this.loan?.remainingCapital,
                    disabled: this.loan?.accountID !== undefined,
                },
                {
                    validators: [Validators.min(0)],
                    nonNullable: true,
                },
            ),
            interestRate: new FormControl<number | undefined>(
                {
                    value: this.loan?.interestRate,
                    disabled: this.loan?.accountID !== undefined,
                },
                {
                    validators: [Validators.min(0)],
                    nonNullable: true,
                },
            ),
            assurance: new FormControl<string | undefined>(
                {
                    value: this.loan?.assurance,
                    disabled: false,
                },
                {
                    nonNullable: true,
                },
            ),
            guarantee: new FormControl<string | undefined>(
                {
                    value: this.loan?.guarantee,
                    disabled: false,
                },
                {
                    nonNullable: true,
                },
            ),
            type: new FormControl<string | undefined>(
                {
                    value: this.loan?.type,
                    disabled: false,
                },
                {
                    nonNullable: true,
                },
            ),
        });

        const dueDateForm = new FormGroup<LoanDueDateForm>({
            openingDate: new FormControl<Date | undefined>(
                {
                    value: this.loan?.openingDate || new Date(),
                    disabled: this.loan?.accountID !== undefined,
                },
                {
                    nonNullable: true,
                },
            ),

            maturityDate: new FormControl<Date | undefined>(
                {
                    value: this.loan?.maturityDate || new Date(),
                    disabled: this.loan?.accountID !== undefined,
                },
                {
                    nonNullable: true,
                },
            ),

            nextPaymentDate: new FormControl<Date | undefined>(
                {
                    value: this.loan?.nextPaymentDate || new Date(),
                    disabled: this.loan?.accountID !== undefined,
                },
                {
                    nonNullable: true,
                },
            ),

            nextPaymentAmount: new FormControl<number | undefined>(
                {
                    value: this.loan?.nextPaymentAmount,
                    disabled: this.loan?.accountID !== undefined,
                },
                {
                    validators: [Validators.min(0)],
                    nonNullable: true,
                },
            ),
        });

        // Sync demo loan data one time
        this.syncDemo();

        // Sync demo loan data when the form changes
        this.subscriptions.push(
            infosForm.valueChanges.subscribe(() => {
                this.syncDemo();
            }),
            dueDateForm.valueChanges.subscribe(() => {
                this.syncDemo();
            }),
        );

        // Set the forms for each step of the form in the page
        this.forms = [
            { stepId: 'infos', form: infosForm },
            { stepId: 'dueDate', form: dueDateForm },
        ];
    }

    /**
     * @description Get the form for a specific step of the form in the page using the step ID as a parameter
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 06/05/2024
     * @param {string} stepId
     * @returns {FormGroup}
     * @memberof LoanFormComponent
     */
    public getForm(stepId: string): FormGroup {
        return this.forms.find((form) => form.stepId === stepId)?.form || new FormGroup({});
    }

    /**
     * @description Save loan data in the database using the loan service and the data from the form in the page
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 23/05/2024
     * @returns {Promise<void>}
     * @memberof LoanFormComponent
     */
    public async submit(): Promise<void> {
        // Prevent multiple clicks on the save button
        this.saveState$.next('pending');

        // Get the loan data from the form
        const loan = {
            ...this.loan,
            ...this.getForm('infos').value,
            ...this.getForm('dueDate').value,
        };

        // Remove undefined values
        Object.keys(loan).forEach((key) => loan[key] === undefined && delete loan[key]);

        // Save the loan in the database
        if (this.loan) {
            try {
                await this.loanService.update(loan);

                // Display a success toast
                const toast = await this.toastController.create({
                    message: 'Votre crédit a été mis à jour avec succès',
                    duration: 2000,
                    color: 'success',
                });

                await toast.present();
            } catch (error) {
                // Display an error toast
                const toast = await this.toastController.create({
                    message: 'Erreur lors de la mise à jour de votre crédit',
                    duration: 2000,
                    color: 'danger',
                });

                await toast.present();

                // Set the state of the save button to ok
                this.saveState$.next('ok');

                return;
            }
        } else {
            try {
                if (!this.user) {
                    throw new Error('User not found');
                }
                // Assign the loan to the user
                loan.userUID = this.user.uid;

                await this.loanService.create(loan);
                this.analyticsService.logEvent('Create Credit from desktop');

                // Display a success toast
                const toast = await this.toastController.create({
                    message: 'Votre crédit a été créé avec succès',
                    duration: 2000,
                    color: 'success',
                });

                await toast.present();
            } catch (error) {
                // Display an error toast
                const toast = await this.toastController.create({
                    message: 'Erreur lors de la création de votre crédit',
                    duration: 2000,
                    color: 'danger',
                });

                await toast.present();

                // Set the state of the save button to ok
                this.saveState$.next('ok');

                return;
            }
        }

        this.saveState$.next('ok');

        // Redirect to the previous page
        if (await this.modalController.getTop()) {
            await this.modalController.dismiss();
        } else {
            this.navController.back();
        }

        return;
    }

    /**
     * @description Go to the smart subscription page using the router service
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 09/07/2024
     * @memberof LoanFormComponent
     */
    public goToSubscription(): void {
        this.router.navigate(['/menu'], { queryParams: { menu: 'subscription' } });
    }

    /**
     * @description Synchronize the demo loan data with the form data in the page using the loan entity class from the data library in the page
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 09/07/2024
     * @private
     * @memberof LoanFormComponent
     */
    private syncDemo(): void {
        // Merge loan and forms
        const merge = {
            ...this.loan,
            ...this.getForm('infos').value,
            ...this.getForm('dueDate').value,
        };

        this.demo = new LoanEntity(merge);
    }
}
