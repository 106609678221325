import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ActivatedRoute, Data, Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import {
    AllChargeCategories,
    ChargeCategoryInfo,
    ChargeEntity,
    ChargeListModel,
    ChargePeriodicity,
    ChargePeriodicityInfo,
    defaultRoleState,
    EntityTypes,
    PropertyType,
    RoleState,
} from '@omedom/data';
import {
    AnalyticsService,
    ChargeService,
    PropertyService,
    RoleService,
    SocietyService,
    UserService,
} from '@omedom/services';
import { OmedomStory } from '@omedom/utils';
import { BehaviorSubject, combineLatest, map, Observable, of, switchMap } from 'rxjs';

import { elementAnimation, listAnimation, transactionAnimation } from '../../animations';
import { TreasuryList } from '../../class/treasury-list';
import { ChargeDeleteComponent } from '../../components/charge-delete/charge-delete.component';
import { StoryDisplayComponent } from '../story-display/story-display.component';
import { TreasuryEditComponent } from '../treasury-edit/treasury-edit.component';

@Component({
    selector: 'omedom-charge-list',
    templateUrl: './charge-list.component.html',
    styleUrls: ['./charge-list.component.scss'],
    animations: [listAnimation, transactionAnimation, elementAnimation],
})
export class ChargeListComponent extends TreasuryList<
    ChargeEntity,
    AllChargeCategories,
    ChargePeriodicity
> {
    @Input() isChargeForAllProperty?: boolean;
    /**
     * @description: title of the page: "Charge du mois" when in property; "Charges globales" when in treasury
     * @author: Hanane Djeddal.
     * @type {string}
     * @memberof ChargeListComponent
     */
    label?: string;

    /**
     * @description Label if the component is used in a modal
     * @author Brisset Killian
     * @date 07/06/2024
     * @type {string}
     * @memberof ChargeListComponent
     */
    @Input() modalLabel?: string;

    /**
     *@description: Only show property avatar card when in treasury
     * @author: Hanane Djeddal.
     * @type {boolean}
     * @memberof ChargeListComponent
     */
    avatar?: boolean;

    /**
     * @description Previous url to assign to the back button
     * @author Jérémie Lopez
     * @type {string}
     * @memberof ChargeListComponent
     */
    previousUrl: string;

    roleRight = defaultRoleState as RoleState;

    override propertyUid: string;
    override societyUid?: string;
    isSociety: boolean;
    canCreateCharge = false;

    @Input()
    showAllCharges = true;

    @Input()
    charges$: BehaviorSubject<ChargeEntity[]> = new BehaviorSubject<ChargeEntity[]>([]);

    @Output()
    treasuryEditEvent: EventEmitter<{
        isCharge: boolean;
        currentDate: Date;
        treasuryUid: string;
        updateType: number;
    }> = new EventEmitter();

    public override isDesktop: boolean = true;

    constructor(
        userService: UserService,
        propertyService: PropertyService,
        activatedRoute: ActivatedRoute,
        modalController: ModalController,
        router: Router,
        societyService: SocietyService,
        private route: ActivatedRoute,
        private roleService: RoleService,
        private chargeService: ChargeService,
        private routerComp: Router,
        private analyticsService: AnalyticsService,
    ) {
        super(
            userService,
            propertyService,
            activatedRoute,
            modalController,
            router,
            societyService,
        );

        const segment = this.routerComp.url.split('/')[2];
        this.isSociety = this.routerComp.url.split('/')[3] === 'society';
        this.propertyUid = this.routerComp.url.split('/')[4];

        if (segment === 'treasury') {
            this.previousUrl = '/tabs/treasury';
        } else {
            if (this.isSociety) {
                this.societyUid = this.routerComp.url.split('/')[5];
                this.previousUrl = '/tabs/property/society/info/' + this.societyUid;
            } else {
                this.previousUrl = '/tabs/property/info/' + this.propertyUid;
            }
        }
    }

    override async ngOnInit(): Promise<void> {
        super.ngOnInit();
        await this.manageRoleState();
    }

    override async ionViewWillEnter() {
        super.ionViewWillEnter();
    }
    ionViewDidEnter(): void {
        this.analyticsService.setCurrentScreen('Charge List Page');
    }

    private async manageDataSubscription(data: Data) {
        if (data['isChargeForAllProperty']) {
            this.canCreateCharge = true;
        } else {
            await this.manageRoleState();
        }
    }
    private async manageRoleState() {
        if (this.isSociety) {
            await this.setRoleStateOfSociety();
        } else {
            await this.setRoleStateOfProperty();
        }
        this.canCreateCharge = this.roleRight?.create ?? false;
    }

    private async setRoleStateOfProperty() {
        const property = await this.propertyService.get(this.propertyUid);
        if (property) {
            this.roleRight = await this.roleService.getRoleState(property, EntityTypes.property);
        }
    }
    private async setRoleStateOfSociety() {
        if (!this.societyUid) {
            return;
        }
        const society = await this.societyService.get(this.societyUid);
        if (society) {
            this.roleRight = await this.roleService.getRoleState(society, EntityTypes.property);
        }
    }

    async deleteClicked(chargeListModel: ChargeListModel): Promise<void> {
        await this.showDeleteComponent(ChargeDeleteComponent, chargeListModel);
    }

    getCategoryInfo(category: string): ChargeCategoryInfo {
        return new ChargeCategoryInfo(category as AllChargeCategories);
    }

    getPeriodicityInfo(periodicity: string): ChargePeriodicityInfo {
        return new ChargePeriodicityInfo(periodicity as ChargePeriodicity);
    }

    loadTreasury(): Observable<ChargeEntity[]> {
        // let charges: Promise<ChargeEntity[]>;

        this.label = this.propertyUid || this.societyUid ? 'Charges du mois' : 'Charges globales';
        this.avatar = true;

        if (this.charges$?.value.length > 0) {
            return this.charges$.asObservable();
        }

        if (!!this.propertyUid) {
            return this.propertyService._get(this.propertyUid).pipe(
                switchMap((currentProperty) => {
                    this.avatar = currentProperty?.type === PropertyType.immeuble;
                    if (
                        (currentProperty?.lotsUID && currentProperty.lotsUID.length > 0) ||
                        currentProperty?.type === PropertyType.immeuble
                    ) {
                        return this.chargeService.getChargesByBuildingsLots(currentProperty);
                    }
                    return this.chargeService._getChargesByProperty(this.propertyUid);
                }),
            );
        }
        if (!!this.societyUid) {
            this.avatar = true;
            return this.chargeService._getChargesBySociety(this.societyUid);
        }
        if (this.showAllCharges) {
            const propertiesCharges = this.chargeService._getUserChargesByPropertyIds(
                this.properties.map((property) => property.uid),
            );
            const societyCharges = this.chargeService._getUserChargesBySocietyIds(
                this.societies.map((society) => society.uid),
            );
            return combineLatest([propertiesCharges, societyCharges]).pipe(
                map(([propertiesCharges, societyCharges]) => {
                    return [...propertiesCharges, ...societyCharges];
                }),
            );
        }
        return of([]);
    }

    addClicked(): void {
        if (this.propertyUid) {
            this.routerComp.navigate([`/tabs/property/info/${this.propertyUid}/charge/form`], {
                queryParams: {
                    list: 'true',
                },
            });
        } else {
            this.routerComp.navigate(['/tabs/treasury/charge/form'], {
                queryParams: {
                    list: 'true',
                },
            });
        }
    }
    /**
     * @description Open modal for a story
     * @author ANDRE Felix
     * @param {ChargeListModel} chargeList
     * @returns {*}  {Promise<void>}
     * @memberof ChargeListComponent
     */
    async chargeClicked(chargeList: ChargeListModel): Promise<void> {
        this.analyticsService.logEvent('Story opened from charge list');
        const storyModel = OmedomStory.transformTreasuryModelListToStory(chargeList, true);
        const modal = await this.showStoryModal(StoryDisplayComponent, storyModel);
        modal.onDidDismiss().then(async (callback) => {
            if (
                callback.data?.updateType &&
                callback.data?.treasuryUid &&
                callback.data?.currentDate
            ) {
                if (await this.modalController.getTop()) {
                    const editModal = await this.modalController.create({
                        component: TreasuryEditComponent,
                        initialBreakpoint: 1,
                        breakpoints: [0, 1],
                        canDismiss: true,
                        componentProps: {
                            updateType: callback.data.updateType,
                            treasuryUid: callback.data.treasuryUid,
                            currentDate: callback.data.currentDate,
                            isCharge: callback.data.isCharge,
                        },
                        cssClass: 'max-heigth-mode',
                    });

                    await editModal.present();

                    await editModal.onDidDismiss().then(() => {
                        this.updateData();
                    });
                } else {
                    this.treasuryEditEvent.emit({
                        isCharge: callback.data.isCharge ?? false,
                        currentDate: callback.data.currentDate,
                        treasuryUid: callback.data.treasuryUid,
                        updateType: callback.data.updateType,
                    });
                }
            } else {
                this.updateData();
            }
        });
    }

    getChargeInfo(propertyUID: string): {
        icon: string;
    } {
        const property = this.properties.find((p) => p.uid === propertyUID);
        const society = this.societies.find((s) => s.uid === propertyUID);
        let icon = 'uil uil-home';
        if (property) {
            icon = property.type === PropertyType.immeuble ? 'uil uil-building' : 'uil uil-home';
        } else if (society) {
            icon = 'uil uil-suitcase';
        }
        return {
            icon,
        };
    }
}
