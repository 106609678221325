import { Component, Input, OnChanges, SimpleChanges } from '@angular/core';
import { ActivityZone, departmentByRegion, ProEntity, ProValue } from '@omedom/data';
import { OmedomPro } from '@omedom/utils';

@Component({
    selector: 'omedom-pro-record-detail',
    templateUrl: './pro-record-detail.component.html',
    styleUrls: ['./pro-record-detail.component.scss'],
})
export class ProRecordDetailComponent implements OnChanges {
    /**
     * @description Pro entity to display in the component
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 15/10/2024
     * @type {ProEntity}
     * @memberof ProRecordDetailComponent
     */
    @Input({ required: true }) pro!: ProEntity;

    // For the moment, we hide these values for user, but still create in the pro
    // proExpertiseField: ExpertiseField | string = '';

    /**
     * @description Activity zone of the pro (can be multiple) to display in the component
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 15/10/2024
     * @type {(ActivityZone | string)}
     * @memberof ProRecordDetailComponent
     */
    proActivityZone: ActivityZone | string = '';

    /**
     * @description Values of the pro (can be multiple) to display in the component
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 15/10/2024
     * @type {(ProValue | string)}
     * @memberof ProRecordDetailComponent
     */
    proValues: ProValue | string = '';

    /**
     * @description Activity zones of the pro to display in the component (enum)
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 15/10/2024
     * @memberof ProRecordDetailComponent
     */
    activityZones = ActivityZone;

    /**
     * @description Get the website of the pro without protocol and www. (if there is any) and without slash at the end (if there is any)
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 15/10/2024
     * @readonly
     * @type {(string | undefined)}
     * @memberof ProRecordDetailComponent
     */
    public get proWebsite(): string | undefined {
        // Regex to remove protocol
        const protocolRegex = /^(http|https):\/\//;

        // Regex to remove www.
        const websiteRegex = /^(www\.)/;

        // Regex to remove slash after the domain name if there is nothing after
        const slashAfterDomainRegex = /^(https?:\/\/)?(www\.)?([^\/]+)\/$/;

        const siteWithoutProtocolAndWWW = this.pro.website
            ?.replace(protocolRegex, '')
            .replace(websiteRegex, '')
            .replace(slashAfterDomainRegex, '$3');

        return siteWithoutProtocolAndWWW;
    }

    /**
     * @description Get the departments of the pro
     * @author Killian Brisset <killian.brisset@omedom.com>
     * @date 15/10/2024
     * @readonly
     * @type {(string)}
     * @memberof ProRecordDetailComponent
     */
    public get proDepartments(): string {
        if (!this.pro || !this.pro.departmentCodes || this.pro.departmentCodes.length === 0) {
            return '-';
        }
        const departmentByRegionKeys = Object.keys(departmentByRegion);
        const departments = departmentByRegionKeys.map((region) => {
            if (!this.pro.departmentCodes) {
                return [];
            }
            const departmentsOfRegion = departmentByRegion[region];
            // if all departments in region are selected
            if (
                this.pro.departmentCodes.length === departmentsOfRegion.length &&
                this.pro.departmentCodes.every((dep) =>
                    departmentsOfRegion.find((d) => d.code === dep)
                )
            ) {
                return [region];
            }

            return this.pro.departmentCodes
                .map((departmentCode) => {
                    const departmentFind = departmentByRegion[region].find(
                        (dep) => dep.code === departmentCode
                    );
                    if (departmentFind) {
                        return `${departmentFind.name} (${departmentFind.code})`;
                    }
                    return null;
                })
                .filter((dep) => dep !== null) as string[];
        });
        return departments.flat().join(', ');
    }

    constructor() {}

    ngOnChanges(changes: SimpleChanges) {
        if (changes['pro'] && changes['pro'].currentValue) {
            // this.proExpertiseField = this.setProExpertiseField(this.pro);
            this.proActivityZone = this.setProZone(this.pro);
            this.proValues = OmedomPro.proValuestoString(this.pro);
        }
    }

    setProZone(pro: ProEntity | undefined) {
        if (!pro || !pro.activityZone || pro.activityZone.length === 0) {
            return '';
        }
        if (pro.activityZone.length === 1) {
            return pro.activityZone[0];
        }
        return pro.activityZone.join(', ');
    }
}
