import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ClientEntity, UserEntity } from '@omedom/data';

import { elementAnimation } from '../../animations';
import { Chat } from '../../components/chat-card/chat-card.component';

@Component({
    selector: 'omedom-chat-list',
    templateUrl: './chat-list.component.html',
    styleUrls: ['./chat-list.component.scss'],
    animations: [elementAnimation],
})
export class ChatListComponent {
    @Input() clients: ClientEntity[] = [];

    @Input() chats: Chat[] = [];

    @Input() selectedChat?: Chat;

    @Input() user?: UserEntity;

    @Output() chatSelected = new EventEmitter<Chat>();

    constructor() {}
}
