@if (story) {
    <div
        class="omedom-card padding-1"
        [class.payed]="story.isPayed && story.date.getTime() <= now.getTime()"
        [class.notPayed]="
            !story.isPayed && story.date.getTime() <= now.getTime()
        "
        [class.future]="story.date.getTime() > now.getTime()"
        (click)="
            !canManageRental && this.isOwner
                ? openModaSuggestSubscription()
                : null
        "
    >
        <div class="container flex-1">
            <div
                class="info-property flex-1 clickable"
                (click)="propertyClicked()"
            >
                @if (story.propertyImg) {
                    <img
                        class="margin-right-1"
                        [src]="story.propertyImg"
                        alt="Photo du bien"
                    />
                } @else {
                    <div class="icon margin-right-1">
                        <i class="uil uil-home"></i>
                    </div>
                }
                <div class="property-title">
                    <strong class="property-name">
                        <i
                            [ngClass]="{
                                'uil uil-share-alt ng-star-inserted': !isOwner
                            }"
                        >
                        </i>
                        {{ property?.name }}
                    </strong>
                    @if (society) {
                        <p class="society-name">
                            {{ society.name }}
                        </p>
                    }
                </div>
                <strong class="amount-value margin-left-1">
                    +&nbsp;{{ story.amount | currency: 'EUR' }}
                </strong>
            </div>
        </div>
        <!-- @if (receiptRelaunchButtonVisible) { // this condition isn't used anymore because of popover messages in case of missig preriquises -->
        <div class="actions flex-1 margin-top-1">
            <button
                class="omedom-button-primary flex-1"
                [ngClass]="
                    receiptRelaunchButtonDisabled ? 'disable-cursor' : ''
                "
                [disabled]="receiptRelaunchButtonDisabled"
                (click)="onClickPDF()"
            >
                @if (!story.isPayed) {
                    <i class="uil uil-file-exclamation"></i>
                }
                @if (story.isPayed) {
                    <img
                        [src]="
                            '/assets/icon/quittance-' +
                            (receiptRelaunchButtonDisabled
                                ? 'disabled'
                                : 'primary') +
                            '.svg'
                        "
                        alt="Quittance"
                    />
                }
                <span>
                    Générer une
                    {{ story.isPayed ? 'quittance' : 'lettre de relance' }}
                </span>
            </button>
            <!-- in case of missing prerquises we explain here to the user why and what to do -->
            @if (
                receiptRelaunchButtonDisabled && (canManageRental || !isOwner)
            ) {
                <omedom-popover
                    class="margin-left-1"
                    [content]="helpModalProps.message"
                    icon="uil uil-exclamation-triangle"
                    [withIcon]="true"
                    iconColor="yellow"
                    [redirectionButton]="helpModalProps.redirectionButton"
                    [displayButton]="displayPopoverButton"
                >
                </omedom-popover>
            }
            @if (!canManageRental && isOwner) {
                <i class="omedom-icon-crown margin-left-1"></i>
            }
        </div>
        <!-- } -->
    </div>
    <div class="state margin-left-1">
        @if (story.date.getTime() > now.getTime()) {
            <i class="uil uil-question-circle color-grey"></i>
        }
        @if (story.isPayed && story.date.getTime() <= now.getTime()) {
            <i class="uil uil-check-circle color-green"></i>
        }
        @if (!story.isPayed && story.date.getTime() <= now.getTime()) {
            <i class="uil uil-times-circle color-red"></i>
        }
    </div>
}
