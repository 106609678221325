import { Component, OnInit } from '@angular/core';
import { TutorialVideoEntity, TutorialVideoName } from '@omedom/data';
import { SanitizerService, TutorialVideoService } from '@omedom/services';

@Component({
    selector: 'omedom-bank-empty-tuto-video',
    templateUrl: './bank-empty-tuto-video.component.html',
    styleUrls: ['./bank-empty-tuto-video.component.scss'],
})
export class BankEmptyTutoVideoComponent implements OnInit {
    bankTutorialVideo?: TutorialVideoEntity;

    constructor(
        private readonly sanitizerService: SanitizerService,
        private readonly tutorialVideoService: TutorialVideoService,
    ) {}

    async ngOnInit() {
        await this.getTutorialVideo();
    }

    private async getTutorialVideo() {
        // TODO: get the banking video, for the moment it doesn't exist on youtube
        this.bankTutorialVideo = await this.tutorialVideoService.getYoutubeVideoByName(
            TutorialVideoName.bank,
        );
    }
}
