@if (treasury) {
    <div class="margin-bottom-2 main-card flex-1 w-100">
        <div
            class="omedom-card padding-1 flex-1"
            [class.payed]="treasury.isPayed"
            [class.not-payed]="!treasury.isPayed && treasury.date < now"
            (click)="treasuryClicked.emit(treasury)"
        >
            <div class="info flex-1">
                <div class="info-category">
                    <i
                        class="margin-right-1"
                        [class]="treasury.categoryInfo.icon"
                    ></i>
                    <strong class="category-name">{{
                        treasury.categoryInfo.label
                    }}</strong>
                </div>
                @if (avatar) {
                    <div class="info-property">
                        @if (image) {
                            <img [src]="image" alt="property photo" />
                        } @else {
                            <i [class]="icon"></i>
                        }
                        <em class="property-name">{{ name }}</em>
                        @if (currentUserUID !== treasury.userUID) {
                            <i class="uil uil-share-alt"></i>
                        }
                    </div>
                }
                @if (treasury.notes) {
                    <span class="info-notes">
                        <em>{{ treasury.notes }}</em>
                    </span>
                }
                @if (treasury.designation) {
                    <span class="info-designation">
                        <em
                            ><strong>{{ treasury.designation }}</strong></em
                        >
                    </span>
                }
            </div>
            <div class="amount margin-left-1">
                <strong class="amount-value">
                    {{ isCharge ? '-' : ''
                    }}{{
                        treasury.amount
                            | number
                                : (treasury.amount <= 10000
                                      ? ('0.2-2' | omedomNumber)
                                      : ('0.0-0' | omedomNumber))
                    }}&nbsp;€
                </strong>
                <span class="amount-periodicity">
                    <em>{{ treasury.periodicityInfo.label }}</em>
                </span>
            </div>
        </div>
        @if (treasury.date < now) {
            <div class="state margin-left-1">
                <ng-container
                    *ngTemplateOutlet="
                        treasury.isPayed
                            ? payedIconTempalate
                            : notPayedIconTempalate
                    "
                >
                </ng-container>
                <ng-template #payedIconTempalate>
                    <i class="uil uil-check-circle color-green"></i>
                </ng-template>
                <ng-template #notPayedIconTempalate>
                    <i class="uil uil-times-circle color-red"></i>
                </ng-template>
            </div>
        }
    </div>
}
