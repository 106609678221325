<svg
    width="320"
    id="map"
    height="612"
    viewBox="0 0 320 612"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
>
    <path
        d="
     M 261.5 611
     C 264.261 611 266.5 608.761 266.5 606
     C 266.5 603.239 264.261 601 261.5 601
     L 56.5 601
     C 31.6472 601 11.5 580.853 11.5 556
     C 11.5 531.147 31.6472 511 56.5 511
     L 263 511
     C 263.084 511 263.167 510.998 263.25 510.994
     C 263.333 510.998 263.416 511 263.5 511
     C 293.876 511 318.5 486.376 318.5 456
     C 318.5 425.624 293.876 401 263.5 401
     L 56.5 401
     C 31.6472 401 11.5 380.853 11.5 356
     C 11.5 331.147 31.6472 311 56.5 311
     L 263.5 311
     C 293.876 311 318.5 286.376 318.5 256
     C 318.5 225.624 293.876 201 263.5 201
     L 56.5 201
     C 31.6472 201 11.5 180.853 11.5 156
     C 11.5 131.147 31.6471 111 56.5 111
     L 263.5 111
     C 293.876 111 318.5 86.3757 318.5 56
     C 318.5 25.6243 293.876 1 263.5 1
     L 159 1.00001
     C 156.239 1.00001 154 3.2386 154 6.00001
     C 154 8.76143 156.239 11 159 11
     L 263.5 11
     C 288.353 11 308.5 31.1472 308.5 56
     C 308.5 80.8528 288.353 101 263.5 101
     L 56.5 101
     C 26.1243 101 1.49996 125.624 1.49996 156
     C 1.49996 186.376 26.1243 211 56.5 211
     L 263.5 211
     C 288.353 211 308.5 231.147 308.5 256
     C 308.5 280.853 288.353 301 263.5 301
     L 56.5 301
     C 26.1243 301 1.49998 325.624 1.49998 356
     C 1.49998 386.376 26.1243 411 56.5 411
     L 263.5 411
     C 288.353 411 308.5 431.147 308.5 456
     C 308.5 480.853 288.353 501 263.5 501
     C 263.416 501 263.333 501.002 263.25 501.006
     C 263.167 501.002 263.084 501 263 501
     L 56.5 501
     C 26.1243 501 1.49999 525.624 1.5 556
     C 1.5 586.376 26.1243 611 56.5 611
     L 261.5 611
     Z"
        fill="#F5F5F5"
        stroke="#EEEEEE"
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
    />
    <path
        #path
        d="
            M 261.25 605
            H 55
            C 27.3858 605 5 582.614 5 555
            C 5 527.386 27.3858 505 55 505
            H 261.25
            C 288.864 505 311.25 482.614 311.25 455
            C 311.25 427.386 288.864 405 261.25 405
            H 55
            C 27.3858 405 5 382.614 5 355
            C 5 327.386 27.3858 305 55 305
            H 261.25
            C 288.864 305 311.25 282.614 311.25 255
            C 311.25 227.386 288.864 205 261.25 205
            H 55
            C 27.3858 205 5 182.614 5 155
            C 5 127.386 27.3858 105 55 105
            H 261.25
            C 288.864 105 311.25 82.6142 311.25 55
            C 311.25 27.3858 288.864 5 261.25 5
            H 158
          "
        stroke="#0076c8"
        id="path"
        stroke-linecap="round"
        stroke-linejoin="round"
    />
</svg>

@if (user && user.dom) {
    <div class="level-map-grid">
        @for (level of levelMap; track level.name; let i = $index) {
            <div class="level-map-grid-cell" [style.gridArea]="level.gridArea">
                <span class="level-icon-text">{{ level.order }}</span>
                <div
                    class="level-icon-border"
                    [style.backgroundColor]="
                        getNextLevel(user.dom) > level.order
                            ? '#00c29a'
                            : '#fff'
                    "
                    [style.borderColor]="
                        getNextLevel(user.dom) >= level.order
                            ? '#00c29a'
                            : '#eee'
                    "
                >
                    <div
                        class="level-icon"
                        [style.cursor]="
                            getEventsForLevel(level.order).length > 0
                                ? 'pointer'
                                : 'default'
                        "
                        [id]="level.order"
                        [style.backgroundColor]="
                            getNextLevel(user.dom) >= level.order
                                ? '#04151C'
                                : '#a8adaf'
                        "
                        [style.mask]="getLevelMask(level.order)"
                        [style.-webkit-mask]="getLevelMask(level.order)"
                    ></div>
                </div>
                <span class="level-icon-text">{{ level.name }}</span>
                @if (getEventsForLevel(level.order).length > 0) {
                    <ion-popover
                        [trigger]="level.order"
                        [showBackdrop]="false"
                        [keepContentsMounted]="true"
                        side="top"
                        alignment="center"
                        class="level-trophy-popover"
                    >
                        <ng-template>
                            <ion-content>
                                <svg
                                    width="20"
                                    height="20"
                                    viewBox="0 0 20 20"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M19 2H16V1C16 0.734784 15.8946 0.48043 15.7071 0.292893C15.5196 0.105357 15.2652 0 15 0H5C4.73478 0 4.48043 0.105357 4.29289 0.292893C4.10536 0.48043 4 0.734784 4 1V2H1C0.734784 2 0.48043 2.10536 0.292893 2.29289C0.105357 2.48043 0 2.73478 0 3V6C0 7.06087 0.421427 8.07828 1.17157 8.82843C1.92172 9.57857 2.93913 10 4 10H5.54C6.44453 11.0091 7.66406 11.6824 9 11.91V14H8C7.20435 14 6.44129 14.3161 5.87868 14.8787C5.31607 15.4413 5 16.2044 5 17V19C5 19.2652 5.10536 19.5196 5.29289 19.7071C5.48043 19.8946 5.73478 20 6 20H14C14.2652 20 14.5196 19.8946 14.7071 19.7071C14.8946 19.5196 15 19.2652 15 19V17C15 16.2044 14.6839 15.4413 14.1213 14.8787C13.5587 14.3161 12.7956 14 12 14H11V11.91C12.3359 11.6824 13.5555 11.0091 14.46 10H16C17.0609 10 18.0783 9.57857 18.8284 8.82843C19.5786 8.07828 20 7.06087 20 6V3C20 2.73478 19.8946 2.48043 19.7071 2.29289C19.5196 2.10536 19.2652 2 19 2ZM4 8C3.46957 8 2.96086 7.78929 2.58579 7.41421C2.21071 7.03914 2 6.53043 2 6V4H4V6C4.0022 6.68171 4.12056 7.35806 4.35 8H4ZM12 16C12.2652 16 12.5196 16.1054 12.7071 16.2929C12.8946 16.4804 13 16.7348 13 17V18H7V17C7 16.7348 7.10536 16.4804 7.29289 16.2929C7.48043 16.1054 7.73478 16 8 16H12ZM14 6C14 7.06087 13.5786 8.07828 12.8284 8.82843C12.0783 9.57857 11.0609 10 10 10C8.93913 10 7.92172 9.57857 7.17157 8.82843C6.42143 8.07828 6 7.06087 6 6V2H14V6ZM18 6C18 6.53043 17.7893 7.03914 17.4142 7.41421C17.0391 7.78929 16.5304 8 16 8H15.65C15.8794 7.35806 15.9978 6.68171 16 6V4H18V6Z"
                                        fill="#04151C"
                                    />
                                </svg>
                                @for (
                                    event of getEventsForLevel(level.order);
                                    track $index
                                ) {
                                    <div class="level-trophy-event">
                                        {{ event.name }}<br />
                                    </div>
                                }
                            </ion-content>
                        </ng-template>
                    </ion-popover>
                }
            </div>
        }
    </div>
}
