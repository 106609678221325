@if (documentTutorialVideo) {
    <div class="w-100" style="position: relative">
        <omedom-essential-video
            [video]="documentTutorialVideo"
        ></omedom-essential-video>
    </div>
}

<div class="documents-container">
    <omedom-document-search
        class="blur"
        [addButtonInSearchBar]="true"
        [isTypeFree]="false"
    ></omedom-document-search>

    <omedom-storage-progress></omedom-storage-progress>

    <div class="list blur" [@list]="fakeDocuments.length">
        @for (document of fakeDocuments; track document.uid) {
            <omedom-document-card
                style="cursor: auto"
                [document]="document"
            ></omedom-document-card>
        }
    </div>
</div>
