<omedom-lock
    [isLocked]="('accessManageFinance' | isAuthorised | async) === false"
>
    <ng-container omedom-lock-content>
        <div class="omedom-page-container-content w-100">
            @switch (state$ | async) {
                @case ('pending') {
                    <ng-container *ngTemplateOutlet="pending"></ng-container>
                }
                @case ('error') {
                    <ng-container *ngTemplateOutlet="error"></ng-container>
                }
                @default {
                    <ng-container *ngTemplateOutlet="content"></ng-container>
                }
            }

            <ng-template #pending>
                <div @element class="pending-container">
                    <span>Chargement des données de votre banque...</span>
                    <ion-spinner></ion-spinner>
                </div>
            </ng-template>

            <ng-template #content>
                <ng-container [ngTemplateOutlet]="form"></ng-container>
            </ng-template>

            <ng-template #error> Error </ng-template>

            <ng-template #form>
                <omedom-stepper
                    [steps]="steps"
                    [(selectedStep)]="selectedStep"
                    [loading]="(saveState$ | async) === 'pending'"
                    (onSave)="submit()"
                >
                    @if (selectedStep.id === 'infos') {
                        <omedom-loan-infos-step
                            [form]="getForm('infos')"
                        ></omedom-loan-infos-step>
                    }
                    @if (selectedStep.id === 'dueDate') {
                        <omedom-loan-due-date-step
                            [form]="getForm('dueDate')"
                        ></omedom-loan-due-date-step>
                    }
                    @if (demo && selectedStep.id === 'confirmation') {
                        <omedom-loan-confirmation-step
                            [loan]="demo"
                        ></omedom-loan-confirmation-step>
                    }
                </omedom-stepper>
            </ng-template>
        </div>
    </ng-container>

    <ng-container omedom-lock-fallback>
        <omedom-action
            (actionClicked)="goToSubscription()"
            @element
            class="w-100 margin-top-1 margin-bottom-1"
            text="Fonctionnalité Premium"
            actionIcon="omedom-icon-crown"
        ></omedom-action>
        <button
            class="omedom-button-primary w-100 margin-top-1"
            @element
            (click)="goToSubscription()"
        >
            Changer mon abonnement
        </button>
    </ng-container>
</omedom-lock>
