import { IncomingMessage } from "http";

/**
 * @description Interface for the result of the function deleteBrevosContacts in Brevo
 * @author Didier Pascarel <didier.pascarel@omedom.com>
 * @date 10/12/2024
 * @interface DeleteBrevosContactsResult
 */
export interface DeleteBrevosContactsResult {
    deletedContactsEmails: string[];
    notdeletedContactsEmailss: string[];
    results: Array<{
        response: IncomingMessage;
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        body?: any;
    } | unknown>;
}
