@if (user) {
    @if (user.avatar) {
        <img
            [src]="user.avatar"
            class="avatar"
            alt="Avatar de {{ user.firstname }} {{ user.name }}"
        />
    } @else {
        <div class="avatar">
            <i class="uil uil-user-circle"></i>
        </div>
    }
    <div class="infos">
        @if (displayKey('name') && displayKey('firstname')) {
            <div class="info">
                <i class="uil uil-user-circle"></i>
                @if (user.name || user.firstname) {
                    <span> {{ user.firstname }} {{ user.name }} </span>
                } @else {
                    <span class="empty-info">Non renseigné</span>
                }
            </div>
        }
        @if (displayKey('address')) {
            <div class="info">
                <i class="uil uil-location-pin-alt"></i>
                @if (user.address) {
                    <span>
                        {{ user.address.streetNumber }}
                        {{ user.address.street }},
                        {{ user.address.postalCode }} {{ user.address.city }}
                    </span>
                } @else {
                    <span class="empty-info">Non renseigné</span>
                }
            </div>
        }
        @if (displayKey('email')) {
            <div class="info">
                <i class="uil uil-envelope"></i>
                @if (user.email) {
                    <span>{{ user.email }}</span>
                } @else {
                    <span class="empty-info">Non renseigné</span>
                }
            </div>
        }
        @if (displayKey('proUID')) {
            <div class="info">
                <i class="uil uil-briefcase-alt"></i>
                @if (pro) {
                    <span>{{ pro.name }}</span>
                } @else {
                    <span class="empty-info">Non renseigné</span>
                }
            </div>
        }
    </div>
} @else {
    <div class="empty">
        <i class="uil uil-exclamation-triangle"></i>
        <span>Utilisateur non renseigné</span>
    </div>
}

<ng-template #empty>
    <div class="empty">
        <i class="uil uil-exclamation-triangle"></i>
        <span>Utilisateur non renseigné</span>
    </div>
</ng-template>
