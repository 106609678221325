@if (isInTrial || isInEssential) {
    @if (!isInApp) {
        <div class="content margin-bottom-2">
            <i
                class="uil uil-stopwatch margin-right-1"
                [class.has-expired]="hasExpired"
            ></i
            >{{ content }}
        </div>
    } @else {
        <omedom-popover
            [content]="content"
            class="margin-left-1"
            [withMargin]="false"
            [iconColor]="hasExpired ? 'red' : 'yellow'"
            [iconTop]="false"
            [withIcon]="true"
            icon="uil uil-stopwatch"
        ></omedom-popover>
    }
}
