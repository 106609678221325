import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { IonModal, ModalController, ToastController } from '@ionic/angular';
import {
    AssetTypes,
    PropertyEntity,
    Sharing,
    SocietyEntity,
    SocietyStatusMember,
    UserEntity,
} from '@omedom/data';
import { PropertyService, SocietyService, UserService } from '@omedom/services';
import { Subscription } from 'rxjs';

import { ShareFormComponent } from '../share-form/share-form.component';

@Component({
    selector: 'omedom-share-card',
    templateUrl: './share-card.component.html',
    styleUrls: ['./share-card.component.scss'],
})
export class ShareCardComponent implements OnInit, OnDestroy {
    /**
     * @description Share
     * @author Jérémie Lopez
     * @type {Sharing}
     * @memberof ShareCardComponent
     */
    @Input()
    public share?: Sharing;

    /**
     * @description Entity UID of the user shared
     * @author Jérémie Lopez
     * @type {string}
     * @memberof ShareCardComponent
     */
    @Input()
    public entity?: PropertyEntity | SocietyEntity;

    /**
     * @description Type of share
     * @author Jérémie Lopez
     * @type {string}
     * @memberof ShareCardComponent
     */
    @Input()
    public type?: AssetTypes;

    /**
     * @description Type of society share card
     * @author Brisset Killian
     * @date 20/06/2024
     * @memberof ShareCardComponent
     */
    public societyType = AssetTypes.society;

    /**
     * @description Index of share
     * @author Jérémie Lopez
     * @type {number}
     * @memberof ShareCardComponent
     */
    @Input()
    public sharingIndex?: number;

    /**
     * @description Editable share card
     * @author Brisset Killian
     * @date 20/06/2024
     * @type {boolean}
     * @memberof ShareCardComponent
     */
    @Input()
    public editable?: boolean;

    /**
     * @description Is shared card shared or not
     * @author Brisset Killian
     * @date 20/06/2024
     * @memberof ShareCardComponent
     */
    @Input()
    public isShared = false;

    /**
     * @description User who shared the entity to the user in the card
     * @author Brisset Killian
     * @date 20/06/2024
     * @type {UserEntity}
     * @memberof ShareCardComponent
     */
    public user?: UserEntity;

    /**
     * @description Subscriptions of the component ShareCardComponent
     * @author Brisset Killian
     * @date 20/06/2024
     * @private
     * @type {Subscription[]}
     * @memberof ShareCardComponent
     */
    private subscriptions: Subscription[] = [];

    /**
     * @description Is the user a member of the society or not
     * @author Brisset Killian
     * @date 20/06/2024
     * @memberof ShareCardComponent
     */
    public isSocietyMember = false;

    /**
     * @description Status of the member in the society if he is a member of the society or not
     * @author Brisset Killian
     * @date 20/06/2024
     * @type {SocietyStatusMember}
     * @memberof ShareCardComponent
     */
    public memberStatus?: SocietyStatusMember;

    constructor(
        private toastController: ToastController,
        private modalController: ModalController,
        private propertyService: PropertyService,
        private societyService: SocietyService,
        private userService: UserService
    ) {}

    ngOnInit(): void {
        const user$ = this.userService.user$.subscribe((user) => (this.user = user));

        this.subscriptions.push(user$);
        this.setSocietyMember();
    }

    ngOnDestroy(): void {
        this.subscriptions.forEach((subscription) => subscription.unsubscribe());
    }

    /**
     * @description Set if the user is a member of the society or not and set the status of the member
     * if he is a member of the society or not
     * @author Brisset Killian
     * @date 20/06/2024
     * @private
     * @memberof ShareCardComponent
     */
    private setSocietyMember() {
        // isSocietymember a true, si la personne 'share' correspond à une des personne dans society.members
        if (this.type === AssetTypes.society && this.share) {
            const society = this.entity as SocietyEntity;
            society.members.filter((member) => {
                if (member.email === this.share?.email) {
                    this.isSocietyMember = true;
                    this.memberStatus = member.status;
                }
            });
        }
    }

    /**
     * @description Open the modal to delete the share
     * @author Jérémie Lopez
     * @param {IonModal} modal
     * @return {*}  {Promise<void>}
     * @memberof ShareCardComponent
     */
    public async openDeleteModal(modal: IonModal): Promise<void> {
        await modal.present();
    }

    /**
     * @description Delete the share from the entity
     * @author Jérémie Lopez
     * @return {*}  {Promise<void>}
     * @memberof MemberCardComponent
     */
    public async delete(modal?: IonModal): Promise<void> {
        await this.removeShareAttributeFromEntity();
        if (modal) await this.showDeleteToastSuccess(modal);
    }

    /**
     * @description Remove the share attribute from the entity and update the entity in the database
     * @author Brisset Killian
     * @date 20/06/2024
     * @private
     * @returns {*}
     * @memberof ShareCardComponent
     */
    private async removeShareAttributeFromEntity() {
        if (!(this.sharingIndex || this.sharingIndex === 0) || !this.type) return;
        let service: PropertyService | SocietyService;
        switch (this.type) {
            case AssetTypes.building:
            case AssetTypes.property:
                service = this.propertyService;
                break;
            case AssetTypes.society:
                service = this.societyService;
                break;
            default:
                return;
        }
        this.entity?.sharing.splice(this.sharingIndex, 1);
        await service.update(this.entity as any);
    }

    /**
     * @description Show a toast to confirm the deletion of the share
     * and dismiss the modal after the toast is shown
     * @author Brisset Killian
     * @date 20/06/2024
     * @private
     * @param {IonModal} modal
     * @memberof ShareCardComponent
     */
    private async showDeleteToastSuccess(modal: IonModal) {
        modal.dismiss();
        const toast = await this.toastController.create({
            position: 'top',
            duration: 2000,
            color: 'primary',
            message: 'Le partage a bien été supprimé',
        });

        await toast.present();
    }

    /**
     * @description Open the modal to edit the member
     * @author Jérémie Lopez
     * @return {*}  {Promise<void>}
     * @memberof MemberCardComponent
     */
    public async edit(): Promise<void> {
        const modal = await this.modalController.create({
            component: ShareFormComponent,
            breakpoints: [0, 1],
            initialBreakpoint: 1,
            canDismiss: true,
            componentProps: {
                share: this.share,
                entity: this.entity,
                sharingIndex: this.sharingIndex,
                type: this.type,
                isSocietyMember: this.isSocietyMember,
            },
        });

        await modal.present();
    }
}
