import { LoanEntity } from '../../entities';

export const fakeLoans: LoanEntity[] = [
    {
        accountID: 1234567,
        bankID: 574,
        borrowedCapital: 150000,
        repaidCapital: 50000,
        interestRate: 3.9,
        name: 'Crédit Immobilier',
        nextPaymentAmount: 442,
        remainingCapital: 14116,
        nextPaymentDate: new Date(),
        maturityDate: new Date(),
        openingDate: new Date(),
        uid: 'xxxxxx',
        created: new Date(),
        updated: new Date(),
        userUID: 'Antoine',
    },
];

// nextPaymentDate
// :
// Sun Dec 01 2024 01:00:00 GMT+0100 (heure normale d’Europe centrale) {}
// openingDate
// :
// Sun Jan 01 2023 01:00:00 GMT+0100 (heure normale d’Europe centrale) {}
// uid
// :
// "74dzaDyVu11lreNK1fVl"
