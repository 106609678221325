import { Component, EventEmitter, Input, OnChanges, Output, ViewChild } from '@angular/core';
import { IonPopover } from '@ionic/angular';
import {
    PropertiesFilter,
    PropertyEntity,
    SelectOption,
    SocietyEntity,
    startDateTarification,
    UserEntity,
} from '@omedom/data';
import { PropertyService, SocietyService, UserService } from '@omedom/services';
import { BehaviorSubject, Subscription } from 'rxjs';

import { SelectComponent } from '../select/select.component';

interface TreeSelectOption {
    parent: SelectOption;
    children: TreeSelectOption[];
    level: number;
}

@Component({
    selector: 'omedom-asset-filter',
    templateUrl: './asset-filter.component.html',
    styleUrls: ['./asset-filter.component.scss'],
})
export class AssetFilterComponent implements OnChanges {
    @Input() disabled = false;

    /**
     * @description Display filter icon if authorized by user subscription plan
     * @author Didier Pascarel <didier.pascarel@omedom.com>
     * @date 18/06/2024
     * @memberof AssetFilterComponent
     */
    @Input() canFilter = false;

    @Output() update = new EventEmitter();

    @Input() showLabel = false;

    /**
     * @description Put icon filter to left and add text, user in desktop
     * @author ANDRE Felix
     * @memberof AssetFilterComponent
     */
    @Input() iconFilterToLeft = false;

    isOpen = false;

    /**
     * @description controls popover for properties
     * @author Hanane Djeddal
     * @memberof TreasuryBase
     */
    @ViewChild('popover') popover!: IonPopover;

    @ViewChild('smartPopover') smartPopover!: IonPopover;

    /**
     * @description the listing of properties for filtering
     * @author Hanane Djeddal
     * @type {SelectComponent}
     * @memberof TreasuryBase
     */
    @ViewChild('selectApp') selectApp!: SelectComponent;

    selectedAssets: SelectOption[] = [];

    propertyOptions$ = new BehaviorSubject<SelectOption[]>([]);

    subjectPlaceholder = { label: 'Vos biens' } as SelectOption;

    allPropertiesOptions: SelectOption[] = [];

    public allAssetsTreesOptions: TreeSelectOption[] = [];

    private subscription!: Subscription;

    private currentUser!: UserEntity;

    @Input() properties: PropertyEntity[] = [];

    @Input() societies: SocietyEntity[] = [];

    /**
     * @description If the date is before the transition date (2024-03-01) or not
     * @author Brisset Killain
     * @type {boolean}
     * @memberof PropertyListPage
     */
    protected isBeforeTransitionDate = false;

    constructor(
        private userService: UserService,
        private propertyService: PropertyService,
        private societyService: SocietyService,
    ) {}

    async ngOnChanges() {
        this.selectedAssets = PropertiesFilter.filteredProperties;

        this.subscription = this.userService.user$.subscribe(async (user) => {
            this.currentUser = user;

            const propertieOptions = this.propertyService.getPropertiesOptions(this.properties);
            const societiesOptions = this.societyService.getSocietiesOptions(this.societies);

            const propertieOptionsFiltered = propertieOptions.filter((x) => x.isAccesible);

            const propertiesUids = propertieOptions.map((x) => x.id);
            const societiesUids = societiesOptions.map((x) => x.id);

            let allAssetsTreesOptions: TreeSelectOption[] = [];

            for (const societyOption of societiesOptions) {
                const findOption = this.findTreeSelectOption(allAssetsTreesOptions, societyOption);
                if (!findOption) {
                    allAssetsTreesOptions.push({
                        parent: societyOption,
                        children: [],
                        level: 0,
                    });
                }
            }

            for (const propertyOption of propertieOptionsFiltered) {
                const property = this.properties.find((x) => x.uid === propertyOption.id);

                if (!property) {
                    continue;
                }

                const hasPropertyParent =
                    property.parentPropertyUID &&
                    property.parentPropertyUID !== property.uid &&
                    propertiesUids.includes(property.parentPropertyUID);

                const hasSocietyParent =
                    property.societyUID && societiesUids.includes(property.societyUID);

                let parentOption: SelectOption | undefined;
                if (hasPropertyParent) {
                    parentOption = propertieOptions.find(
                        (x) => x.id === property.parentPropertyUID,
                    );
                } else if (hasSocietyParent) {
                    parentOption = societiesOptions.find((x) => x.id === property.societyUID);
                }

                const findOption = this.findTreeSelectOption(allAssetsTreesOptions, propertyOption);

                if (parentOption) {
                    const findParent = this.findTreeSelectOption(
                        allAssetsTreesOptions,
                        parentOption,
                    );

                    if (!findParent && findOption) {
                        findOption.children = [
                            {
                                parent: findOption.parent,
                                children: findOption.children.map((child) => {
                                    return {
                                        parent: child.parent,
                                        children: child.children,
                                        level: child.level + 1,
                                    };
                                }),
                                level: findOption.level + 1,
                            },
                        ];

                        // Remove the property from the parent level
                        findOption.parent = parentOption;
                    } else if (!findParent && !findOption) {
                        allAssetsTreesOptions.push({
                            parent: parentOption,
                            children: [
                                findOption ?? {
                                    parent: propertyOption,
                                    children: [],
                                    level: 1,
                                },
                            ],
                            level: 0,
                        });
                    } else if (findParent && findOption) {
                        // If the parent is in the list and the property is in the list
                        findParent.children.push({
                            parent: findOption.parent,
                            children: findOption.children.map((child) => {
                                return {
                                    parent: child.parent,
                                    children: child.children,
                                    level: (child.level ?? 0) + 1,
                                };
                            }),
                            level: (findParent.level ?? 0) + 1,
                        });

                        findParent.children = findParent.children.sort((a, b) => {
                            return a.children.length - b.children.length;
                        });

                        // Remove the property from the parent level
                        allAssetsTreesOptions = allAssetsTreesOptions.filter(
                            (treeOption) => treeOption.parent.id !== findOption.parent.id,
                        );
                    } else if (findParent && !findOption) {
                        // If the parent is in the list and the property is not in the list
                        // Add the property to the parent children
                        findParent.children.push({
                            parent: propertyOption,
                            children: [],
                            level: findParent.level + 1,
                        });

                        findParent.children = findParent.children.sort((a, b) => {
                            return a.children.length - b.children.length;
                        });
                    }
                } else {
                    if (!findOption) {
                        allAssetsTreesOptions.push({
                            parent: propertyOption,
                            children: [],
                            level: 0,
                        });
                    }
                }
            }

            this.allAssetsTreesOptions = allAssetsTreesOptions;

            this.selectedAssets = this.selectedAssets.filter(
                (x) => propertiesUids.includes(x.id) || societiesUids.includes(x.id),
            );

            this.updateFilter();

            const selectedUids = this.selectedAssets.map((x) => x.id);

            this.allPropertiesOptions = [...propertieOptionsFiltered, ...societiesOptions];

            this.propertyOptions$.next(this.allPropertiesOptions);

            this.propertyOptions$.forEach((y) =>
                y.forEach((x) => {
                    if (selectedUids.includes(x.id)) {
                        x.isSelected = true;
                    } else {
                        x.isSelected = false;
                    }
                }),
            );

            const transitionDate = new Date(startDateTarification);
            const today = new Date();
            this.isBeforeTransitionDate = today.getTime() < transitionDate.getTime();
        });
    }

    private findTreeSelectOption(
        treesSelectOption: TreeSelectOption[],
        assetOption: SelectOption,
    ): TreeSelectOption | undefined {
        for (const treeSelectOption of treesSelectOption) {
            if (treeSelectOption.parent.id === assetOption.id) {
                return treeSelectOption;
            }
            const parent = this.findTreeSelectOption(treeSelectOption.children, assetOption);
            if (parent) {
                return parent;
            }
        }
        return undefined;
    }

    /**
     * @description event when property is selected from filter
     * @param property
     */
    async propertySelected(property: SelectOption) {
        this.popover?.dismiss();
        if (this.canFilter && !this.disabled && property.isAccesible) {
            const index = this.selectedAssets.findIndex((element) => element.id === property.id);
            const findOption = this.findTreeSelectOption(this.allAssetsTreesOptions, {
                ...property,
            });

            if (index >= 0) {
                const allChildrenSelected = findOption?.children.reduce<boolean>((acc, child) => {
                    return (
                        acc &&
                        !!child.parent.isSelected &&
                        child.children.reduce<boolean>((acc, x) => {
                            return acc && this.selectedAssets.some((y) => y.id === x.parent.id);
                        }, true)
                    );
                }, true);

                if (allChildrenSelected && findOption && findOption.children.length > 0) {
                    // Get the ids to remove
                    const idsToRemove = [
                        ...findOption.children.reduce<string[]>((acc, child) => {
                            return [
                                ...acc,
                                child.parent.id,
                                ...child.children.map((x) => x.parent.id),
                            ];
                        }, []),
                    ];

                    for (const idToRemove of idsToRemove) {
                        console.log(idToRemove);
                        console.log([...this.selectedAssets]);
                        const indexToRemove = this.selectedAssets.findIndex(
                            (element) => element.id === idToRemove,
                        );
                        console.log(indexToRemove);
                        if (indexToRemove >= 0) {
                            this.selectedAssets.splice(indexToRemove, 1);
                            console.log([...this.selectedAssets]);
                        }
                    }

                    this.propertyOptions$.forEach((y) =>
                        y.forEach((x) => {
                            if (idsToRemove.includes(x.id)) {
                                x.isSelected = false;
                            }
                        }),
                    );
                } else {
                    // Unselect only the selected property
                    this.selectedAssets.splice(index, 1);

                    this.propertyOptions$.forEach((y) =>
                        y.forEach((x) => {
                            if (x.id === property.id) {
                                x.isSelected = false;
                            }
                        }),
                    );
                }
            } else {
                let selectAssetToAdd = [property];

                if (findOption && findOption.children.length > 0) {
                    selectAssetToAdd = [
                        ...selectAssetToAdd,
                        ...findOption.children.reduce<SelectOption[]>((acc, child) => {
                            return [...acc, child.parent, ...child.children.map((x) => x.parent)];
                        }, [] as SelectOption[]),
                    ].filter((x) => x.id && this.selectedAssets.findIndex((y) => y.id === x.id));
                }
                this.selectedAssets.push(...selectAssetToAdd);

                this.propertyOptions$.forEach((y) =>
                    y.forEach((x) => {
                        if (selectAssetToAdd.some((z) => z.id === x.id)) {
                            x.isSelected = true;
                        }
                    }),
                );
            }
            PropertiesFilter.updateProperties(this.selectedAssets);
            this.update.emit();
        }
    }

    updateFilter() {
        PropertiesFilter.updateProperties(this.selectedAssets);
        this.update.emit();
    }

    /**
     * @description allow to open the select-app via the filter icon
     */
    openSelect(): void {
        // TODO filter is not working, i disabled it
        // this.selectApp.onClick();
    }
    presentSmartPopover(e: Event) {
        if (!this.canFilter) {
            this.smartPopover.event = e;
            this.isOpen = true;
        }
    }
}
