import {
    AllChargeCategories,
    CategoryInfo,
    ChargeCategoryInfo,
    ChargeCategoryProperty,
    ChargeEntity,
    ChargePeriodicity,
    ChargePeriodicityInfo,
    IncomeCategoryInfo,
    IncomeCategoryProperty,
    IncomeEntity,
    IncomePeriodicity,
    IncomePeriodicityInfo,
    PeriodicityInfo,
    PropertyEntity,
    SocietyEntity,
    Story,
} from '@omedom/data';

import { OmedomTreasury } from './treasury';

// type
type Treasury = ChargeEntity | IncomeEntity;
/**
 * @description Movement util class. This class is used to transform charges and incomes (movement) to stories
 * @author Jérémie Lopez <jeremie.lopez@omedom.com>
 * @date 08/08/2023
 * @export
 * @class OmedomMovement
 */
export class OmedomMovement {
    /**
     * @description Get the next movements in an array of movement (charge or income)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 08/08/2023
     * @static
     * @param {ChargeEntity[]} charges Array of charges
     * @param {IncomeEntity[]} incomes Array of incomes
     * @param {PropertyEntity[]} properties Array of properties
     * @param {Date} startDate Start date
     * @param {boolean} onlyPayed Only payed condition
     * @param {boolean} [notification=true] Notification of the movement
     * @returns {Story[]}  Array of stories
     * @memberof OmedomMovement
     * @example
     * const charges = await this.chargeService.search({...});
     * const incomes = await this.incomeService.search({...});
     * const properties = await this.propertyService.search({...});
     *
     * const stories = OmedomMovement.getNextMovement(charges, incomes, properties, new Date(), false);
     */
    public static getNextMovement(
        charges: ChargeEntity[],
        incomes: IncomeEntity[],
        properties: PropertyEntity[],
        startDate: Date,
        onlyPayed: boolean,
        notification: boolean = true,
        societies?: SocietyEntity[],
    ): Story[] {
        // Check if charges and incomes are not null
        if (!charges || !incomes) {
            return [];
        }

        // Leave the treasury with punctual periodicity, and for future mouvements we check if the nextHistoryDate is in this month
        charges = charges?.filter((x) => {
            const debitDate = x.debitDate?.toDate();
            const nextHistoryDate = x.nextHistoryDate?.toDate();

            // If debitDate is undefined, we return false
            if (!debitDate) {
                return false;
            }

            const isPunctual = x.periodicity === ChargePeriodicity.punctual;
            const isDebitDateMoreThanStartDate = debitDate >= startDate;
            const isNextHistoryDateMoreThanStartDate =
                nextHistoryDate?.getTime() ?? 0 >= startDate.getTime();

            return (
                (isPunctual && isDebitDateMoreThanStartDate) || isNextHistoryDateMoreThanStartDate
            );
        });
        incomes = incomes?.filter((x) => {
            const debitDate = x.debitDate?.toDate();
            const nextHistoryDate = x.nextHistoryDate?.toDate();

            // If debitDate is undefined, we return false
            if (!debitDate) {
                return false;
            }

            const isPunctual = x.periodicity === IncomePeriodicity.punctual;
            const isDebitDateMoreThanStartDate = debitDate >= startDate;
            const isNextHistoryDateMoreThanStartDate =
                nextHistoryDate?.getTime() ?? 0 >= startDate.getTime();

            return (
                (isPunctual && isDebitDateMoreThanStartDate) || isNextHistoryDateMoreThanStartDate
            );
        });

        // Filter charges and incomes with notification
        const chargesWithNotif = this.filterNextMovement(
            charges,
            true,
            properties,
            onlyPayed,
            notification,
            (category: string) => new ChargeCategoryInfo(category as ChargeCategoryProperty),
            (periodicity: string) => new ChargePeriodicityInfo(periodicity as ChargePeriodicity),
            societies,
        );

        // Filter charges and incomes with notification
        const incomesWithNotif = this.filterNextMovement(
            incomes,
            false,
            properties,
            onlyPayed,
            notification,
            (category: string) => new IncomeCategoryInfo(category as IncomeCategoryProperty),
            (periodicity: string) => new IncomePeriodicityInfo(periodicity as IncomePeriodicity),
        );

        // Concat charges and incomes with notification
        // Sort by isReaded and date
        return chargesWithNotif
            .concat(incomesWithNotif)
            .sort(
                (a, b) =>
                    +a.isReaded - +b.isReaded ||
                    new Date(a.date).getTime() - new Date(b.date).getTime(),
            );
    }

    /**
     * @description Get the past movements in an array of movement (charge or income) with notification or not
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 08/08/2023
     * @static
     * @param {ChargeEntity[]} charges Array of charges
     * @param {IncomeEntity[]} incomes Array of incomes
     * @param {PropertyEntity[]} properties Array of properties
     * @param {Date} endDate End date
     * @param {boolean} onlyPayed Only payed condition
     * @param {boolean} [notification=true]
     * @returns {Story[]}  Array of stories
     * @memberof OmedomMovement
     * @example
     * const charges = await this.chargeService.search({...});
     * const incomes = await this.incomeService.search({...});
     * const properties = await this.propertyService.search({...});
     *
     * const stories = OmedomMovement.getPastMovement(charges, incomes, properties, new Date(), false);
     */
    public static getPastMovement(
        charges: ChargeEntity[],
        incomes: IncomeEntity[],
        properties: PropertyEntity[],
        endDate: Date,
        onlyPayed: boolean,
        notification: boolean = true,
        societies?: SocietyEntity[],
    ): Story[] {
        // Check if charges and incomes are not null
        if (!charges || !incomes) {
            return [];
        }

        // Filter charges and incomes with history that are in the past
        charges?.forEach(
            (x) =>
                (x.history = x.history?.filter(
                    (h) => h.date.toDate() <= endDate.getLastDayOfMonth(),
                )),
        );
        incomes?.forEach(
            (x) =>
                (x.history = x.history?.filter(
                    (h) => h.date.toDate() <= endDate.getLastDayOfMonth(),
                )),
        );

        // Leave the treasury with punctual periodicity, and for future mouvements we check if the nextHistoryDate is in this month
        charges = charges?.filter((x) => {
            const debitDate = x.debitDate?.toDate();

            // If debitDate is undefined, we return false
            if (!debitDate) {
                return false;
            }

            const isPunctual = x.periodicity === ChargePeriodicity.punctual;
            const isDebitDateLessThanEndDate = debitDate <= endDate;

            return x.history?.length || (isPunctual && isDebitDateLessThanEndDate);
        });
        incomes = incomes?.filter((x) => {
            const debitDate = x.debitDate?.toDate();

            // If debitDate is undefined, we return false
            if (!debitDate) {
                return false;
            }

            const isPunctual = x.periodicity === IncomePeriodicity.punctual;
            const isDebitDateLessThanEndDate = debitDate <= endDate;

            return x.history?.length || (isPunctual && isDebitDateLessThanEndDate);
        });

        // Filter charges and incomes with notification
        const chargesWithNotif = this.filterPastMovement(
            charges,
            true,
            properties,
            onlyPayed,
            notification,
            (category: string) => new ChargeCategoryInfo(category as ChargeCategoryProperty),
            (periodicity: string) => new ChargePeriodicityInfo(periodicity as ChargePeriodicity),
            societies,
        );
        const incomesWithNotif = this.filterPastMovement(
            incomes,
            false,
            properties,
            onlyPayed,
            notification,
            (category: string) => new IncomeCategoryInfo(category as IncomeCategoryProperty),
            (periodicity: string) => new IncomePeriodicityInfo(periodicity as IncomePeriodicity),
            societies,
        );

        // Concat charges and incomes with notification
        // Sort by isReaded and date
        return chargesWithNotif
            .concat(incomesWithNotif)
            .sort(
                (a, b) =>
                    +a.isReaded - +b.isReaded ||
                    new Date(a.date).getTime() - new Date(b.date).getTime(),
            );
    }

    /**
     * @description Get All stories from charges and incomes filtered in a a period and params (onlyPaid, notification, futureStories)
     * @author ANDRE Felix
     * @static
     * @param {ChargeEntity[]} charges
     * @param {IncomeEntity[]} incomes
     * @param {PropertyEntity[]} properties
     * @param {Date} startDate
     * @param {Date} endDate
     * @param {boolean} onlyPayed
     * @param {boolean} [futureStories=false]
     * @param {boolean} [notification=true]
     * @returns {*}  {Story[]}
     * @memberof OmedomMovement
     */
    public static getAllStoriesByPeriod(
        charges: ChargeEntity[],
        incomes: IncomeEntity[],
        properties: PropertyEntity[],
        societies: SocietyEntity[],
        startDate: Date,
        endDate: Date,
        onlyPayed: boolean,
        futureStories: boolean = false,
        notification: boolean = true,
    ): Story[] {
        // I can't make difference between chargeEntity and IncomeEntity, so I call two times this function
        const chargeTreasuries = this.getAllMovementByPeriod(
            charges,
            [],
            properties,
            societies,
            startDate,
            endDate,
            onlyPayed,
            futureStories,
            notification,
        );

        const incomeTreasuries = this.getAllMovementByPeriod(
            [],
            incomes,
            properties,
            societies,
            startDate,
            endDate,
            onlyPayed,
            futureStories,
            notification,
        );
        const chargeStory = this.transformTreasuriesToStories(
            chargeTreasuries,
            properties,
            societies,
            true,
        );
        const incomeStory = this.transformTreasuriesToStories(
            incomeTreasuries,
            properties,
            societies,
            false,
        );
        const treasuriesStorySort = [...chargeStory, ...incomeStory].sort(
            (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime(),
        );
        return treasuriesStorySort;
    }

    public static hasSomeStoriesNotPayed(stories: Story[]) {
        return stories.some((story) => !story.isPayed);
    }

    /**
     * @description Get Charges and Incomes within a period and filtered by params (onlyPayed, notification, futureStories)
     * @author Didier Pascarel <didier.pascarel@omedom.com> Felix ANDRE
     * @static
     * @param {ChargeEntity[]} charges Array of charges
     * @param {IncomeEntity[]} incomes Array of incomes
     * @param {PropertyEntity[]} properties Array of properties
     * @param {Date} startDate Start date
     * @param {Date} endDate End date
     * @param {boolean} onlyPayed Only payed condition
     * @param {boolean} [futureStories=false] Future stories condition
     * @param {boolean} [notification=true] Notification condition
     * @return {Treasury[]} Array of stories
     * @memberof OmedomMovement
     * @example
     * const charges = await this.chargeService.search({...});
     * const incomes = await this.incomeService.search({...});
     * const properties = await this.propertyService.search({...});
     *
     * const startDate = new Date(2021, 0, 1);
     * const endDate = new Date(2021, 0, 31);
     *
     * const stories = OmedomMovement.getMovement(charges, incomes, properties, startDate, endDate, false);
     */
    private static getAllMovementByPeriod(
        charges: ChargeEntity[],
        incomes: IncomeEntity[],
        properties: PropertyEntity[],
        societies: SocietyEntity[],
        startDate: Date,
        endDate: Date,
        onlyPayed: boolean,
        futureStories: boolean = false,
        notification: boolean = true,
    ): Treasury[] {
        // Check if charges and incomes are not null
        if (!charges || !incomes) {
            return [];
        }

        const chargesFiltered = this.filterOnlyPaid(charges, properties, societies, onlyPayed);
        const incomesFiltered = this.filterOnlyPaid(incomes, properties, societies, onlyPayed);

        const chargesFilteredByPeriod = this.filterHistoryByPeriod(
            chargesFiltered,
            startDate,
            endDate,
        );
        const incomesFilteredByPeriod = this.filterHistoryByPeriod(
            incomesFiltered,
            startDate,
            endDate,
        );

        // Leave the treasury with punctual periodicity, and for future mouvements we check if the nextHistoryDate is in this month
        const chargeNextHistoryFiltered = this.filterNextHistoryByPeriod(
            chargesFilteredByPeriod as ChargeEntity[],
            startDate,
            endDate,
            futureStories,
            false,
        );

        const incomesNextHistoryFiltered = this.filterNextHistoryByPeriod(
            incomesFilteredByPeriod as IncomeEntity[],
            startDate,
            endDate,
            futureStories,
            false,
        );

        return [...chargeNextHistoryFiltered, ...incomesNextHistoryFiltered];
    }

    /**
     * @description Filter if nexthistory is within period
     * @author ANDRE Felix
     * @private
     * @static
     * @param {(ChargeEntity[] | IncomeEntity[])} treasuries
     * @param {Date} startDate
     * @param {Date} endDate
     * @param {boolean} futureStories
     * @param {boolean} isCharge
     * @returns {*}
     * @memberof OmedomMovement
     */
    private static filterNextHistoryByPeriod(
        treasuries: ChargeEntity[] | IncomeEntity[],
        startDate: Date,
        endDate: Date,
        futureStories: boolean,
        isCharge: boolean,
    ) {
        const treasuryPeriodicity = isCharge ? ChargePeriodicity : IncomePeriodicity;

        const nextHistoryFilter = (treasury: ChargeEntity | IncomeEntity) => {
            const debitDate = treasury.debitDate?.toDate();
            const nextHistoryDate = treasury.nextHistoryDate?.toDate();

            // If debitDate is undefined, we return false
            if (!debitDate) {
                return false;
            }

            const isPunctual = treasury.periodicity === treasuryPeriodicity.punctual;
            const isDebitDateBetweenStartEndDate = debitDate.between(startDate, endDate);
            const isNextHistoryDateBetweenStartEndDate = nextHistoryDate?.between(
                startDate,
                endDate,
            );

            return (
                treasury.history?.length ||
                (isPunctual && isDebitDateBetweenStartEndDate) ||
                (futureStories && isNextHistoryDateBetweenStartEndDate)
            );
        };

        // This condition is due because filter() isn't callable on an union type
        if (isCharge) {
            return (treasuries as ChargeEntity[]).filter(nextHistoryFilter);
        } else {
            return (treasuries as IncomeEntity[]).filter(nextHistoryFilter);
        }
    }

    /**
     * @description Filtre history by period of treasuries
     * @author ANDRE Felix
     * @private
     * @static
     * @param {(ChargeEntity[] | IncomeEntity[])} treasuries
     * @param {Date} startDate
     * @param {Date} endDate
     * @memberof OmedomMovement
     */
    private static filterHistoryByPeriod = (
        treasuries: ChargeEntity[] | IncomeEntity[],
        startDate: Date,
        endDate: Date,
    ) => {
        const result = treasuries.map((treasury) => {
            treasury.history = treasury.history?.filter(
                (history) =>
                    !history.isDeleted &&
                    history.date.toDate().between(startDate, endDate.getLastDayOfMonth()),
            );
            return treasury;
        });
        return result;
    };

    private static transformTreasuriesToStories(
        treasuries: (ChargeEntity | IncomeEntity)[],
        properties: PropertyEntity[],
        societies: SocietyEntity[],
        isCharge: boolean,
    ) {
        const now = new Date().toUTC();
        const stories = treasuries.map((treasury) => {
            // Sort history by date
            const sortedHistory = treasury?.history?.sort(
                (a, b) => new Date(a.date.toDate()).getTime() - new Date(b.date.toDate()).getTime(),
            );

            // Get the first history if onlyPayed is false
            const history = !!sortedHistory?.length ? sortedHistory[0] : undefined;

            let date: Date;
            let amount: number;

            // If the periodicity is not punctual, we get the next history date and the amount of the month
            // Else we get the debit date and the amount
            if (treasury.periodicity !== (ChargePeriodicity || IncomePeriodicity).punctual) {
                date = history
                    ? history.date.toDate()
                    : treasury.nextHistoryDate?.toDate() ?? treasury.debitDate?.toDate() ?? now;
                amount = OmedomTreasury.getMonthAmount(treasury, date);
            } else {
                date = treasury.debitDate?.toDate() ?? now;
                amount = treasury.amount;
            }

            // Get the property
            const property = properties?.find((property) => property.uid === treasury.propertyUID);
            const society = societies?.find((society) => society.uid === treasury.societyUID);
            let getCategoryInfo: (category: string) => ChargeCategoryInfo | IncomeCategoryInfo;
            let getPeriodicityInfo: (
                periodicity: string,
            ) => ChargePeriodicityInfo | IncomePeriodicityInfo;

            if (isCharge) {
                getCategoryInfo = (category: string) =>
                    new ChargeCategoryInfo(category as AllChargeCategories);

                getPeriodicityInfo = (periodicity: string) =>
                    new ChargePeriodicityInfo(periodicity as ChargePeriodicity);

                // categoryInfo = new ChargeCategoryInfo(treasury.category as ChargeCategoryProperty);
            } else {
                getCategoryInfo = (category: string) =>
                    new IncomeCategoryInfo(category as IncomeCategoryProperty);
                getPeriodicityInfo = (periodicity: string) =>
                    new IncomePeriodicityInfo(periodicity as IncomePeriodicity);

                // categoryInfo = new IncomeCategoryInfo(treasury.category as IncomeCategoryProperty);
            }
            // Return the story
            return new Story({
                amount,
                isCharge,
                uid: treasury.uid,
                categoryInfo: getCategoryInfo(treasury.category),
                periodicityInfo: getPeriodicityInfo(treasury.periodicity),
                propertyImg: property?.photo ?? society?.photo,
                propertyName: property?.name ?? society?.name,
                ownerUID: property?.userUID ?? society?.userUID,
                withNotif: treasury.notification,
                isReaded:
                    treasury.periodicity === (ChargePeriodicity || IncomePeriodicity).punctual
                        ? treasury?.isReaded
                        : history?.isReaded,
                isPayed:
                    treasury.periodicity === (ChargePeriodicity || IncomePeriodicity).punctual
                        ? treasury?.isPayed
                        : history?.isPayed,

                isTransaction:
                    treasury.periodicity === (ChargePeriodicity || IncomePeriodicity).punctual
                        ? !!treasury.transactionID
                        : !!history?.transactionID,
                designation: treasury.designation,
                date,
                notes: OmedomTreasury.getMonthNotes(treasury, date),
            });
        });
        // treasuries.forEach((treasury) => {
        //     const property = properties.find((p) => p.uid === treasury.propertyUID);

        //     const story = OmedomStory.transformTreasuryToStory(
        //         treasury,
        //         isCharge,
        //         property as PropertyEntity
        //     );
        //     stories.push(story);
        // });
        return stories;
    }

    /**
     * @description Filter charges and incomes to stories with notification from the future and return stories array
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 08/08/2023
     * @private
     * @static
     * @template T ChargeEntity or IncomeEntity
     * @param {T[]} entities Array of charges or incomes
     * @param {boolean} isCharge Is charge condition
     * @param {PropertyEntity[]} properties Array of properties
     * @param {boolean} onlyPayed Only payed condition
     * @param {boolean} notification Notification condition
     * @param {(category: string) => CategoryInfo<any>} getCategory Get category function
     * @param {(periodicity: string) => PeriodicityInfo<any>} getPeriodicity Get periodicity function
     * @returns {Story[]} Array of stories
     * @memberof OmedomMovement
     * @example
     * const charges = await this.chargeService.search({...});
     * const properties = await this.propertyService.search({...});
     *
     * const stories = OmedomMovement.filterMovement(
     *      charges, // entities
     *      true, // isCharge
     *      properties, // properties
     *      false, // onlyPayed
     *      false, // notification
     *      true, // futureStories
     *      (category: string) => new ChargeCategoryInfo(category as ChargeCategoryProperty), // getCategory
     *      (periodicity: string) => new ChargePeriodicityInfo(periodicity as ChargePeriodicity) // getPeriodicity
     * );
     */
    private static filterNextMovement<T extends ChargeEntity | IncomeEntity>(
        entities: T[],
        isCharge: boolean,
        properties: PropertyEntity[],
        onlyPayed: boolean,
        notification: boolean,
        getCategory: (category: string) => CategoryInfo<any>,
        getPeriodicity: (periodicity: string) => PeriodicityInfo<any>,
        societies?: SocietyEntity[],
    ): Story[] {
        // Check if entities is not null
        if (!entities) {
            return [];
        }

        // Get now date
        const now = new Date().toUTC();

        // Filter entities with isDeleted
        entities = entities.filter((x) => !x.isDeleted);

        return (
            entities
                // Filter entities with notification and onlyPayed and property exist
                .filter((x) => {
                    // Get property
                    const property = properties?.find((p) => p.uid === x.propertyUID);

                    // Get society
                    const society = societies?.find((s) => s.uid === x.societyUID);

                    // If property is undefined, we return false
                    if (!property && !society) {
                        return false;
                    }

                    const isOnlyPayed = x.history?.some((h) => !h.isPayed) || !onlyPayed;

                    return isOnlyPayed;
                })
                .map((treasury) => {
                    let date: Date;

                    // If periodicity is not punctual, we take the nextHistoryDate, else we take the debitDate
                    if (
                        treasury.periodicity !== (ChargePeriodicity || IncomePeriodicity).punctual
                    ) {
                        date =
                            treasury.nextHistoryDate?.toDate() ??
                            treasury.debitDate?.toDate() ??
                            now;
                    } else {
                        date = treasury.debitDate?.toDate() ?? now;
                    }

                    // Get property
                    const property = properties?.find((p) => p.uid === treasury.propertyUID);

                    // Get society
                    const society = societies?.find((s) => s.uid === treasury.societyUID);

                    // Return story
                    return new Story({
                        amount: OmedomTreasury.getMonthAmount(treasury, date),
                        isCharge,
                        uid: treasury.uid,
                        categoryInfo: getCategory(treasury.category),
                        periodicityInfo: getPeriodicity(treasury.periodicity),
                        propertyImg: property?.photo ?? society?.photo,
                        propertyName: property?.name ?? society?.name,
                        isReaded: false,
                        isPayed:
                            treasury.periodicity ===
                            (ChargePeriodicity || IncomePeriodicity).punctual
                                ? date <= now
                                    ? true
                                    : false
                                : false,

                        designation: treasury.designation,
                        date,
                    });
                })
        );
    }

    /**
     * @description Filter charges and incomes to stories with notification from the past and return stories array
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 08/08/2023
     * @private
     * @static
     * @template T ChargeEntity or IncomeEntity
     * @param {T[]} entities Array of charges or incomes
     * @param {boolean} isCharge Is charge condition
     * @param {PropertyEntity[]} properties Array of properties
     * @param {boolean} onlyPayed Only payed condition
     * @param {boolean} notification Notification condition
     * @param {(category: string) => CategoryInfo<any>} getCategory Get category function
     * @param {(periodicity: string) => PeriodicityInfo<any>} getPeriodicity Get periodicity function
     * @returns {Story} Array of stories
     * @memberof OmedomMovement
     * @example
     * const charges = await this.chargeService.search({...});
     * const properties = await this.propertyService.search({...});
     *
     * const stories = OmedomMovement.filterMovement(
     *      charges, // entities
     *      true, // isCharge
     *      properties, // properties
     *      false, // onlyPayed
     *      false, // notification
     *      false, // futureStories
     *      (category: string) => new ChargeCategoryInfo(category as ChargeCategoryProperty), // getCategory
     *      (periodicity: string) => new ChargePeriodicityInfo(periodicity as ChargePeriodicity) // getPeriodicity
     * );
     */
    private static filterPastMovement<T extends ChargeEntity | IncomeEntity>(
        entities: T[],
        isCharge: boolean,
        properties: PropertyEntity[],
        onlyPayed: boolean,
        notification: boolean,
        getCategory: (category: string) => CategoryInfo<any>,
        getPeriodicity: (periodicity: string) => PeriodicityInfo<any>,
        societies?: SocietyEntity[],
    ): Story[] {
        // Check if entities is not null
        if (!entities) {
            return [];
        }

        // Get now date
        const now = new Date().toUTC();

        // Filter entities with isDeleted
        entities = entities.filter((x) => !x.isDeleted);

        return (
            entities
                // Filter entities with notification and onlyPayed and property exist
                .filter((treasury) => {
                    // Get property
                    const property = properties?.find((p) => p.uid === treasury.propertyUID);
                    const society = societies?.find((s) => s.uid === treasury.societyUID);
                    // If property is undefined, we return false
                    if (!property && !society) {
                        return false;
                    }

                    // Get sorted history by date
                    const sortedHistory = treasury?.history?.sort(
                        (a, b) =>
                            new Date(b.date.toDate()).getTime() -
                            new Date(a.date.toDate()).getTime(),
                    );

                    // Get last history or first history if no history
                    const history = onlyPayed
                        ? sortedHistory?.find((x) => !x.isPayed)
                        : !!sortedHistory?.length
                          ? sortedHistory[0]
                          : undefined;

                    // If history is undefined and periodicity is not punctual, we return false
                    if (
                        !history &&
                        treasury.periodicity !== (ChargePeriodicity || IncomePeriodicity).punctual
                    ) {
                        return false;
                    }

                    const isOnlyPayed = treasury.history?.some((h) => !h.isPayed) || !onlyPayed;
                    return isOnlyPayed;
                })
                // Map entities to stories
                .map((treasury) => {
                    // Get sorted history by date
                    const sortedHistory = treasury?.history?.sort(
                        (a, b) =>
                            new Date(b.date.toDate()).getTime() -
                            new Date(a.date.toDate()).getTime(),
                    );

                    // Get last history or first history if no history
                    const history = onlyPayed
                        ? sortedHistory?.find((x) => !x.isPayed)
                        : !!sortedHistory?.length
                          ? sortedHistory[0]
                          : undefined;

                    let date: Date;

                    // If periodicity is not punctual, we take the lastHistoryDate, else we take the debitDate
                    if (
                        treasury.periodicity !== (ChargePeriodicity || IncomePeriodicity).punctual
                    ) {
                        date = history
                            ? history.date.toDate()
                            : treasury.lastHistoryDate?.toDate() ??
                              treasury.debitDate?.toDate() ??
                              now;
                    } else {
                        date = treasury.debitDate?.toDate() ?? now;
                    }

                    // Get property
                    const property = properties?.find((p) => p.uid === treasury.propertyUID);
                    // Get society
                    const society = societies?.find((s) => s.uid === treasury.societyUID);

                    // Return story
                    return new Story({
                        amount: OmedomTreasury.getMonthAmount(treasury, date),
                        isCharge,
                        uid: treasury.uid,
                        categoryInfo: getCategory(treasury.category),
                        periodicityInfo: getPeriodicity(treasury.periodicity),
                        propertyImg: property?.photo ?? society?.photo,
                        propertyName: property?.name ?? society?.name,
                        isReaded: history?.isReaded,
                        isPayed:
                            treasury.periodicity ===
                            (ChargePeriodicity || IncomePeriodicity).punctual
                                ? date <= now
                                    ? true
                                    : false
                                : history?.isPayed,
                        isTransaction:
                            treasury.periodicity ===
                            (ChargePeriodicity || IncomePeriodicity).punctual
                                ? !!treasury.transactionID
                                : !!history?.transactionID,
                        designation: treasury.designation,
                        date,
                    });
                })
        );
    }

    /**
     * @description Filter onlypaid and notification on treasury
     * @author ANDRE Felix
     * @private
     * @static
     * @template T
     * @param {T[]} entities
     * @param {PropertyEntity[]} properties
     * @param {boolean} onlyPayed
     * @param {boolean} notification
     * @returns {*}  {T[]}
     * @memberof OmedomMovement
     */
    private static filterOnlyPaid<T extends ChargeEntity | IncomeEntity>(
        entities: T[],
        properties: PropertyEntity[],
        societies: SocietyEntity[],
        onlyPayed: boolean,
    ): T[] {
        return entities.filter((treasury) => {
            const property = properties?.find((property) => property.uid === treasury.propertyUID);
            const society = societies?.find((society) => society.uid === treasury.societyUID);
            if (!property && !society) {
                return false;
            }
            const isDeleted = treasury?.isDeleted;
            const isOnlyPayed = treasury.history?.some((h) => !h.isPayed) || !onlyPayed;

            return !isDeleted && isOnlyPayed;
        });
    }

    /**
     * @description Filter charges and incomes to stories with notification from the future and return stories array
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 09/08/2023
     * @private
     * @static
     * @template T
     * @param {T[]} entities Array of charges or incomes
     * @param {boolean} isCharge Is charge condition
     * @param {PropertyEntity[]} properties Array of properties
     * @param {boolean} onlyPayed Only payed condition
     * @param {boolean} futureStories Future stories condition
     * @param {boolean} notification Notification condition
     * @param {(category: string) => CategoryInfo<any>} getCategory Get category function
     * @param {(periodicity: string) => PeriodicityInfo<any>} getPeriodicity Get periodicity function
     * @returns {Story[]} Array of stories
     * @memberof OmedomMovement
     * @example
     * const charges = await this.chargeService.search({...});
     * const properties = await this.propertyService.search({...});
     *
     * const stories = OmedomMovement.filterMovement(
     *      charges, // entities
     *      true, // isCharge
     *      properties, // properties
     *      false, // onlyPayed
     *      true, // futureStories
     *      true, // notification
     *      (category: string) => new ChargeCategoryInfo(category as ChargeCategoryProperty), // getCategory
     *      (periodicity: string) => new ChargePeriodicityInfo(periodicity as ChargePeriodicity) // getPeriodicity
     * );
     */
    //a Renommer, car filter et transform en story
    private static filterMovement<T extends ChargeEntity | IncomeEntity>(
        entities: T[],
        isCharge: boolean,
        properties: PropertyEntity[],
        onlyPayed: boolean,
        futureStories: boolean,
        notification: boolean,
        getCategory: (category: string) => CategoryInfo<any>,
        getPeriodicity: (periodicity: string) => PeriodicityInfo<any>,
    ): Story[] {
        // Check if entities is not null
        if (!entities) {
            return [];
        }

        // Get now date
        const now = new Date().toUTC();

        // Filter deleted entities
        entities = entities.filter((x) => !x.isDeleted);

        const filterMovement = this.filterOnlyPaid(entities, properties, [], onlyPayed);

        const afterMap = filterMovement.map((treasury) => {
            // Sort history by date
            const sortedHistory = treasury?.history?.sort(
                (a, b) => new Date(a.date.toDate()).getTime() - new Date(b.date.toDate()).getTime(),
            );

            // Get the first history if onlyPayed is false
            const history = onlyPayed
                ? sortedHistory?.find((x) => !x.isPayed)
                : !!sortedHistory?.length
                  ? sortedHistory[0]
                  : undefined;

            let date: Date;
            let amount: number;

            // If the periodicity is not punctual, we get the next history date and the amount of the month
            // Else we get the debit date and the amount
            if (treasury.periodicity !== (ChargePeriodicity || IncomePeriodicity).punctual) {
                date = history
                    ? history.date.toDate()
                    : treasury.nextHistoryDate?.toDate() ?? treasury.debitDate?.toDate() ?? now;
                amount = OmedomTreasury.getMonthAmount(treasury, date);
            } else {
                date = treasury.debitDate?.toDate() ?? now;
                amount = treasury.amount;
            }

            // Get the property
            const property = properties?.find((p) => p.uid === treasury.propertyUID);

            // Return the story
            return new Story({
                amount,
                isCharge,
                uid: treasury.uid,
                categoryInfo: getCategory(treasury.category),
                periodicityInfo: getPeriodicity(treasury.periodicity),
                propertyImg: property?.photo,
                propertyName: property?.name,
                isReaded:
                    treasury.periodicity === (ChargePeriodicity || IncomePeriodicity).punctual
                        ? treasury?.isReaded
                        : history?.isReaded,
                isPayed:
                    treasury.periodicity === (ChargePeriodicity || IncomePeriodicity).punctual
                        ? treasury?.isPayed
                        : history?.isPayed,
                isTransaction:
                    treasury.periodicity === (ChargePeriodicity || IncomePeriodicity).punctual
                        ? !!treasury.transactionID
                        : !!history?.transactionID,
                designation: treasury.designation,
                date,
                notes: OmedomTreasury.getMonthNotes(treasury, date),
            });
        });

        return afterMap;
    }
}
