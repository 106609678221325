@if (accountsData && accountsData.length > 0) {
    <div class="header">
        <div class="title">
            @if (icon) {
                <i class="{{ icon }} "></i>
            }
            <strong class="title-text">{{ title }}</strong>
        </div>
        <div class="global-amount">
            <span class="value" [style.color]="globalAmountData?.color">
                <strong>{{ globalAmountData?.value }}</strong>
            </span>
        </div>
    </div>

    <div class="content">
        @for (accountData of accountsData; track accountData.id) {
            <div class="account">
                <div class="icon">
                    @if (accountData.bank && accountData.bank.logoUrl) {
                        <img
                            [src]="accountData.bank.logoUrl"
                            class="logo"
                            alt="logo banque"
                        />
                    } @else if (icon) {
                        <i class="{{ icon }}"></i>
                    } @else {
                        <i class="uil uil-wallet"></i>
                    }
                </div>

                <div class="label">
                    <div
                        class="account-name"
                        [style.color]="accountData.account.nameColor"
                    >
                        {{ accountData.account.name }}
                    </div>
                    <span class="bank-name">{{ accountData.bank.name }}</span>
                </div>

                <div
                    class="value"
                    [style.color]="accountData.account.amountColor"
                >
                    <strong>{{ accountData.account.amount }} </strong>
                </div>
            </div>
        }
    </div>
}
