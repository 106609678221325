import {
    Component,
    EventEmitter,
    forwardRef,
    HostBinding,
    HostListener,
    Input,
    OnInit,
    Output,
    ViewEncapsulation,
} from '@angular/core';
import { ControlValueAccessor, NG_VALIDATORS, NG_VALUE_ACCESSOR, Validator } from '@angular/forms';
import { ModalController } from '@ionic/angular';
import { optGroupOptions, SelectOption } from '@omedom/data';

import { SelectBottomSheetComponent } from './bottom-sheet/select-bottom-sheet.component';

@Component({
    selector: 'omedom-select',
    templateUrl: './select.component.html',
    styleUrls: ['select.component.scss'],
    encapsulation: ViewEncapsulation.None,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => SelectComponent),
            multi: true,
        },
        {
            provide: NG_VALIDATORS,
            useExisting: forwardRef(() => SelectComponent),
            multi: true,
        },
    ],
})
export class SelectComponent implements OnInit, ControlValueAccessor, Validator {
    /**
     * @description The placeholder to display in the select when no option is selected
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 02/11/2023
     * @type {SelectOption}
     * @memberof SelectComponent
     */
    @Input()
    public placeholder?: SelectOption;

    /**
     * @description True if we can select multiple options, false otherwise
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 02/11/2023
     * @type {boolean}
     * @memberof SelectComponent
     */
    @Input()
    public isMultiple: boolean = false;

    /**
     * @description Number Limit of option selectable (only for multiple selection)
     * @author ANDRE Felix
     * @type {number}
     * @memberof SelectComponent
     */
    @Input() selectionNumberLimit?: number;

    /**
     * @description Footer to display at the bottom of the select
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 02/11/2023
     * @type {boolean}
     * @memberof SelectComponent
     */
    @Input()
    public footer?: boolean;

    /**
     * @description Label to display at the top of the select
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 02/11/2023
     * @type {string}
     * @memberof SelectComponent
     */
    @Input()
    public label?: string;

    /**
     * @description Add a required star to the label if true, nothing otherwise
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 02/11/2023
     * @type {boolean}
     * @memberof SelectComponent
     */
    @Input()
    public required: boolean = false;

    /**
     * @description Display the options in a detailed way if true, nothing otherwise
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 02/11/2023
     * @memberof SelectComponent
     */
    @Input()
    public detailedOptions = false;

    /**
     * @description Display the label in bold if true, nothing otherwise
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 02/11/2023
     * @type {boolean}
     * @memberof SelectComponent
     */
    @Input()
    public boldLabel: boolean = false;

    /**
     * @description The options to display in the select (used by the ControlValueAccessor interface) (private because it is used only in the ngOnInit method to subscribe to the options$ observable and unsubscribe in the ngOnDestroy method
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 02/11/2023
     * @private
     * @type {SelectOption[]}
     * @memberof SelectComponent
     */
    @Input()
    public options: SelectOption[] = [];

    /**
     * @description Options already selected, use to fill the isSelected in SelectedOption (only for multiple selection)
     * @author ANDRE Felix
     * @type {string[]}
     * @memberof SelectComponent
     */
    @Input() selectedOptionsIds?: string[];

    /**
     * @description Used for optgroup select
     * @author ANDRE Felix
     * @type {optGroupOptions[]}
     * @memberof SelectComponent
     */
    @Input() optGroupOptions?: optGroupOptions[];

    /**
     * @description Event emitted when the select is exited (when the modal is closed) with the selected option(s) as parameter(s) if any, undefined otherwise
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 02/11/2023
     * @type {EventEmitter<void>}
     * @memberof SelectComponent
     */
    @Output()
    public selectExited: EventEmitter<SelectOption | SelectOption[] | undefined> =
        new EventEmitter();

    /**
     * @description True if the select has the class disabled
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 02/11/2023
     * @type {boolean}
     * @memberof SelectComponent
     */
    @HostBinding('class.disabled')
    public isDisabled: boolean = false;

    /**
     * @description The value of the select (the selected option(s) if any, undefined otherwise) (used by the ControlValueAccessor interface)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 02/11/2023
     * @type {*}
     * @memberof SelectComponent
     */
    public value?: any;

    /**
     * @description True if the select is open, false otherwise (used to display the options in the template)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 02/11/2023
     * @type {boolean}
     * @memberof SelectComponent
     */
    public isOpen: boolean = false;

    /**
     * @description The selected option
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 02/11/2023
     * @type {SelectOption}
     * @memberof SelectComponent
     */
    public selectedOption?: SelectOption;

    /**
     * @description The length of the value of the select if it is an array, undefined otherwise (used in the template to display the number of selected options if the select is multiple)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 02/11/2023
     * @readonly
     * @type {(number | undefined)}
     * @memberof SelectComponent
     */
    public get lentgh(): number | undefined {
        return this.value instanceof Array ? this.value.length : undefined;
    }

    /**
     * @description The function to call when the value of the select changes
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 02/11/2023
     * @private
     * @type {*}
     * @memberof SelectComponent
     */
    private onChangeCallback: Function = () => {};

    constructor(private modalController: ModalController) {}

    ngOnInit(): void {
        this.setSelectedOption();
    }

    setSelectedOption() {
        if (this.selectedOptionsIds && this.isMultiple && this.options.length) {
            this.options.forEach((selectOption, index) => {
                const isSelected = this.selectedOptionsIds?.includes(selectOption.id);
                if (isSelected) {
                    const currentOption = this.options[index];
                    this.options[index] = {
                        ...currentOption,
                        isSelected: true,
                    };
                }
            });
        }
    }

    /**
     * @description Open the select when the user clicks on it (or do nothing if the select is disabled) (used by the HostListener decorator)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 02/11/2023
     * @returns {*}  {Promise<void>}
     * @memberof SelectComponent
     */
    @HostListener('click')
    public async onClick(): Promise<void> {
        this.openSelectBottomModal();
    }

    private async openSelectBottomModal() {
        // If the select is disabled, do nothing
        if (this.isDisabled) {
            return;
        }
        // Open the select
        const modal = await this.modalController.create({
            component: SelectBottomSheetComponent,
            initialBreakpoint: 1,
            breakpoints: [0, 1],
            componentProps: {
                options: this.options,
                selectTitle: this.label,
                isMultiple: this.isMultiple,
                selectedOptionsIds: this.selectedOptionsIds,
                selectionNumberLimit: this.selectionNumberLimit,
                optGroupOptions: this.optGroupOptions,
            },
        });

        // When the modal is closed, update the value of the select
        modal.onDidDismiss().then((x) => {
            if (x.data) {
                this.selectionChange(x?.data);
            }
            if (x.role === 'backdrop') {
                const optionsSelected = this.options.filter((x) => x.isSelected);
                this.selectionChange(optionsSelected);
            }
        });

        await modal.present();
    }

    /**
     * @description Method that performs synchronous validation against the provided control
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 02/11/2023
     * @returns {null}
     * @memberof SelectComponent
     */
    public validate(): null {
        return null;
    }

    /**
     * @description Registers a callback function that is called when the control's value changes in the UI
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 02/11/2023
     * @param {Function} fn
     * @memberof SelectComponent
     */
    public registerOnChange(fn: Function): void {
        this.onChangeCallback = fn;
    }

    /**
     * @description Registers a callback function that is called by the forms API on initialization to update the form model on blur
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 02/11/2023
     * @memberof SelectComponent
     */
    public registerOnTouched(): void {}

    /**
     * @description This method is called by the forms API to write to the view when programmatic changes from model to view are requested
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 02/11/2023
     * @param {(SelectOption | SelectOption[])} newValue
     * @memberof SelectComponent
     */
    public writeValue(newValue: any): void {
        // If the value is the same as the previous one, do nothing
        if (newValue === this.value && this.selectedOption) {
            return;
        }

        if (newValue === undefined || newValue === null) {
            this.selectedOption = undefined;
            this.value = undefined;
            return;
        }

        const isValueAnArray = Array.isArray(newValue);
        const isValueASelectOption =
            typeof newValue === 'object' &&
            newValue !== null &&
            'id' in newValue &&
            'label' in newValue;

        // If the value is an array and is not empty, set the selected option to the first one
        if (isValueAnArray && newValue.length) {
            const firstValue = newValue[0];

            const firstValueOption =
                this.options.find((x) =>
                    firstValue.id ? x.id === firstValue.id : x.id === firstValue,
                ) ??
                this.optGroupOptions
                    ?.flatMap((x) => x.options)
                    .find((x) => (firstValue.id ? x.id === firstValue.id : x.id === firstValue));

            this.selectedOption = {
                label: `${firstValueOption?.label ?? firstValue} ${
                    newValue.length > 1 ? `+${newValue.length - 1}` : ''
                }`,
                id: null,
                image: firstValue.image,
                imageAlt: firstValue.imageAlt,
                icon: firstValue.icon,
                isSelected: true,
            } as SelectOption;

            this.value = newValue.map((x) => x.id);
        } else if (isValueASelectOption) {
            this.selectedOption = newValue as SelectOption;
            this.value = newValue.id;
        } else {
            this.selectedOption = this.options.find((x) => x.id === newValue);
            this.value = newValue;
        }

        // Set the isSelected property of the selected option to true
        if (this.selectedOption) {
            this.selectedOption.isSelected = true;
        }

        this.onChangeCallback(this.value);
    }

    /**
     * @description Disables the select if the argument is true, enables it otherwise (Work only in template driven forms)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 02/11/2023
     * @param {boolean} isDisabled
     * @memberof SelectComponent
     */
    public setDisabledState(isDisabled: boolean): void {
        this.isDisabled = isDisabled;
    }

    /**
     * @description Clear the selection of the select
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 02/11/2023
     * @memberof SelectComponent
     */
    public clearSelectionClicked(): void {
        // If value is an array, set the isSelected property of each option to false
        if (Array.isArray(this.value)) {
            this.value?.forEach((x) => {
                const option = this.options.find((y) => y.id === x);
                if (option) {
                    option.isSelected = false;
                }
            });
        } else if (this.value?.isSelected !== undefined) {
            // If value is not an array, set the isSelected property of the option to false
            this.value.isSelected = false;
        }

        // Broadcast the change
        this.writeValue(undefined);
        this.onChangeCallback(undefined);
    }

    /**
     * @description Method called when the selection of the select changes (when the modal is closed)
     * @author Jérémie Lopez <jeremie.lopez@omedom.com>
     * @date 02/11/2023
     * @private
     * @param {SelectOption[]} selection
     * @returns {*}  {void}
     * @memberof SelectComponent
     */
    private selectionChange(selection?: SelectOption[]): void {
        // If the selection is empty, set the value to undefined
        if (!selection) {
            this.writeValue(undefined);
            this.onChangeCallback(undefined);
            return;
        }

        let value: SelectOption | SelectOption[] | undefined;

        // If the selection is not empty, set the value to the first option if the select is not multiple, to the selection otherwise
        if (selection.length === 0) {
            value = this.isMultiple ? selection : undefined;
        } else {
            value = this.isMultiple ? selection : selection[0];
        }

        // Broadcast the change
        this.writeValue(value);
        this.selectExited?.next(value);
    }
}
