<div class="popup-container">
    <div class="popup-header">
        <span class="popup-icon" [style.transform]="'rotateY(180deg)'">🎄</span>
        <div class="popup-title-container">
            <h1 class="popup-title">
                <span>Jeu de Noël</span><span> "Raconte-moi une maison"</span>
            </h1>
            <span class="popup-subtitle">Pour transmettre l'histoire</span>
        </div>
        <span class="popup-icon">🎄</span>
    </div>
    @if (user) {
        @switch (_state | async) {
            @case ('pending') {
                <ng-container *ngTemplateOutlet="pending"></ng-container>
            }
            @case ('error') {
                <ng-container *ngTemplateOutlet="error"></ng-container>
            }
            @default {
                @if (!showExplanation) {
                    <ng-container *ngTemplateOutlet="content"></ng-container>
                } @else {
                    <ng-container
                        *ngTemplateOutlet="explanation"
                    ></ng-container>
                }
            }
        }
    }
</div>

<ng-template #pending>
    <div @element class="pending-container">
        <span>Chargement du Jeu de Noël</span>
        <ion-spinner></ion-spinner>
    </div>
</ng-template>

<ng-template #error>
    <div class="error-container">
        <p @element>
            Une erreur est survenue lors du chargement du Jeu de Noël.
        </p>
    </div>
</ng-template>

<ng-template #explanation>
    <div class="explanation-container">
        <h2 class="explanation-title">
            Jeu de Noël : « Raconte-moi une maison »
        </h2>
        <p class="explanation-text">
            Pour participer : Importez une photo de votre bien, et partagez-nous
            son histoire. Tirage au sort mi-Janvier !
        </p>
        <div class="explanation-encadre">
            <span class="explication-encadre-text">
                🎁 Coline, Héloïse et Chloé débarquent pour rencontrer le
                gagnant avec un panier garni made in Occitanie et un abonnement
                annuel FAMILY ! 🎁
            </span>
            <span class="explanation-encadre-date">
                Vous avez jusqu'au 06 janvier 2025 !
            </span>
        </div>

        <div class="explanation-exemple">
            <span class="explanation-exemple-title">
                Coline SINQUIN, fondatrice d'OMEDOM, nous raconte son histoire :
            </span>
            <ng-container
                [ngTemplateOutlet]="propertyContestInfo"
                [ngTemplateOutletContext]="{
                    propertyContestValue: propertyContestExample,
                    imagePreview: previewExample
                }"
            ></ng-container>
        </div>
        <button class="omedom-button-primary" (click)="showExplanation = false">
            <span>Suivant</span>
        </button>
    </div>
</ng-template>

<ng-template #content>
    @if (propertyContestForm && isEditable) {
        <form [formGroup]="propertyContestForm" class="popup-form">
            <div class="form-group">
                <div
                    class="file-container w-100"
                    [ngClass]="{ 'drag-over': isDragOver }"
                    (drop)="dropHandler($event)"
                    (dragover)="dragOverHandler($event)"
                    (dragleave)="isDragOver = false"
                    (click)="$any(fileinput).click()"
                    [class.padding-2]="!(file && isValidFileType)"
                    [style.border]="
                        file && isValidFileType
                            ? 'transparent'
                            : '1.5px #04151c dashed'
                    "
                >
                    @if (file && isValidFileType) {
                        @if (preview) {
                            <img
                                [src]="preview"
                                alt="Image du bien"
                                class="file-preview"
                            />
                        }
                        <div class="file-detail margin-top-1">
                            <span>{{ file.name }}</span>
                            <button
                                class="omedom-icon-button-warn"
                                (click)="deleteFile(); $event.stopPropagation()"
                            >
                                <i class="uil uil-trash-alt"></i>
                            </button>
                        </div>
                    } @else {
                        <div class="drop-label margin-y-2 w-100">
                            <i class="uil uil-import margin-right-1"></i>
                            <span>Déposer une image ou cliquer ici</span>
                        </div>
                    }
                    @if (document) {
                        <button
                            class="omedom-button-secondary"
                            (click)="
                                $any(fileinput).click($event);
                                $event.stopPropagation()
                            "
                        >
                            <i class="uil uil-import"></i>
                            <span> Remplacer l'image</span>
                        </button>
                    }
                    <input
                        #fileinput
                        name="file"
                        id="file"
                        class="omedom-invisible"
                        type="file"
                        accept="image/*"
                        (change)="keepFile($any($event.target).files)"
                    />
                    @if (!isValidFileType) {
                        <div
                            class="margin-y-1 color-red"
                            style="font-size: 14px"
                        >
                            Seul les images peuvent être importés
                        </div>
                    }
                </div>
            </div>

            <div class="form-group">
                <omedom-checkbox
                    [required]="true"
                    formControlName="newProperty"
                    label="Nouveau bien"
                >
                    Nouveau bien
                </omedom-checkbox>

                @if (!newProperty) {
                    @if (propertiesOptions$ | async; as propertiesOptions) {
                        <omedom-select
                            [required]="true"
                            formControlName="propertyUID"
                            label="Bien associé"
                            [options]="propertiesOptions || []"
                            class="form-select"
                        ></omedom-select>
                    }
                } @else {
                    <omedom-input
                        [required]="true"
                        formControlName="name"
                        label="Nom du bien"
                        type="text"
                        class="form-input"
                    ></omedom-input>

                    @if (canAddProperty) {
                        <omedom-select
                            formControlName="use"
                            [required]="canAddProperty"
                            [placeholder]="usePlaceholder"
                            [options]="(useOptions$ | async) || []"
                            label="Usage du bien"
                            class="form-select"
                        ></omedom-select>
                    }
                }
            </div>

            <div class="form-group">
                <omedom-input
                    [required]="true"
                    formControlName="description"
                    label="Racontez l'histoire de votre maison"
                    type="text"
                    class="textarea form-textarea"
                ></omedom-input>
            </div>

            <div class="form-actions">
                <button
                    class="omedom-button-secondary form-button"
                    type="button"
                    [disabled]="propertyContestForm.invalid"
                    (click)="save()"
                >
                    <i class="uil uil-save"></i>
                    <span>Continuer plus tard</span>
                </button>
                <button
                    class="omedom-button-green form-button"
                    type="submit"
                    [disabled]="propertyContestForm.invalid"
                    (click)="save(true)"
                >
                    <i class="uil uil uil-envelope"></i>
                    <span>Envoyer</span>
                </button>
            </div>
        </form>
    } @else {
        <!-- Afficher fiche info avec le nom du bien, la description et l'image -->
        @if (propertyContest && propertyContest.isSubmitted) {
            <!--  le tirage au sort aura lieu mi-janvier 2025 -->
            <!-- <div class="contest-submitted-container">
                <div class="contest-submitted-content">
                    <i class="uil uil-check-circle"></i>
                    <span class="contest-submitted-text">
                        Votre inscription a bien été prise en compte, le tirage
                        au sort aura lieu mi-janvier 2025
                    </span>
                </div>
            </div>
            <div class="property-info">
                <h2 class="property-name">{{ propertyContest.name }}</h2>

                <img
                    [src]="preview"
                    alt="Image du bien"
                    class="property-image"
                />
                <p class="property-description">
                    {{ propertyContest.description }}
                </p>
            </div> -->
            <div class="contest-submitted-container">
                <div class="contest-submitted-content">
                    <i class="uil uil-check-circle"></i>
                    <span class="contest-submitted-text">
                        Votre inscription a bien été prise en compte, le tirage
                        au sort aura lieu mi-janvier 2025
                    </span>
                </div>
            </div>
            <ng-container
                [ngTemplateOutlet]="propertyContestInfo"
                [ngTemplateOutletContext]="{
                    propertyContestValue: propertyContest,
                    imagePreview: preview
                }"
            ></ng-container>
        } @else {
            <ng-container *ngTemplateOutlet="pending"></ng-container>
        }
    }
</ng-template>

<ng-template
    #propertyContestInfo
    let-propertyContestValue="propertyContestValue"
    let-imagePreview="imagePreview"
>
    <div class="property-info">
        <h2 class="property-name">{{ propertyContestValue.name }}</h2>

        <img [src]="imagePreview" alt="Image du bien" class="property-image" />
        <p
            class="property-description"
            [innerHTML]="propertyContestValue.description"
        ></p>
    </div>
</ng-template>
