import { Injectable } from '@angular/core';
import { DocumentService } from '../core';
import { AngularFireFunctions } from '@angular/fire/compat/functions';
import {
    DocumentEntity,
    PDFCreated,
    PDFCreatedDocument,
    PDFFunctions,
    PDFUsage,
} from '@omedom/data';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { lastValueFrom } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class PdfService {
    constructor(
        private documentService: DocumentService,
        private angularFireFunctions: AngularFireFunctions,
        private angularFireStorage: AngularFireStorage
    ) {}

    public async createPDF(data: any, pdfUsage: PDFUsage): Promise<PDFCreatedDocument> {
        let PDFCreated: PDFCreated;
        let pdfFunction: PDFFunctions;

        switch (pdfUsage) {
            case PDFUsage.receiptAndRelaunch:
                pdfFunction = PDFFunctions.receiptAndRelaunch;
                break;
            case PDFUsage.propertyInfo:
                pdfFunction = PDFFunctions.propertyInfo;
                break;
            case PDFUsage.summaryClient:
                pdfFunction = PDFFunctions.summaryClient;
                break;
        }

        try {
            const callable = this.angularFireFunctions.httpsCallable(pdfFunction);
            const pdfResponse = await lastValueFrom(callable(data));
            const downloadURL = await lastValueFrom(
                this.angularFireStorage
                    .refFromURL(pdfResponse.data.document.fileURL)
                    .getDownloadURL()
            );
            PDFCreated = {
                document: pdfResponse.data.document,
                documentURL: downloadURL,
                documentName: pdfResponse.data.document.name,
            };
        } catch (error) {
            console.error('error', error);
            throw new Error('Error while creating PDF');
        }
        const savedPDF = await this.savePDFAsDocument(PDFCreated);
        return {
            documentURL: PDFCreated.documentURL,
            documentID: savedPDF,
            documentName: PDFCreated.documentName,
        };
    }

    private async savePDFAsDocument(PDFCreated: PDFCreated): Promise<string> {
        let document: Partial<DocumentEntity>;
        let documentCreatedId = '';

        try {
            document = PDFCreated.document;
            document.fileURL = PDFCreated.documentURL;
            documentCreatedId = (await await this.documentService.create(document)).id;
        } catch (error) {
            console.error('error', error);
        }
        return documentCreatedId;
    }

    public async deletePDF(documentUID: string) {
        await this.documentService.delete(documentUID);
    }
}
