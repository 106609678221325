import { DecimalPipe } from '@angular/common';
import {
    Component,
    EventEmitter,
    Input,
    OnChanges,
    OnDestroy,
    OnInit,
    Output,
    SimpleChanges,
    ViewChild,
} from '@angular/core';
import { ChargeEntity, IncomeEntity, OmedomDateType, PropertyEntity } from '@omedom/data';
import { OmedomChart, OmedomTreasury } from '@omedom/utils';
import { ChartData, ChartDataset, ChartOptions } from 'chart.js';
import { BaseChartDirective } from 'ng2-charts';
import { Subject, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

import { OmedomChartLegend } from '../../components/chart-legend/omedom-chart-legend';
import { OmedomNumberPipe } from '../../pipes/number.pipe';

@Component({
    selector: 'omedom-liquidity',
    templateUrl: './liquidity.component.html',
    styleUrls: ['./liquidity.component.scss'],
})
export class LiquidityComponent implements OnInit, OnDestroy, OnChanges {
    @ViewChild(BaseChartDirective) baseChart?: BaseChartDirective;

    @Input()
    public showDate = true;

    /**
     * @description Used to display the forecast on the year if user has the good subscription plan
     * @author Didier Pascarel <didier.pascarel@omedom.com>
     * @date 19/06/2024
     * @memberof ChartLegendComponent
     */
    @Input()
    forecastAuthorised = false;

    @Input()
    get charges(): ChargeEntity[] {
        return this._charges ?? [];
    }

    set charges(value: ChargeEntity[]) {
        this._charges = value;
        this.dataChange$.next(null);
    }

    @Input()
    get incomes(): IncomeEntity[] {
        return this._incomes ?? [];
    }

    set incomes(value: IncomeEntity[]) {
        this._incomes = value;
        this.dataChange$.next(null);
    }

    @Input() property?: PropertyEntity;

    @Output() forecastClicked = new EventEmitter<void>();

    omedomDateType = OmedomDateType;

    chartLegends: OmedomChartLegend[] = [
        new OmedomChartLegend({
            label: 'Trésorerie mensuelle',
            color: OmedomChart.gold,
        }),
        new OmedomChartLegend({
            label: 'Trésorerie cumulée',
            color: OmedomChart.blue,
        }),
        new OmedomChartLegend({
            label: 'Prévisionnel',
            color: OmedomChart.red,
            previsionLabel: true,
        }),
        new OmedomChartLegend({
            label: 'Revenus mensuels',
            color: OmedomChart.green,
        }),
        new OmedomChartLegend({
            label: 'Charges mensuelles',
            color: OmedomChart.red,
        }),
    ];

    totalCharge = 0;

    totalIncome = 0;

    datasetIncome: ChartDataset<'bar'> = {
        data: [],
        backgroundColor: [OmedomChart.green],
        borderColor: 'transparent',
        order: 2,
        hoverBackgroundColor: [OmedomChart.green],
        borderWidth: 0,
        barThickness: 8,
        borderRadius: 8,
    };

    datasetCharge: ChartDataset<'bar'> = {
        data: [],
        backgroundColor: [OmedomChart.red],
        borderColor: 'transparent',
        order: 2,
        hoverBackgroundColor: [OmedomChart.red],
        borderWidth: 0,
        barThickness: 8,
        borderRadius: 8,
    };

    datasetTreasuryByMonth: ChartDataset<'line'> = {
        data: [],
        type: 'line',
        backgroundColor: [OmedomChart.gold],
        borderColor: OmedomChart.gold,
        order: 1,
        hoverBackgroundColor: [OmedomChart.gold],
        pointStyle: 'rectRounded',
        borderWidth: 5,
        pointRadius: 2.5,
        pointBorderWidth: 0,
        pointBackgroundColor: OmedomChart.gold,
    };

    datasetTreasuryCumulated: ChartDataset<'line'> = {
        data: [],
        type: 'line',
        backgroundColor: [OmedomChart.blue],
        borderColor: OmedomChart.blue,
        order: 1,
        hoverBackgroundColor: [OmedomChart.blue],
        pointStyle: 'rectRounded',
        borderWidth: 5,
        pointRadius: 2.5,
        pointBorderWidth: 0,
        pointBackgroundColor: OmedomChart.blue,
    };

    datasetIncomeProjection: ChartDataset<'bar'> = {
        data: [],
        backgroundColor: ['rgba(0, 194, 154, 0.3)'],
        borderColor: 'transparent',
        order: 2,
        hoverBackgroundColor: ['rgba(0, 194, 154, 0.3)'],
        borderWidth: 0,
        barThickness: 8,
        borderRadius: 8,
    };

    datasetChargeProjection: ChartDataset<'bar'> = {
        data: [],
        backgroundColor: ['rgba(255, 86, 65, 0.3)'],
        borderColor: 'transparent',
        order: 2,
        hoverBackgroundColor: ['rgba(255, 86, 65, 0.3)'],
        borderWidth: 0,
        barThickness: 8,
        borderRadius: 8,
    };

    datasetTreasuryByMonthProjection: ChartDataset<'line'> = {
        data: [],
        type: 'line',
        backgroundColor: [OmedomChart.gold],
        borderColor: OmedomChart.gold,
        order: 1,
        hoverBackgroundColor: [OmedomChart.gold],
        pointStyle: 'rectRounded',
        borderWidth: 5,
        pointRadius: 2.5,
        pointBorderWidth: 0,
        pointBackgroundColor: OmedomChart.gold,
        borderDash: [5, 5],
    };

    datasetTreasuryCumulatedProjection: ChartDataset<'line'> = {
        data: [],
        type: 'line',
        backgroundColor: [OmedomChart.blue],
        borderColor: OmedomChart.blue,
        order: 1,
        hoverBackgroundColor: [OmedomChart.blue],
        pointStyle: 'rectRounded',
        borderWidth: 5,
        pointRadius: 2.5,
        pointBorderWidth: 0,
        pointBackgroundColor: OmedomChart.blue,
        borderDash: [5, 5],
    };

    chartData: ChartData = {
        labels: [
            'Jan',
            'Fev',
            'Mars',
            'Avr',
            'Mai',
            'Juin',
            'Juil',
            'Aout',
            'Sep',
            'Oct',
            'Nov',
            'Dec',
        ],
        datasets: [
            this.datasetIncome,
            this.datasetCharge,
            this.datasetTreasuryByMonth,
            this.datasetTreasuryCumulated,
            this.datasetIncomeProjection,
            this.datasetChargeProjection,
            this.datasetTreasuryByMonthProjection,
            this.datasetTreasuryCumulatedProjection,
        ],
    };

    chartOptions: ChartOptions = {
        aspectRatio: 1,
        plugins: {
            legend: {
                display: false,
            },
            tooltip: {
                backgroundColor: 'white',
                cornerRadius: 10,
                caretSize: 0,
                bodyColor: '#04151c',
                titleColor: '#04151c',
                bodyAlign: 'center',
                bodyFont: {
                    family: 'Asap',
                    size: 18,
                    lineHeight: 1.1,
                    weight: '700',
                },
                borderWidth: 1.5,
                usePointStyle: true,
                padding: 10,
                callbacks: {
                    label: (context) => {
                        const labels: string[] = [];
                        let rawAmount;
                        if (typeof context?.raw == 'number') {
                            rawAmount = context?.raw;
                        } else {
                            // eslint-disable-next-line @typescript-eslint/dot-notation
                            rawAmount = (context?.raw as any)['y'];
                        }
                        const amount = this.omedomNumberPipe.transform(
                            this.decimalPipe.transform(rawAmount.toString(), '0.0-2') ?? 0,
                        );

                        labels.push(context.label);
                        labels.push(amount + '€');
                        return labels;
                    },
                    title: () => '',
                },
            },
        },
        scales: {
            x: {
                stacked: true,
                ticks: {
                    color: '#04151C',
                    font: {
                        family: 'Asap',
                        size: 14,
                        lineHeight: 1.1,
                        weight: '700',
                    },
                },
                grid: {
                    lineWidth: 0,
                    drawBorder: false,
                },
            },
            y: {
                ticks: {
                    color: '#04151C',
                    font: {
                        family: 'Asap',
                        size: 14,
                        lineHeight: 1.1,
                        weight: '700',
                    },
                },
                grid: {
                    color: 'white',
                    lineWidth: 2,
                    drawBorder: false,
                },
            },
        },
    };

    private dataChange$ = new Subject();

    private selectedYear?: number;

    private subscription?: Subscription;

    private _charges?: ChargeEntity[];

    private _incomes?: IncomeEntity[];

    protected cumulatedPreviousYearTreasury = 0;

    protected cumulatedYearTreasuryProjection = 0;

    constructor(
        private decimalPipe: DecimalPipe,
        private omedomNumberPipe: OmedomNumberPipe,
    ) {}

    ngOnInit(): void {
        this.selectedYear = new Date().toUTC().getUTCFullYear();

        this.subscription = this.dataChange$.pipe(debounceTime(123)).subscribe(() => {
            this.updateGraphData();
            this.calculatePreviousYearCumulatedTreasury();
        });
    }

    ngOnChanges(changes: SimpleChanges): void {
        this.updateGraphData();
        if (changes['charges'] || changes['incomes']) {
            this.calculatePreviousYearCumulatedTreasury();
        }
    }

    ngOnDestroy(): void {
        this.subscription?.unsubscribe();
    }

    dateChange(date: Date) {
        this.selectedYear = date.getUTCFullYear();
        this.updateGraphData();
        this.calculatePreviousYearCumulatedTreasury();
    }

    private getLabel(month: number) {
        const labels = [
            'Jan',
            'Fev',
            'Mars',
            'Avr',
            'Mai',
            'Juin',
            'Juil',
            'Aout',
            'Sep',
            'Oct',
            'Nov',
            'Dec',
        ];
        return labels[month];
    }

    private updateGraphData(): void {
        const now = new Date().toUTC();
        let currentDate = new Date(Date.UTC(this.selectedYear ?? now.getUTCFullYear(), 0, 1));

        let lastMonth = new Date().toUTC();
        lastMonth = lastMonth.subUTCMonths(1);

        const currentMonth = new Date().toUTC();

        const monthlyTreasuryOverview = this.calculateMonthlyTreasuryOverview(
            currentDate,
            lastMonth,
            currentMonth,
        );

        const { amountCharges, amountIncomes, treasuryByMonth, treasuryCumulated } =
            monthlyTreasuryOverview;

        currentDate = monthlyTreasuryOverview.currentDate;

        const {
            amountChargesProjection,
            amountIncomesProjection,
            treasuryByMonthProjection,
            treasuryCumulatedProjection,
        } = this.calculateMonthlyTreasuryOverviewProjection(
            currentDate,
            lastMonth,
            treasuryByMonth,
            treasuryCumulated,
        );

        this.cumulatedYearTreasuryProjection =
            treasuryCumulatedProjection[treasuryCumulatedProjection.length - 1]?.y ??
            treasuryCumulated[treasuryCumulated.length - 1];

        this.chartData.datasets[0].data = amountIncomes;
        this.chartData.datasets[1].data = amountCharges;
        this.chartData.datasets[2].data = treasuryByMonth;
        this.chartData.datasets[3].data = treasuryCumulated;
        this.chartData.datasets[4].data = amountIncomesProjection as any;
        this.chartData.datasets[5].data = amountChargesProjection as any;
        this.chartData.datasets[6].data = treasuryByMonthProjection as any;
        this.chartData.datasets[7].data = treasuryCumulatedProjection as any;

        this.totalIncome = amountIncomes.filter((x) => !!x).sum();
        this.totalCharge = amountCharges.filter((x) => !!x).sum();

        this.baseChart?.update();
    }

    private calculatePreviousYearCumulatedTreasury(selectedYear = this.selectedYear) {
        const now = new Date().toUTC();
        const startDate = new Date(0);

        const endDate = new Date(new Date(selectedYear ?? now.getUTCFullYear(), 0, 1));
        const allCharges = OmedomTreasury.filterTreasury(
            this.charges,
            new Date(startDate),
            new Date(endDate),
        );

        const allIncomes = OmedomTreasury.filterTreasury(
            this.incomes,
            new Date(startDate),
            new Date(endDate),
        );
        let currentDate = new Date(startDate).getUTCDateWithoutTime();
        let cumulatedPreviousYearTreasury = 0;
        let index = 0;
        while (
            currentDate.getFirstDayOfMonth().getTime() <= endDate.getFirstDayOfMonth().getTime() &&
            index < 1000
        ) {
            const currentStartDate = currentDate.getFirstDayOfMonth();
            const currentEndDate = currentDate.getLastDayOfMonth();

            const isForecast =
                (currentDate.getTime() > now.getTime() && !this.forecastAuthorised) ||
                (currentDate.getMonth() === now.getMonth() &&
                    currentDate.getFullYear() === now.getFullYear());

            const monthCharges = OmedomTreasury.filterTreasury(
                allCharges,
                currentStartDate,
                currentEndDate,
            ).filter((x) =>
                isForecast
                    ? OmedomTreasury.isTreasuryPayed(x, currentStartDate, currentEndDate)
                    : true,
            );

            const monthIncomes = OmedomTreasury.filterTreasury(
                allIncomes,
                currentStartDate,
                currentEndDate,
            ).filter((x) =>
                isForecast
                    ? OmedomTreasury.isTreasuryPayed(x, currentStartDate, currentEndDate)
                    : true,
            );

            const monthChargeTreasury = OmedomTreasury.getTreasuryInPeriod(
                monthCharges,
                currentStartDate,
                currentEndDate,
                true,
            );

            const monthIncomeTreasury = OmedomTreasury.getTreasuryInPeriod(
                monthIncomes,
                currentStartDate,
                currentEndDate,
                false,
            );

            const monthChargeAmount = monthChargeTreasury
                .filter(
                    (x) =>
                        x.isPayed ||
                        (x.debitDate.getTime() > now.getLastDayOfMonth().getTime() &&
                            this.forecastAuthorised) ||
                        (x.debitDate.getTime() >= now.getFirstDayOfMonth().getTime() &&
                            x.debitDate.getTime() <= now.getLastDayOfMonth().getTime()),
                )
                .sumBy((x) => -x.amount);

            const monthIncomeAmount = monthIncomeTreasury
                .filter(
                    (x) =>
                        x.isPayed ||
                        (x.debitDate.getTime() > now.getLastDayOfMonth().getTime() &&
                            this.forecastAuthorised) ||
                        (x.debitDate.getTime() >= now.getFirstDayOfMonth().getTime() &&
                            x.debitDate.getTime() <= now.getLastDayOfMonth().getTime()),
                )
                .sumBy((x) => x.amount);

            cumulatedPreviousYearTreasury +=
                (!!monthIncomeAmount ? monthIncomeAmount : 0) +
                (!!monthChargeAmount ? monthChargeAmount : 0);

            currentDate = currentDate.addMonths(1);

            index++;
        }

        this.cumulatedPreviousYearTreasury = cumulatedPreviousYearTreasury;
    }

    private calculateMonthlyTreasuryOverview(
        currentDate: Date,
        lastMonth: Date,
        currentMonth: Date,
        selectedYear = this.selectedYear,
    ) {
        // Arrays to hold charges, incomes, and treasury details for each month
        const amountCharges: number[] = [];
        const amountIncomes: number[] = [];
        const treasuryByMonth: number[] = [];
        const treasuryCumulated: number[] = [];

        // Loop through each month from the current date until the last month of the selected year
        while (
            currentDate.getUTCFirstDayOfMonth().getTime() <=
                currentMonth.getUTCFirstDayOfMonth().getTime() &&
            currentDate.getUTCFullYear() === selectedYear
        ) {
            // Set the end date of the current month
            const endDate = new Date(
                currentDate.getFullYear(),
                currentDate.getMonth() + 1,
                0, // Get the last day of the current month
                23,
                59,
                59,
            );

            // Filter charges that are within the current month and have been paid
            const monthCharges = OmedomTreasury.filterTreasury(
                this.charges,
                currentDate,
                endDate,
            ).filter((x) => OmedomTreasury.isTreasuryPayed(x, currentDate, endDate));

            // Calculate the total charge amount for the month
            const monthChargeAmount = monthCharges?.length
                ? monthCharges.sumBy((x) => -OmedomTreasury.getMonthAmount(x, currentDate))
                : undefined;
            amountCharges.push(monthChargeAmount ?? 0);

            // Filter incomes that are within the current month and have been paid
            const monthIncomes = OmedomTreasury.filterTreasury(
                this.incomes,
                currentDate,
                endDate,
            ).filter((x) => OmedomTreasury.isTreasuryPayed(x, currentDate, endDate));

            // Calculate the total income amount for the month
            const monthIncomeAmount = monthIncomes?.length
                ? monthIncomes.sumBy((x) => OmedomTreasury.getMonthAmount(x, currentDate))
                : undefined;
            amountIncomes.push(monthIncomeAmount ?? 0);

            // Calculate the treasury amount for the month (incomes - charges)
            const monthTreasury =
                (!!monthIncomeAmount ? monthIncomeAmount : 0) +
                (!!monthChargeAmount ? monthChargeAmount : 0);

            if (
                currentDate.getUTCFirstDayOfMonth().getTime() <=
                lastMonth.getUTCFirstDayOfMonth().getTime()
            ) {
                // Update cumulative and monthly treasury arrays
                treasuryCumulated.push(treasuryByMonth.sum() + monthTreasury);
                treasuryByMonth.push(monthTreasury);

                // Move to the next month
            }

            currentDate = currentDate.addUTCMonths(1);
        }

        if (currentMonth.getUTCFullYear() === selectedYear) {
            currentDate = currentDate.subUTCMonths(1);
        }

        // Return the calculated data for charges, incomes, and treasury details
        return {
            amountCharges,
            amountIncomes,
            treasuryByMonth,
            treasuryCumulated,
            currentDate,
        };
    }

    private calculateMonthlyTreasuryOverviewProjection(
        currentDate: Date,
        lastMonth: Date,
        treasuryByMonth: number[],
        treasuryCumulated: number[],
        selectedYear = this.selectedYear,
    ) {
        // Arrays to store projection results for charges, incomes, treasury by month, and cumulative treasury
        const amountChargesProjection = [];
        const amountIncomesProjection = [];
        const treasuryByMonthProjection = [];
        const treasuryCumulatedProjection = [];

        let startWithCumul: number | null = null;

        // If the selected year matches the year of the last month
        if (selectedYear === lastMonth.getUTCFullYear()) {
            // Set initial cumulative treasury and monthly treasury for the projection
            const label = this.getLabel(lastMonth.getMonth());
            startWithCumul = treasuryCumulated[treasuryCumulated.length - 1];
            treasuryCumulatedProjection.push({ x: label, y: startWithCumul });
            treasuryByMonthProjection.push({
                x: label,
                y: treasuryByMonth[treasuryByMonth.length - 1],
            });
        } else if (selectedYear !== lastMonth.getUTCFullYear() && !this.forecastAuthorised) {
            // If forecast is not authorised, return empty projections
            return {
                amountChargesProjection: [],
                amountIncomesProjection: [],
                treasuryByMonthProjection: [],
                treasuryCumulatedProjection: [],
            };
        }

        if (this.forecastAuthorised) {
            // If forecast is authorised, calculate the projection for each month
            const startProjectionWithCumul = startWithCumul;

            while (currentDate.getUTCFullYear() === selectedYear) {
                // Get the label for the current month and filter charges and incomes for that month
                const endDate = currentDate.getUTCLastDayOfMonth();
                const label = this.getLabel(currentDate.getMonth());

                // Filter and calculate charges for the current month
                const monthCharges = OmedomTreasury.filterTreasury(
                    this.charges,
                    currentDate,
                    endDate,
                );
                const monthChargeAmount = monthCharges?.length
                    ? monthCharges.sumBy((x) => -OmedomTreasury.getMonthAmount(x, currentDate))
                    : undefined;
                amountChargesProjection.push({
                    x: label,
                    y: monthChargeAmount,
                });

                // Filter and calculate incomes for the current month
                const monthIncomes = OmedomTreasury.filterTreasury(
                    this.incomes,
                    currentDate,
                    endDate,
                );
                const monthIncomeAmount = monthIncomes?.length
                    ? monthIncomes.sumBy((x) => OmedomTreasury.getMonthAmount(x, currentDate))
                    : undefined;
                amountIncomesProjection.push({
                    x: label,
                    y: monthIncomeAmount,
                });

                // Calculate monthly treasury and update the projection
                const monthTreasury =
                    (!!monthIncomeAmount ? monthIncomeAmount : 0) +
                    (!!monthChargeAmount ? monthChargeAmount : 0);
                treasuryByMonthProjection.push({ x: label, y: monthTreasury });

                // Calculate cumulative treasury for the projection
                if (startWithCumul) {
                    treasuryCumulatedProjection.push({
                        x: label,
                        y: startWithCumul + monthTreasury,
                    });
                    startWithCumul = null;
                } else {
                    const cumul: number = treasuryCumulatedProjection.length
                        ? monthTreasury +
                          treasuryCumulatedProjection[treasuryCumulatedProjection.length - 1].y
                        : monthTreasury + (startProjectionWithCumul ?? 0);
                    treasuryCumulatedProjection.push({ x: label, y: cumul });
                }

                // Move to the next month
                currentDate = currentDate.addUTCMonths(1);
            }
        } else if (currentDate.getUTCFullYear() === selectedYear) {
            // If forecast is not authorised and current year matches selected year, calculate projections for the current month

            // Set the end date to the last day of the current month
            const endDate = new Date(
                currentDate.getFullYear(),
                currentDate.getMonth() + 1,
                0,
                23,
                59,
                59,
            );

            const label = this.getLabel(currentDate.getMonth());

            // Filter and calculate charges for the current month
            const monthCharges = OmedomTreasury.filterTreasury(this.charges, currentDate, endDate);
            const monthChargeAmount = monthCharges?.length
                ? monthCharges.sumBy((x) => -OmedomTreasury.getMonthAmount(x, currentDate))
                : undefined;
            amountChargesProjection.push({ x: label, y: monthChargeAmount });

            // Filter and calculate incomes for the current month
            const monthIncomes = OmedomTreasury.filterTreasury(this.incomes, currentDate, endDate);
            const monthIncomeAmount = monthIncomes?.length
                ? monthIncomes.sumBy((x) => OmedomTreasury.getMonthAmount(x, currentDate))
                : undefined;
            amountIncomesProjection.push({ x: label, y: monthIncomeAmount });

            // Calculate monthly treasury and update the cumulative treasury projection
            const monthTreasury =
                (!!monthIncomeAmount ? monthIncomeAmount : 0) +
                (!!monthChargeAmount ? monthChargeAmount : 0);
            treasuryCumulatedProjection.push({
                x: label,
                y: treasuryCumulatedProjection.map((x) => x.y).sum() + monthTreasury,
            });
            treasuryByMonthProjection.push({ x: label, y: monthTreasury });

            // Move to the next month
            currentDate = currentDate.addUTCMonths(1);
        }

        // Return the projections for charges, incomes, treasury by month, and cumulative treasury
        return {
            amountChargesProjection,
            amountIncomesProjection,
            treasuryByMonthProjection,
            treasuryCumulatedProjection,
        };
    }
}
