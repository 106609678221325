@if (startDate && endDate) {
    <div class="date-bar margin-bottom-1 flex-1">
        <div class="date-bar-item">
            <span class="date-bar-title">Du</span>
            <omedom-input
                type="date"
                [(ngModel)]="startDate"
                class="flex-1"
                (ngModelChange)="startDateChange($event)"
                [max]="(endDate | date: 'yyyy-MM-dd') ?? ''"
            ></omedom-input>
        </div>

        <div class="date-bar-item">
            <span class="date-bar-title">au</span>
            <omedom-input
                type="date"
                [(ngModel)]="endDate"
                class="flex-1"
                (ngModelChange)="endDateChange($event)"
                [min]="(startDate | date: 'yyyy-MM-dd') ?? ''"
            ></omedom-input>
        </div>
    </div>
}
@if (!hideFilter) {
    <omedom-asset-filter
        [properties]="properties"
        [societies]="societies"
        (update)="updateFilter()"
        [canFilter]="('accessFilter' | isAuthorised | async) || false"
    ></omedom-asset-filter>
}
@if (typeOfChart === 'pie') {
    <div class="graph-header">
        <div>
            <strong
                ><span>Total&nbsp;</span>
                @if (displayAmount) {
                    {{
                        (displayAmount | number: '0.2-2') ?? 0 | omedomNumber
                    }}&nbsp;€
                }
                @if (!displayAmount) {
                    --,--&nbsp;€
                }
            </strong>
        </div>
        <div #payed class="omedom-card padding-1 flex-1">
            <ion-checkbox
                class="omedom-checkBox"
                [checked]="onlyPayed"
                (ionChange)="toggleOnlyPayed()"
            >
            </ion-checkbox>
            <ion-label>
                <span class="label-text"> Déjà payé </span>
            </ion-label>
        </div>
        <div
            #notPayed
            class="omedom-card padding-1 flex-1 margin-left-1"
            [class.selected]="onlyNotPayed || (!onlyPayed && !onlyNotPayed)"
            [class.Notselected]="!onlyNotPayed && onlyPayed"
        >
            <ion-checkbox
                class="omedom-checkBox"
                [checked]="onlyNotPayed"
                (ionChange)="toggleOnlyNotPayed()"
            >
            </ion-checkbox>
            <ion-label>
                <span class="label-text"> Reste à payer </span>
            </ion-label>
        </div>
    </div>
}

@if (typeOfChart !== 'pie') {
    <omedom-canvas-header
        [isCharge]="true"
        [totalAmount]="totalAmount"
        [totalAmountPayed]="totalAmountPayed"
        [totalAmountNotPayed]="totalAmountNotPayed"
    ></omedom-canvas-header>
}
@if (!charges || charges.length < 1) {
    @if (roleState?.create) {
        <omedom-create-first-item-button
            buttonIcon="uil-chart-pie-alt"
            message="Ajouter votre 1ère charge"
            (buttonClicked)="addClicked()"
        ></omedom-create-first-item-button>
    }
}
@if (!roleState?.update || (charges && charges.length > 0)) {
    <div [class]="'graph-legend-container-' + mode">
        <div class="graph-container margin-y-2">
            <div class="graph">
                <canvas
                    baseChart
                    [class.clickable]="typeOfChart === 'pie'"
                    [data]="chartData"
                    [options]="chartOptions"
                    [type]="typeOfChart"
                    (alreadyClicGraph)="(alreadyClicGraph)"
                >
                </canvas>
                @if (typeOfChart !== 'pie') {
                    <div class="total">
                        <span class="total-title">Total</span>
                        <span class="total-value">
                            @if (displayAmount) {
                                {{
                                    (displayAmount | number: '0.2-2') ?? 0
                                        | omedomNumber
                                }}&nbsp;€
                            }
                            @if (!displayAmount) {
                                --,--&nbsp;€
                            }
                        </span>
                    </div>
                }
            </div>
            @if (!hideSticker && !alreadyClicGraph && typeOfChart === 'pie') {
                <omedom-sticker-clic></omedom-sticker-clic>
            }
        </div>
        <omedom-chart-legend
            [disablePercentage]="true"
            [legends]="chartLegends"
            (legendClicked)="legendClicked($event)"
            [isLegendClickable]="true"
        ></omedom-chart-legend>
    </div>

    @if (showDateBar) {
        <div class="omedom-sticky-button-container w-100">
            <div class="flex-1"></div>
            @if (roleState?.update && chartLegends.length > 0) {
                <button
                    class="omedom-icon-button-secondary-outline"
                    (click)="navigateToChargesDetail()"
                >
                    <i class="uil uil-eye"></i>
                </button>
            }
            @if (roleState?.create) {
                <button
                    class="omedom-icon-button-primary"
                    (click)="addClicked()"
                >
                    <i class="uil uil-plus"></i>
                </button>
            }
        </div>
    }
}
