import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'omedom-canvas-header',
    templateUrl: './canvas-header.component.html',
    styleUrls: ['./canvas-header.component.scss'],
})
export class CanvasHeaderComponent implements OnInit {
    @Input() isCharge!: boolean;

    @Input() totalAmount!: number;

    @Input() totalAmountPayed!: number;

    @Input() totalAmountNotPayed!: number;

    constructor() {}

    ngOnInit() {}
}
