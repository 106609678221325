import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
    selector: 'omedom-create-first-item-button',
    templateUrl: './create-first-item-button.component.html',
    styleUrls: ['./create-first-item-button.component.scss'],
})
export class CreateFirstItemButtonComponent implements OnInit {
    @Input() buttonIcon = '';

    @Input() message = '';

    @Output() buttonClicked = new EventEmitter();

    public get iconMask() {
        return `url("/assets/icon/${this.buttonIcon}.svg") no-repeat center`;
    }

    constructor() {}

    ngOnInit() {}
}
