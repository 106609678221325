<div class="avatar-container">
    <div class="avatar-display">
        @if (avatar) {
            <img [src]="avatar" class="avatar" alt="Avatar" />
        } @else {
            <div class="avatar">
                <i class="uil" [class]="defaultIcon"></i>
            </div>
        }
    </div>
</div>
