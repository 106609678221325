<div class="header">
    <div class="icon">
        @if (bank && bank.logoUrl) {
        <img [src]="bank.logoUrl" class="logo" />
        } @else {
        <i class="uil uil-wallet"></i>
        }

        <ng-template #icon>
            <i class="uil uil-wallet"></i>
        </ng-template>
    </div>

    <div class="label">
        <span class="saving-name">{{ saving.name }}</span>

        @if (!saving.etablissement) { @if (bank && showBankName) {
        <span class="bank-name">{{ bank.name }}</span>
        } } @else {
        <span class="bank-name">{{ saving.etablissement }}</span>
        }

        <ng-template #etablissement>
            <span class="bank-name">{{ saving.etablissement }}</span>
        </ng-template>
    </div>

    <div class="details">
        @if (saving.interestRate && showInterestRate) {
        <span class="interest-rate">
            {{ saving.interestRate | number }} %
        </span>
        } @if (saving.balance && showBalance) {
        <span class="balance"> {{ saving.balance | currency: 'EUR' }} </span>
        }
    </div>
</div>

<ng-container *ngTemplateOutlet="progress"></ng-container>

@if (showInformations && layout !== SavingCardLayout.DASHBOARD) {
<div class="infos">
    <div class="info">
        <span class="title">Ouverture le</span>
        @if (saving.openingDate) {
        <span class="value">
            {{ saving.openingDate | date:'dd/MM/yyyy' }}
        </span>
        } @else {
        <span class="value undefined">Non renseigné(e)</span>
        }
    </div>
    <div class="info">
        <span class="title">Montant déposé</span>
        @if (saving.depositAmount) {
        <span class="value" id="deposit"
            >{{ saving.depositAmount | currency: 'EUR' }}</span
        >
        } @else {
        <span class="value undefined">Non renseigné(e)</span>
        }
    </div>
    <div class="info">
        <span class="title">Montant retiré</span>
        @if (saving.withdrawAmount) {
        <span class="value" id="withdraw"
            >{{ saving.withdrawAmount | currency: 'EUR' }}</span
        >
        } @else {
        <span class="value undefined">Non renseigné(e)</span>
        }
    </div>
    <div class="info">
        <span class="title">Prochain dépôt</span>
        <div class="container">
            @if (saving.nextDepositAmount && saving.nextDepositDate) {
            <span class="value" id="next-payment-amount"
                >{{ saving.nextDepositAmount | currency: 'EUR' }}</span
            >
            <span>le</span>
            <span class="value" id="next-payment-date"
                >{{ saving.nextDepositDate | date:'dd/MM' }}</span
            >
            } @else {
            <span class="value undefined">Non renseigné(e)</span>
            }
        </div>
    </div>
</div>
} @if (showFooter && layout !== SavingCardLayout.MOBILE) {
<div class="actions">
    <button class="omedom-button-secondary" (click)="onEvolutionClick.emit()">
        <i class="uil uil-chart-line"></i>
    </button>
    <button class="omedom-button-primary" (click)="onSeeMoreClick.emit()">
        Voir plus
    </button>
</div>
}

<ng-template #progress>
    @if (saving.limit && showLimit) {
    <div class="progress">
        <span class="progress-title">Plafond</span>
        <div class="progress-bar">
            <div class="progress-value" [style.width]="filledLimit + '%'"></div>
        </div>
        <span class="limit">{{ saving.limit | currency: 'EUR' }}</span>
    </div>
    }
</ng-template>
