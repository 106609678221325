import { Component, Input, OnChanges } from '@angular/core';
import { DPEScore } from '@omedom/data';

@Component({
    selector: 'omedom-dpe-score',
    templateUrl: './dpe-score.component.html',
    styleUrls: ['./dpe-score.component.scss'],
})
export class DpeScoreComponent implements OnChanges {
    @Input() score?: DPEScore;
    level: any;
    levels: { letter: string; color: string }[] = [
        { letter: 'A', color: '#00E400' },
        { letter: 'B', color: '#9BCA02' },
        { letter: 'C', color: '#FEF300' },
        { letter: 'D', color: '#F29C00' },
        { letter: 'E', color: '#E20000' },
        { letter: 'F', color: '#980004' },
        { letter: 'G', color: '#6B0022' },
    ];
    constructor() {}

    ngOnChanges() {
        if (this.score === undefined) {
            return;
        }

        const upperScore = this.score.toUpperCase();

        this.level = this.levels.filter((level) => {
            return level.letter === upperScore;
        })[0];

        const index = this.levels.findIndex((level) => {
            return level.letter === upperScore;
        });

        if (index) {
            this.level = this.levels[index];
        }
    }
}
