import { DocumentEntity } from '../../entities';

export const fakeDocuments: DocumentEntity[] = [
    {
        fileName: 'Facture maçon',
        name: 'Facture maçon',
        type: 'Facture',
        uid: '1234567',
        created: new Date(),
        updated: new Date(),
        extension: 'application/pdf',
        extensionName: 'pdf',
        date: new Date(),
        weight: 54000,
        fileURL: 'https://',
    },
    {
        fileName: 'Facture maçon',
        name: 'Facture maçon',
        type: 'Facture',
        uid: '1234567',
        created: new Date(),
        updated: new Date(),
        extension: 'application/pdf',
        extensionName: 'pdf',
        date: new Date(),
        weight: 54000,
        fileURL: 'https://',
    },
    {
        fileName: 'Facture maçon',
        name: 'Facture maçon',
        type: 'Facture',
        uid: '1234567',
        created: new Date(),
        updated: new Date(),
        extension: 'application/pdf',
        extensionName: 'pdf',
        date: new Date(),
        weight: 54000,
        fileURL: 'https://',
    },
    {
        fileName: 'Facture maçon',
        name: 'Facture maçon',
        type: 'Facture',
        uid: '1234567',
        created: new Date(),
        updated: new Date(),
        extension: 'application/pdf',
        extensionName: 'pdf',
        date: new Date(),
        weight: 54000,
        fileURL: 'https://',
    },
    {
        fileName: 'Facture maçon',
        name: 'Facture maçon',
        type: 'Facture',
        uid: '1234567',
        created: new Date(),
        updated: new Date(),
        extension: 'application/pdf',
        extensionName: 'pdf',
        date: new Date(),
        weight: 54000,
        fileURL: 'https://',
    },
    {
        fileName: 'Facture maçon',
        name: 'Facture maçon',
        type: 'Facture',
        uid: '1234567',
        created: new Date(),
        updated: new Date(),
        extension: 'application/pdf',
        extensionName: 'pdf',
        date: new Date(),
        weight: 54000,
        fileURL: 'https://',
    },
    {
        fileName: 'Facture maçon',
        name: 'Facture maçon',
        type: 'Facture',
        uid: '1234567',
        created: new Date(),
        updated: new Date(),
        extension: 'application/pdf',
        extensionName: 'pdf',
        date: new Date(),
        weight: 54000,
        fileURL: 'https://',
    },
];
